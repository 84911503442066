import { createSlice } from "@reduxjs/toolkit"
import { performanceActions } from "../../assets/staticData";

let initialData:any = {
    tabs: [
        'ACTION_PLAN',
    ],
    currentTab: 'ACTION_PLAN',
    isActions: false,
    projects: [],
    selectedProject: {},
    details: {},
    allActions: [],
    selectedAction: {},
}

export const actionPlan = createSlice({
    name: 'actionPlan',
    initialState: initialData,
    reducers: {
        setCurrentTab: (state:any, action:any) => {
            state.currentTab = action.payload;
            return state;
        },
        setIsActions: (state:any, action:any) => {
            state.isActions = action.payload;
            return state;
        },
        setProjects: (state:any, action:any) => {
            state.projects = action.payload;
            return state;
        },
        setSelectedProject: (state:any, action:any) => {
            state.selectedProject = action.payload;
            return state;
        },
        resetSelectedProject: (state:any) => {
            state.selectedProject = initialData.selectedProject;
            return state;
        },
        setProjectDetails: (state:any, action:any) => {
            state.details = action.payload;
            return state;
        },
        setAllActions: (state:any, action:any) => {
            state.allActions = action.payload;
            return state;
        },
        setSelectedAction: (state:any, action:any) => {
            state.selectedAction = action.payload;
            return state;
        },
        resetSelectedAction: (state:any) => {
            state.selectedAction = initialData.selectedAction;
            return state;
        },
    }
});

export const {
    setCurrentTab,
    setIsActions,
    setProjects,
    setSelectedProject,
    resetSelectedProject,
    setProjectDetails,
    setAllActions,
    setSelectedAction,
    resetSelectedAction
} = actionPlan.actions;
export default actionPlan.reducer;
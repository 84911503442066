import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { EnhancedTableHead } from "../../../components/EnhancedTableHead";
import TableSkeleton from "../../../components/TableSkeleton";
import { useSelector } from "react-redux";
import { TableCell, TableRow } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { filteredList, formatDate } from "../Utils";
import { sortData } from "../../../common/SortSupport";

export default function DatesTable() {
  const [t] = useTranslation();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const currentTab: any = useSelector(
    (state: any) => state.evaluationDashboard.currentTab
  );
  const clubsDates: any = useSelector(
    (state: any) => state.evaluationDashboard.clubsDates
  );
  const searchString: any = useSelector(
    (state: any) => state.evaluationDashboard.searchString
  );
  const targetStarRatingFilter: any = useSelector(
    (state: any) => state.evaluationDashboard.targetStarRatingFilter
  );
  const leagueFilter: any = useSelector(
    (state: any) => state.evaluationDashboard.leagueFilter
  );
  const otherFilter: any = useSelector(
    (state: any) => state.evaluationDashboard.otherFilter
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const head = [
    {
      key: "name",
      displayName: t("Club").toUpperCase(),
    },
    {
      key: "",
      displayName: t("TARGET").toUpperCase(),
    },
    {
      key: "",
      displayName: t("SELF_ASSESSMENT").toUpperCase(),
    },
    {
      key: "",
      displayName: t("OFF-SITE").toUpperCase(),
    },
    {
      key: "",
      displayName: t("ON-SITE").toUpperCase(),
    },
    {
      key: "",
      displayName: t("INTERIM_REPORT").toUpperCase(),
    },
    {
      key: "",
      displayName: t("SELF_ASSESSMENT").toUpperCase() + " " + 2,
    },
    {
      key: "",
      displayName: t("FINAL_REPORT").toUpperCase(),
    },
    {
      key: "",
      displayName: "",
    },
  ];

  const processDate = (date: string | undefined) => {
    // If the date is defined, return the formatted date
    if (date && date !== undefined) {
      return formatDate(date);
    }

    // If the date is undefined, return the FiberManualRecordIcon
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <FiberManualRecordIcon style={{ width: 14, color: "red" }} />
        <p style={{ color: "red", margin: 0 }}>Not set</p>
      </div>
    );
  };

  // Memoize the filtered club dates based on searchString and clubsDates
  const filteredClubDates = useMemo(() => {
    console.log("Filtering club dates based on searchString...");
    return clubsDates?.filter((row: any) => {
      const searchTerm = searchString.toLowerCase();
      return (
        row.name?.toLowerCase().includes(searchTerm) ||
        row.targetDate?.toString().toLowerCase().includes(searchTerm) ||
        row.selfAssesment?.toString().toLowerCase().includes(searchTerm) ||
        row.offsite?.toString().toLowerCase().includes(searchTerm) ||
        row.onsite?.toString().toLowerCase().includes(searchTerm) ||
        row.interimReport?.toString().toLowerCase().includes(searchTerm) ||
        row.finalReport?.toString().toLowerCase().includes(searchTerm) ||
        row.selfAssesment2?.toString().toLowerCase().includes(searchTerm)
      );
    });
  }, [clubsDates, searchString]); // Recompute only when clubsDates or searchString changes

  // Memoize the final filtered and sorted data based on other filters and sorting order
  const filteredAndSortedData = useMemo(() => {
    console.log("Applying additional filters...");
    if (filteredClubDates) {
      let filteredData = filteredClubDates;

      // Apply all filters
      if (
        otherFilter.length > 0 ||
        leagueFilter.length > 0 ||
        targetStarRatingFilter.length > 0
      ) {
        filteredData = filteredList(
          otherFilter,
          leagueFilter,
          targetStarRatingFilter,
          filteredData,
          currentTab
        );
      }

      return filteredData;
    }

    return [];
  }, [
    filteredClubDates,
    currentTab,
    otherFilter,
    leagueFilter,
    targetStarRatingFilter,
  ]);

  // Memoizing visible rows (sorted data)
  const visibleRows = useMemo(() => {
    console.log("Sorting the filtered data...");
    return filteredAndSortedData.length > 0
      ? sortData(filteredAndSortedData, orderBy, order)
      : [];
  }, [filteredAndSortedData, order, orderBy]);

  return (
    <>
      <Box sx={{ widt: "100%" }}>
        <TableContainer sx={{ maxHeight: "100vh", overflowY: "auto" }}>
          <Table
            stickyHeader
            sx={{ boxShadow: "none" }}
            size="small"
            aria-label="a dense table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              tableHeads={head}
            />
            <TableBody>
              {visibleRows?.length > 0 ? (
                visibleRows.map((row: any, index: number) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:hover": {
                        backgroundColor: "#f5f5f5", // Light gray background on hover
                        cursor: "pointer", // Change cursor to pointer to indicate clickability
                      },
                    }}
                  >
                    <TableCell>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <img
                          src={`https://jamp.football/Content${row.imagePath}`}
                          alt={row.name}
                          width="30"
                          height="30"
                        />
                        <span>{row.name}</span>
                      </Box>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ fontSize: 12, fontFamily: "Montserrat" }}
                    >
                      {processDate(row?.targetDate)}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ fontSize: 12, fontFamily: "Montserrat" }}
                    >
                      {processDate(row?.selfAssesment1)}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ fontSize: 12, fontFamily: "Montserrat" }}
                    >
                      {processDate(row?.offSite)}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ fontSize: 12, fontFamily: "Montserrat" }}
                    >
                      {processDate(row?.onSite)}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ fontSize: 12, fontFamily: "Montserrat" }}
                    >
                      {processDate(row?.interimReport)}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ fontSize: 12, fontFamily: "Montserrat" }}
                    >
                      {processDate(row?.selfAssesment2)}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ fontSize: 12, fontFamily: "Montserrat" }}
                    >
                      {processDate(row?.finalReport)}
                    </TableCell>
                  </TableRow>
                ))
              ) : clubsDates.length > 0 ? (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      fontSize: 12,
                      fontFamily: "Montserrat",
                    }}
                    colSpan={8}
                  >
                    {t("NO_RECORDS_FOUND")}...
                  </TableCell>
                </TableRow>
              ) : (
                <TableSkeleton rowCount={10} cellCount={9} />
              )}
              <TableRow></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

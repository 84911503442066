import React from "react";
import { GoalCard } from "../../components/GoalCard";
import { Box, Typography } from "@mui/material";

export const GoalsWrapper = (props:any) => {
    const { data, isLoading, isSubGoal, manageOnBlur } = props;

    return (
        <Box sx={{ height: `calc(100% - 84px)`, overflowY: 'auto', mt: 2 }} onBlur={manageOnBlur}>
            {
                !isLoading && data ?
                data.map((item:any) => {
                    return (
                        <GoalCard data={item} isSubGoal={isSubGoal} />
                    )
                }) :
                <>
                </>
            }
        </Box>
    )
}
import axios from "axios";
import { baseUrl } from "./config";
import { encodeBody, encodeQueryParams } from "./client";

export const getGoals: any = (params:any) => {
    const path = `${baseUrl}/api/goals${encodeQueryParams(params)}`;
    return axios({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.data.data);
  };


export const getGoalDetail: any = (params:any) => {
  const path = `${baseUrl}/api/goals/${params?.goalId}/details`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const createGoal: any = (params:any) => {
  const path = `${baseUrl}/api/goals`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(params.goal),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data).catch((err) => console.error('Error in createGoal:', err));
};

export const updateGoal: any = (params:any) => {
  const path = `${baseUrl}/api/goals/${params?.goalId}`;
  return axios({
    method: 'PUT',
    url: path,
    data: params?.goal,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data).catch((err) => console.error('Error in updateGoal:', err));
};

export const getGoalsHeader: any = (params:any) => {
  const path = `${baseUrl}/api/goals/headers/${params.clubId}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data).catch((err) => console.error('Error in getGoalsHeader:', err));
};

export const updateGoalsHeader: any = (params:any) => {
const path = `${baseUrl}/api/goals/headers/${params.clubId}`;
return axios({
  method: 'POST',
  url: path,
  data: encodeBody(params.header),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
}).then(response => response.data).catch((err) => console.error('Error in updateGoalsHeader:', err));
};
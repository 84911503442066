import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ITEM_HEIGHT = 48;

interface ThreeDotMenuProps {
  options: any[];
  iconColor?: string;
  manageClick?: (value: any) => void;
  pathId?: any;
}

const NestedMenuItem: React.FC<{
  option: any;
  manageClick: any;
  pathId?: any;
}> = ({ option, manageClick, pathId }) => {
  const [subMenuAnchorEl, setSubMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const openSubMenu = Boolean(subMenuAnchorEl);

  const handleSubMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setSubMenuAnchorEl(event.currentTarget);
  };

  const handleSubMenuClose = (d: any) => {
    manageClick(d);
    setSubMenuAnchorEl(null);
  };

  return (
    <React.Fragment key={option.label}>
      <MenuItem
        sx={{ fontWeight: 600, fontSize: 13 }}
        onClick={handleSubMenuClick}
        disabled={option.isDisabled}
      >
        {option?.icon && <FontAwesomeIcon className='mr10' icon={option.icon} />}
        {option.label}
      </MenuItem>
      <Menu
        anchorEl={subMenuAnchorEl}
        open={openSubMenu}
        onClose={() => handleSubMenuClose(option.label)}
        MenuListProps={{
          'aria-labelledby': 'submenu-button',
        }}
      >
        {option?.subMenu.length && option?.subMenu.map((subOption: any) => (
          // <NestedMenuItem
          //   key={subOption?.label}
          //   option={subOption}
          //   manageClick={handleSubMenuClose}
          // />
            <MenuItem
            sx={{ fontWeight: 600, fontSize: 13 }}
            onClick={() => {handleSubMenuClose(subOption)}}
            disabled={subOption.isDisabled}
            >
              {subOption?.icon && <FontAwesomeIcon className='mr10' icon={option.icon} />}
              {subOption.label}
            </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

const NestedThreeDotMenu: React.FC<ThreeDotMenuProps> = (props) => {
  const {options, iconColor, manageClick, pathId } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);  

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (d: any) => {
    // @ts-ignore
    manageClick(d);
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={iconColor ? { color: iconColor, p: 0 } : { p: 0 }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(null)}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'auto'
          },
        }}
      >
        {
          options?.length &&
        options?.map((option: any) => 
          ('subMenu' in option && option.subMenu.length > 0) ? (
            <NestedMenuItem
              key={option.label}
              option={option}
              manageClick={handleClose}
              pathId={pathId}
            />
          ) : (
            <MenuItem
              sx={{ fontWeight: 600, fontSize: 11 }}
              key={option.label}
              selected={option.label === 'Pyxis'}
              onClick={() => handleClose(option.label)}
              disabled={option.isDisabled}
            >
              {option?.icon && <FontAwesomeIcon className='mr10' icon={option.icon} />}
              {option.label}
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
};

export default NestedThreeDotMenu;

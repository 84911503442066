export function sortData(arr: any, key: any, order: any) {
  let arrayToSort = Array.isArray(arr) ? [...arr] : [];
  return arrayToSort.sort((a: any, b: any) => {
    if (key == "uid" && !a[key].includes(".")) {
      if (parseInt(a[key].slice(1)) < parseInt(b[key].slice(1))) {
        return order == "asc" ? -1 : 1;
      }
      if (parseInt(a[key].slice(1)) > parseInt(b[key].slice(1))) {
        return order == "asc" ? 1 : -1;
      }
    }
    if (a[key] < b[key]) {
      return order == "asc" ? -1 : 1;
    }
    if (a[key] > b[key]) {
      return order == "asc" ? 1 : -1;
    }
    return 0;
  });
}

export function sortDataByClub(arr: any, key: any, order: any) {
  let arrayToSort = Array.isArray(arr) ? [...arr] : [];
  return arrayToSort.sort((a: any, b: any) => {
    if (key == "uid" && !a.club[key].includes(".")) {
      if (parseInt(a.club[key].slice(1)) < parseInt(b.club[key].slice(1))) {
        return order == "asc" ? -1 : 1;
      }
      if (parseInt(a.club[key].slice(1)) > parseInt(b.club[key].slice(1))) {
        return order == "asc" ? 1 : -1;
      }
    }
    if (a.club[key] < b.club[key]) {
      return order == "asc" ? -1 : 1;
    }
    if (a.club[key] > b.club[key]) {
      return order == "asc" ? 1 : -1;
    }
    return 0;
  });
}

export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
  from?: any
) {
  // if(from == 'works' && array.length > 0) {
  //     let stabilizedThis:any = []
  //     if(array && array?.length > 0) {
  //         stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  //         stabilizedThis.sort((a:any, b:any) => {
  //             const order = comparator(a[0], b[0]);
  //             if (order !== 0) {
  //             return order;
  //             }
  //             return a[1] - b[1];
  //         });
  //     }
  //     return stabilizedThis?.length ? stabilizedThis?.map((el:any) => el[0]) : array;
  // }
  return array;
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: any,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

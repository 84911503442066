import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  OutlinedInput,
  InputLabel,
  FormControl,
} from "@mui/material";
import { createTheme, styled } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import { colors } from "../../common/pallette/colors";
import { AvatarAndName } from "../AvatarAndName";
import { baseUrl } from "../../api/config";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: "0px 14px !important",
          // border: "2px solid " + colors.black.dark,
        },
        input: {
          padding: "8px 14px !important",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: "12px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors.black.dark,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "12px",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "0px 9px",
        },
      },
    },
  },
});

export default function AvatarMultiSelectCheckBox(props: any) {
  const { label, options, selectedValues, setSelectedValues, manageOnFocus } =
    props;
  const selectRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.focus();
    }
  }, [selectRef]);

  const handleChange = (selectedObj:any) => {
    setSelectedValues(selectedObj);
  };
  
  return (
    <ThemeProvider theme={theme}>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          multiple
          fullWidth
          autoFocus
          value={selectedValues || []}
          inputRef={selectRef}
          input={<OutlinedInput autoFocus label={label} />}
          renderValue={(selected) =>
            selected.map((opt: any) => opt?.displayName).join(", ")
          }
          MenuProps={{
            disablePortal: true,
            disableAutoFocusItem: true,
            PaperProps: {
              onMouseDown: (e: any) => e.stopPropagation(),
            },
          }}
          onMouseDown={(e) => {
            e.stopPropagation();
            manageOnFocus(e);
          }}
          onClick={(e) => {
            e.stopPropagation();
            manageOnFocus(e);
          }}
        >
          {options.map((option: any) => (
            <MenuItem
              key={option}
              value={option}
              onClick={(e) => {
                handleChange(option);
                e.stopPropagation();
                manageOnFocus(e);
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
                manageOnFocus(e);
              }}
            >
              <Checkbox
                sx={{
                  transform: "scale(0.8)", 
                  padding: "0px",
                }}
                checked={selectedValues?.some(
                  (item: any) => item.id == option.id
                )}
              />
              <AvatarAndName
                avatarName={option.displayName || ""}
                avatarImage={`${baseUrl}/Content${option.avatarPath}`}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
}

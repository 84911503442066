import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
//internal and external components import
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { Checkbox, TableRow, Typography } from '@mui/material/';
import { Box } from '@mui/material';
import TableSkeleton from '../TableSkeleton';
import { sortData } from '../../common/SortSupport';
import { faCirclePlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import ButtonDefault from '../ButtonDefault';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { calcTaskPercentage } from '../../common/StaticExports';
import ProgressBar from '../ProgressBar';
import { EnhancedTableHead } from '../EnhancedTableHead';
import { AvatarAndName } from '../AvatarAndName';

const SubGoalsTable = (props:any) => {

  const { data, isLoading, handleChange } = props;
  const initialTask = {
    "id": null,
    "task": null,
    "checked": false
  }

  const [t] = useTranslation();

  //supporting contents
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  let visibleRows = React.useMemo(
    () =>
      data && data.length > 0 &&
      sortData(data, orderBy, order),
    [order, orderBy, data],
  );

  const head = [
    {
      key: '', displayName: '',
    },
    {
      key: '', displayName: '',
    },
    {
      key: '', displayName: '',
    },
  ]

  //table contents
    return(
        <React.Fragment>
            <Box sx={{ position: 'relative', width: '100%' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Typography className='cardTitle'>{`${data?.length} ${t('SUB_GOALS')}`}</Typography>
                  <ButtonDefault
                  variant={'outlined'}
                  value={t('ADD_SUB_GOAL')}
                  manageOnClick={(e:any) => {handleChange(e, 'addSubGoal', data)}}
                  icon={<FontAwesomeIcon className={'fs13'} icon={faCirclePlus} />}
                  />
              </Box>
              <TableContainer 
              sx={{ 
                "& .MuiTable-root": {
                  border: 0,
                },
                "& .MuiTableCell-root": {
                  borderBottom: "none", // Remove bottom borders from table cells
                },
                "& .MuiTableRow-root": {
                  border: "none", // Remove row borders
                }
              }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                    {
                      !isLoading && visibleRows.length ?
                      <TableBody>
                        {
                          visibleRows.map((d: any, i: number) => (
                            <>
                              <TableRow
                              sx={{ cursor: 'pointer' }}
                              key={`tasks-table-row-${i}`}
                              >
                              <TableCell className='fs12' width={'400px'}>
                                {d?.name ? d.name : ''}
                              </TableCell>
                              <TableCell sx={{ width: '900px' }}>
                                <ProgressBar percent={d?.progress ? d.progress : 0} width='100%'/>
                              </TableCell>
                              <TableCell className='fs12' sx={{ width: '40px' }}>
                                <AvatarAndName avatarImage={d?.members[0]?.userImg} />
                              </TableCell>
                              </TableRow>
                            </>
                          ))
                        }
                      </TableBody>
                      :
                      (
                        !isLoading && !visibleRows ?
                        <TableBody>
                          <TableRow>{'No Records Found'}</TableRow>
                        </TableBody> :
                        <TableBody>
                          <TableSkeleton rowCount={4} cellCount={3} />
                        </TableBody>
                      )
                    }
                </Table>
              </TableContainer>
            </Box>
        </React.Fragment>
    )
}

export default SubGoalsTable;
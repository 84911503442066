import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { resetSelectedGoal } from "../../redux/reducers/goalsAndStrategy";

const GoalsBreadCrumb = () => {
    const navigate = useNavigate();
    const [t] = useTranslation();
    const location = useLocation();
    const selectedGoal = useSelector((state:any) => state.goalsAndStrategy.selectedGoal);
    const dispatch = useDispatch();

    return (
        <Box sx={{ display: "flex" }}>
        <Typography className="fs14 uppercase cursorPointer fw700"
        onClick={() => {
            navigate('/goals');
            dispatch(resetSelectedGoal());
        }}>
            {`${t('ACADEMY')} ${t('GOALS')}`}
        </Typography>
        {
            selectedGoal && selectedGoal != undefined &&
            selectedGoal != null && location.pathname.includes('/goals/') &&
            <>
            <Typography className="fs14 mr10 ml10 fw700">
                {`>`}
            </Typography>
            <Typography className="fs14 fw700">
                {`${selectedGoal.name}`}
            </Typography>
            </>
        }
        </Box>
    )
}

export default GoalsBreadCrumb;
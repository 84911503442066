import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { IconButton, Divider, List, ListItem, ListItemButton, ListItemIcon, styled, Theme, CSSObject,useTheme, Box, createTheme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faE, faL, faGear, faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ThemeProvider } from '@emotion/react';
import { relative } from 'path';
import Tooltip from '@mui/material/Tooltip';
import { colors } from '../../common/pallette/colors';
import { useNavigate } from 'react-router-dom';
import { setInitialClub } from '../../redux/reducers/clubDetails';
import ChangeClubPopup from '../../containers/ChangeClubPopup';


const theme = createTheme({
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    zIndex: 900,
                    width: 'auto',
                    border: 0,
                    // boxShadow: '3px 0px 5px #7D8793',
                    backgroundColor: colors.black.dark,
                    // marginLeft: -40,
                    // transition: 'margin-left 0.5s',
                    // '&:hover': {
                    //     marginLeft: 0
                    // }
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: 0
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    padding: 0,
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    padding: '10px 14px',
                    textAlign: 'center',
                    color: colors.white.bright,
                    '&:hover': {
                        backgroundColor: colors.white.light
                    }
                }
            }  
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 'auto',
                    color: colors.white.bright
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    padding: 0
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    textAlign: 'left',
                    marginLeft: 20,
                    marginTop: 0,
                    marginBottom: 0,
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.white.bright
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: 13
                }
            }
        }
    }
})

const AppDrawer = (props: any) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const selectedClub = useSelector((state: any) => state.clubDetails);

    const {data} = props;

    const [drawer, setDrawer] = React.useState('Home');
    const [isOpen, setIsOpen] = React.useState(false);
    const [popUpisOpen, setPopUpIsOpen] = React.useState(false);

    const handleDrawerClick = (data: any) => {
        // if(selectedClub.value.name == 'J League' && data !== 'Home') {
        //     setPopUpIsOpen(true);
        // }
        switch(data) {
            case 'Home': 
                dispatch(setInitialClub());
                setDrawer('Home');
                navigate('/adminhome');
                break;
            case 'Evaluation':
                setDrawer('Evaluation');
                navigate('/selfassessment');
                break;
            case 'Licence':
                // setPopUpIsOpen(true);
                setDrawer('Licence');
                navigate('/clublicence');
                break;
            case 'Evidence Library':
                // setPopUpIsOpen(true);
                setDrawer('Evidence Library');
                navigate('/evidencelibrary');
                break;
            case 'Performance Plan':
                setDrawer('Performance Plan');
                navigate('/performanceplan');
                break;
            case 'Goals & Strategy':
                setDrawer('Goals & Strategy');
                navigate('/goals');
                break;
            case 'Performance Plan & Strategy':
                setDrawer('Performance Plan & Strategy');
                navigate('/performanceplan')
                break;
            case 'Dashboard':
                setDrawer('Dashboard');
                navigate('/evaluationdashboard')
                break;
        }
    }

    return(
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <ChangeClubPopup isOpen={popUpisOpen} handleIsOpen={() => {
                    if(selectedClub.value.name == 'J League' && data !== 'Home') {
                        navigate('/');
                        setDrawer('Home');
                    }
                    setPopUpIsOpen(false)
                }}/>
                <MuiDrawer variant="permanent">
                    <Box>
                        <ListItemButton onClick={() => setIsOpen(!isOpen)}>
                            {!isOpen ? <FontAwesomeIcon icon={faBars} /> : <FontAwesomeIcon style={{ marginLeft: 'auto' }} icon={faXmark} />}
                        </ListItemButton>
                    </Box>
                    <Divider/>
                    <List sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
                        <Box>
                            <Tooltip title={isOpen ? '' : 'Home'} placement='right' arrow>
                                <ListItem onClick={() => handleDrawerClick('Home')}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <FontAwesomeIcon className='mlrAuto' icon={faHouse} color={ drawer == 'Home' ? colors.blue.navy : '' } />
                                        </ListItemIcon>
                                        {isOpen && <ListItemText>Home</ListItemText>}
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                            {
                                data.map((d: any, i: any) => {
                                    return(
                                        <Tooltip title={isOpen ? '' : d.title} placement='right' arrow>
                                            <ListItem onClick={() => handleDrawerClick(d.title)}>
                                                <ListItemButton>
                                                    <ListItemIcon><FontAwesomeIcon className='mlrAuto' icon={d.icon} color={ drawer == d.title ? colors.blue.navy : '' } /></ListItemIcon>
                                                    {isOpen && <ListItemText>{d.title}</ListItemText>}
                                                </ListItemButton>
                                            </ListItem>
                                        </Tooltip>
                                    )
                                })
                            }
                        </Box>
                        <Box>
                            <Divider />
                            <Tooltip title={isOpen ? '' : 'Settings'} placement='right' arrow>
                                <ListItem>
                                    <ListItemButton onClick={() => setDrawer('Settings')}>
                                        <ListItemIcon><FontAwesomeIcon className='mlrAuto' icon={faGear} /></ListItemIcon>
                                        {isOpen && <ListItemText>Settings</ListItemText>}
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Box>
                    </List>
                </MuiDrawer>
            </ThemeProvider>
        </React.Fragment>
    )
}

export default AppDrawer;
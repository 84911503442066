import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PeopleTable from "./PeopleTable";
import ClubsTable from "./ClubsTable";
import SearchFilterContainer from "../../../containers/SearchFilterContainer";
import { Box } from "@mui/material";
import "./styles.css";
import { SwitchButton } from "../../../components/SwitchButton";
import { setIsActions } from "../../../redux/reducers/actionPlan";
import {
  getClubAssociatedPeopleDetails,
  getClubPeopleDetails,
} from "../../../api/evaluationDashboard";
import {
  setClubsAssociatedPeople,
  setClubsPeople,
} from "../../../redux/reducers/evaluationDashboard";

export default function PeoplePage() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const isActions = useSelector((state: any) => state.actionPlan.isActions);

  const othersOptions = [{ id: 1, name: "UNASSIGNED", value: "unassigned" }];

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setClubsPeople(await getClubPeopleDetails()));
      dispatch(
        setClubsAssociatedPeople(await getClubAssociatedPeopleDetails())
      );
    };

    fetchData();
  }, [dispatch]);
  return (
    <>
      <Box className="PeopleHeader">
        <SearchFilterContainer
          parent={"evaluationDashboard"}
          othersOptions={othersOptions}
        />
        <div className="SwitchButtonToggle">
          <SwitchButton
            label={t("Clubs")}
            label01={t("PEOPLE")}
            isOn={isActions}
            handleOnClick={() => {
              const changeBool: any = !isActions;
              dispatch(setIsActions(changeBool));
            }}
          />
        </div>
      </Box>
      <br />
      {!isActions ? <PeopleTable /> : <ClubsTable />}
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SelectBoxDefault from "../SearchSelectBox/SelectBoxDefault";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { privacyStatus } from "../../common/StaticExports";

const PrivacySelector = (props:any) => {
    const { value, manageSelect, manageOnBlur } = props;
    const [inputValue, setInputValue] = React.useState((value != undefined && value != null) ? (privacyStatus[value].statusName || '') : '');
    const [optionList, setOptionList] = useState(privacyStatus || []);
    const [t] = useTranslation();

    const manageHandleChange = (_event: any, newValue: any) => {
      let tempObj:any = Object;
      
      if (newValue == null || newValue == undefined) {
        tempObj = {privacy: 0};
      } else {
        const index = privacyStatus.findIndex((item:any) => item.statusName == newValue.statusName);
        tempObj = {privacy: index};
      }
      manageSelect(tempObj);
    };
  
    const manageHandleInputChange = (_event: any, newInputValue: string) => {
      (newInputValue != undefined || newInputValue != 'undefined') &&
      setInputValue(newInputValue);
      // setOptionList(rabgStatus);
    };

    useEffect(() => {
      if(inputValue == null || inputValue == '') {
        setInputValue(privacyStatus[value]?.statusName);
      }
    }, [value]);

    return (
      <Box sx={{ display: "flex"}}>
        <Typography className="fs13 fw700 mtbAuto">{t('PRIVACY')}</Typography>
        <Box sx={{ width: '120px', ml: '5px' }} onBlur={manageOnBlur}>
          <SelectBoxDefault
          options={optionList}
          keyLabel={'statusName'}
          handleChange={manageHandleChange}
          variant={'standard'}
          selectedValue={value}
          handleInputChange={manageHandleInputChange}
          inputValue={t(inputValue)}
          />
        </Box>
      </Box>
    )
}

export default PrivacySelector;
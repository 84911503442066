import React, { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { EnhancedTableHead } from "../../../components/EnhancedTableHead";
import TableSkeleton from "../../../components/TableSkeleton";
import { useDispatch, useSelector } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { filteredList, formatDate, getStars } from "../Utils";
import TimelineDetails from "./TimelineDetails";
import AutoComplete from "../../../components/AutoComplete";
import { setTimelineMonth } from "../../../redux/reducers/evaluationDashboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";

interface Timeline {
  month: string;
  selfAssesment: number;
  selfAssesmentClubs: clubDetail[];
  offSite: number;
  offSiteClubs: clubDetail[];
  onSite: number;
  onSiteClubs: clubDetail[];
  interimReport: number;
  interimReportClubs: clubDetail[];
  finalReport: number;
  finalReportClubs: clubDetail[];
}

interface clubDetail {
  targetDate: string;
  id: number;
  name: string;
  currentRating: number;
  targetRating: number;
  league: {
    id: number;
    name: string;
    createdDate: string;
    updatedDate: string;
  };
  color: string;
  textColor: string;
  imagePath: string;
  createdDate: string;
  updatedDate: string;
  japaneseName: string;
}

function createData(
  month: string,
  selfAssesment: number,
  selfAssesmentClubs: clubDetail[],
  offSite: number,
  offSiteClubs: clubDetail[],
  onSite: number,
  onSiteClubs: clubDetail[],
  interimReport: number,
  interimReportClubs: clubDetail[],
  finalReport: number,
  finalReportClubs: clubDetail[]
) {
  return {
    month,
    selfAssesment,
    selfAssesmentClubs,
    offSite,
    offSiteClubs,
    onSite,
    onSiteClubs,
    interimReport,
    interimReportClubs,
    finalReport,
    finalReportClubs,
  };
}

interface ColumnHead {
  label: string;
  component: string;
  count: string;
}

const columnHead: ColumnHead[] = [
  {
    label: "SELF_ASSESSMENT",
    component: "selfAssesmentClubs",
    count: "selfAssesment",
  },
  { label: "OFF_SITE", component: "offSiteClubs", count: "offSite" },
  { label: "ON_SITE", component: "onSiteClubs", count: "onSite" },
  {
    label: "INTERIM_REPORT",
    component: "interimReportClubs",
    count: "interimReport",
  },
  {
    label: "FINAL_REPORT",
    component: "finalReportClubs",
    count: "finalReport",
  },
];

const applyLeagueFilter = (clubs: clubDetail[], leagueFilter: any) => {
  return clubs?.filter((club) =>
    leagueFilter?.some((item: any) =>
      club.league?.name?.toLowerCase().includes(item.name.toLowerCase())
    )
  );
};

const applyOtherFilter = (row: any, otherFilter: any) => {
  return otherFilter?.every((filterItem: any) => {
    if (filterItem.value === "set") {
      return (
        row.targetDate != null &&
        row.selfAssesment1 != null &&
        row.selfAssesment2 != null &&
        row.offSite != null &&
        row.onSite != null &&
        row.interimReport != null &&
        row.finalReport != null
      );
    } else if (filterItem.value === "notset") {
      return (
        row.targetDate == null ||
        row.selfAssesment1 == null ||
        row.selfAssesment2 == null ||
        row.offSite == null ||
        row.onSite == null ||
        row.interimReport == null ||
        row.finalReport == null
      );
    }
    return true;
  });
};

const applyStarRatingFilter = (list: clubDetail[], filter: any) => {
  return filter?.length > 0
    ? list?.filter((row) =>
        filter?.some(
          (item: any) => row.targetRating?.toString() === item.value.toString()
        )
      )
    : list;
};

const filterTimelineData = (
  otherFilter: any,
  leagueFilter: any,
  targetStarRatingFilter: any,
  filteredRows: Timeline[]
) => {
  return filteredRows.map((item) => {
    const month = item.month;

    let filteredSelfAssesmentList = applyLeagueFilter(
      item?.selfAssesmentClubs,
      leagueFilter
    );
    let filteredOffSiteList = applyLeagueFilter(
      item?.offSiteClubs,
      leagueFilter
    );
    let filteredOnSiteList = applyLeagueFilter(item?.onSiteClubs, leagueFilter);
    let filteredInterimReportList = applyLeagueFilter(
      item?.interimReportClubs,
      leagueFilter
    );
    let filteredFinalReportList = applyLeagueFilter(
      item?.finalReportClubs,
      leagueFilter
    );

    // Apply 'otherFilter' conditions
    if (otherFilter.length > 0) {
      filteredSelfAssesmentList = filteredSelfAssesmentList?.filter((row) =>
        applyOtherFilter(row, otherFilter)
      );
      filteredOffSiteList = filteredOffSiteList?.filter((row) =>
        applyOtherFilter(row, otherFilter)
      );
      filteredOnSiteList = filteredOnSiteList?.filter((row) =>
        applyOtherFilter(row, otherFilter)
      );
      filteredInterimReportList = filteredInterimReportList?.filter((row) =>
        applyOtherFilter(row, otherFilter)
      );
      filteredFinalReportList = filteredFinalReportList?.filter((row) =>
        applyOtherFilter(row, otherFilter)
      );
    }

    // Apply targetStarRatingFilter
    filteredSelfAssesmentList = applyStarRatingFilter(
      filteredSelfAssesmentList,
      targetStarRatingFilter
    );
    filteredOffSiteList = applyStarRatingFilter(
      filteredOffSiteList,
      targetStarRatingFilter
    );
    filteredOnSiteList = applyStarRatingFilter(
      filteredOnSiteList,
      targetStarRatingFilter
    );
    filteredInterimReportList = applyStarRatingFilter(
      filteredInterimReportList,
      targetStarRatingFilter
    );
    filteredFinalReportList = applyStarRatingFilter(
      filteredFinalReportList,
      targetStarRatingFilter
    );

    return createData(
      month,
      filteredSelfAssesmentList?.length,
      filteredSelfAssesmentList,
      filteredOffSiteList?.length,
      filteredOffSiteList,
      filteredOnSiteList?.length,
      filteredOnSiteList,
      filteredInterimReportList?.length,
      filteredInterimReportList,
      filteredFinalReportList?.length,
      filteredFinalReportList
    );
  });
};

export default function TimelineTable() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const clubsTimeline: any = useSelector(
    (state: any) => state.evaluationDashboard.clubsTimeline
  );
  const searchString: string = useSelector(
    (state: any) => state.evaluationDashboard.searchString
  );
  const targetStarRatingFilter: any = useSelector(
    (state: any) => state.evaluationDashboard.targetStarRatingFilter
  );
  const leagueFilter: any = useSelector(
    (state: any) => state.evaluationDashboard.leagueFilter
  );
  const otherFilter: any = useSelector(
    (state: any) => state.evaluationDashboard.otherFilter
  );
  const timelineMonth: any = useSelector(
    (state: any) => state.evaluationDashboard.timelineMonth
  );
  const [viewMonth, setViewMonth] = useState(timelineMonth);
  const [currentTimelineClubs, setCurrentTimelineClubs] =
    useState<any[]>(clubsTimeline);
  const [arrowButton, setArrowButtons] = useState<any>("left");

  const manageTimelineMonth = (event: any) => {
    dispatch(setTimelineMonth(event.target.value));
    setViewMonth(event.target.value);
  };

  const handleFirstButtonClick = () => {
    setCurrentTimelineClubs(clubsTimeline.slice(0, 6));
    setArrowButtons("right");
  };
  const handleLastButtonClick = () => {
    setCurrentTimelineClubs(clubsTimeline.slice(-6));
    setArrowButtons("left");
  };

  const filteredRows = useMemo(() => {
    return currentTimelineClubs.map((item: any) => {
      const filterClubs = (clubs: clubDetail[]) =>
        clubs?.filter((row) =>
          row.name?.toLowerCase().includes(searchString.toLowerCase())
        );
      return createData(
        item.month,
        filterClubs(item?.selfAssesmentClubs)?.length,
        filterClubs(item?.selfAssesmentClubs),
        filterClubs(item?.offSiteClubs)?.length,
        filterClubs(item?.offSiteClubs),
        filterClubs(item?.onSiteClubs)?.length,
        filterClubs(item?.onSiteClubs),
        filterClubs(item?.interimReportClubs)?.length,
        filterClubs(item?.interimReportClubs),
        filterClubs(item?.finalReportClubs)?.length,
        filterClubs(item?.finalReportClubs)
      );
    });
  }, [arrowButton, clubsTimeline, searchString]);

  const timelineData = useMemo(() => {
    if (
      otherFilter.length ||
      leagueFilter.length ||
      targetStarRatingFilter.length
    ) {
      return filterTimelineData(
        otherFilter,
        leagueFilter,
        targetStarRatingFilter,
        filteredRows
      );
    }
    return filteredRows;
  }, [filteredRows, otherFilter, leagueFilter, targetStarRatingFilter]);

  // Handle sort logic
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Handle cell click logic
  const [activeCell, setActiveCell] = useState<{
    row: number;
    col: number;
  } | null>(null);
  const [clubDetails, setClubDetails] = useState<clubDetail[]>([]);

  const handleCellClick = (
    rowIndex: number,
    colIndex: number,
    value: number
  ) => {
    if (value === 0) return;
    if (activeCell?.row === rowIndex && activeCell?.col === colIndex) {
      setClubDetails([]);
      setActiveCell(null);
    } else {
      setActiveCell({ row: rowIndex, col: colIndex });
    }
  };

  const getColor = (count: number) => {
    if (count > 15) return "#74a0d9";
    if (count > 10) return "#9ebde4";
    if (count > 5) return "#A0C4E4";
    if (count > 0) return "#C1D7EE";
    return "#ffffff";
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: 1,
          position: "relative",
        }}
      >
        <Typography style={{ fontSize: "14px", fontFamily: "Montserrat" }}>
          {t("VIEW")}
        </Typography>
        <FormControl sx={{ m: 1, minWidth: 150 }}>
          <Select
            value={viewMonth}
            onChange={manageTimelineMonth}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              height: 30, // reduce the height of the dropdown
              borderRadius: 4, // add rounded corners
              padding: "0 8px", // reduce padding to shrink the height further
            }}
          >
            <MenuItem value={6}>6 months</MenuItem>
            <MenuItem value={9}>9 months</MenuItem>
            <MenuItem value={12}>12 months</MenuItem>
          </Select>
        </FormControl>

        {/* Right Arrow and Left Arrow buttons */}
        <Box sx={{ display: "flex", position: "absolute", right: 0 }}>
          <button
            onClick={() => handleFirstButtonClick()}
            disabled={arrowButton === "right"}
            className="iconButton"
          >
            <FontAwesomeIcon
              className="icon"
              icon={faChevronCircleLeft}
              style={{ height: "3vh" }} // Icon size
            />
          </button>
          <button
            onClick={() => handleLastButtonClick()}
            disabled={arrowButton === "left"}
            className="iconButton"
          >
            <FontAwesomeIcon
              className="icon"
              icon={faChevronCircleRight}
              style={{ height: "3vh" }} // Icon size
            />
          </button>
        </Box>
      </Box>
      <Box sx={{ width: "100%", display: "flex", gap: 4 }}>
        <TableContainer
          sx={{ maxHeight: "100vh", overflowY: "auto", flexGrow: 1 }}
        >
          <Table
            sx={{ minWidth: 700 }}
            stickyHeader
            aria-label="sticky table"
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    padding: "2%",
                    fontWeight: "bold",
                    fontSize: "14px",
                    fontFamily: "Montserrat",
                  }}
                >
                  {t("TIMELINE")}
                </TableCell>
                {timelineData?.length > 0
                  ? timelineData.map((item: any) => (
                      <TableCell
                        key={item.month}
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          fontFamily: "Montserrat",
                        }}
                        align="left"
                      >
                        {item.month}
                      </TableCell>
                    ))
                  : [...Array(6)].map((_, idx) => (
                      <TableCell key={idx} align="left">
                        <Skeleton animation="wave" />
                      </TableCell>
                    ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {columnHead.map((item: any, rowIndex: number) => (
                <TableRow
                  key={rowIndex}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      padding: "2%",
                      fontSize: "14px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {t(item.label)}
                  </TableCell>
                  {timelineData.length > 0
                    ? timelineData.map((dataItem: any, colIndex: number) => {
                        const cellValue = dataItem[item.count] || 0;
                        return (
                          <TableCell
                            key={`${rowIndex}-${colIndex}`}
                            component="th"
                            scope="row"
                            sx={{
                              padding: "2%",
                              cursor: cellValue > 0 ? "pointer" : "not-allowed",
                              background: getColor(cellValue),
                              color: cellValue === 0 ? "#aaa" : "inherit",
                              border:
                                activeCell?.row === rowIndex &&
                                activeCell?.col === colIndex
                                  ? "2px solid #000"
                                  : "",
                              "&:hover":
                                cellValue > 0
                                  ? { backgroundColor: "#f0f0f0" }
                                  : {},
                              "&:active": { backgroundColor: "#dcdcdc" },
                            }}
                            onClick={() => {
                              if (cellValue > 0) {
                                handleCellClick(rowIndex, colIndex, cellValue);
                                setClubDetails(dataItem[item.component]);
                              }
                            }}
                          >
                            {cellValue}
                          </TableCell>
                        );
                      })
                    : [...Array(6)].map((_, idx) => (
                        <TableCell key={idx} component="th" scope="row">
                          <Skeleton animation="wave" />
                        </TableCell>
                      ))}
                </TableRow>
              ))}
              <TableRow></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {activeCell != null && clubDetails?.length > 0 && (
          <Box
            sx={{
              minWidth: "60vh", // Set a fixed width for the TimelineDetails section
              maxWidth: "80vh", // Set a fixed width for the TimelineDetails section
              flexShrink: 0, // Prevent the TimelineDetails from shrinking
              overflow: "auto", // If needed, add scrolling if the content overflows
            }}
          >
            <TimelineDetails
              clubDetails={clubDetails}
              order={order}
              orderBy={orderBy}
              handleRequestSort={handleRequestSort}
            />
          </Box>
        )}
      </Box>
    </>
  );
}

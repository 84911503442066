import { createSlice } from "@reduxjs/toolkit"

let initialData:any = {
    tabs: [
        'ACADEMY_GOALS',
        'DEPARTMENT_GOALS',
        'MY_DEVELOPMENT',
        'STRATEGY_ANALYSIS',
        'CONFIG',
    ],
    currentTab: 'ACADEMY_GOALS',
    goals: [],
    selectedGoal: null,
    goalsHeader: {
        vision: null,
        northStar: null,
    },
}

export const goalsAndStrategy = createSlice({
    name: 'goalsAndStrategy',
    initialState: initialData,
    reducers: {
        setCurrentTab: (state:any, action:any) => {
            state.currentTab = action.payload;
            return state;
        },
        setGoals: (state:any, action:any) => {
            state.goals = action.payload;
            return state;
        },
        setSelectedGoal: (state:any, action:any) => {
            state.selectedGoal = action.payload;
            return state;
        },
        resetSelectedGoal: (state:any) => {
            state.selectedGoal = initialData.selectedGoal;
            return state;
        },
        setGoalsHeader: (state:any, action:any) => {
            state.goalsHeader = action.payload;
            return state;
        },
        resetGoalsHeader: (state:any) => {
            state.selectedGoal = initialData.selectedGoal;
            return state;
        },
    }
});

export const {
    setCurrentTab,
    setGoals,
    setSelectedGoal,
    resetSelectedGoal,
    setGoalsHeader,
    resetGoalsHeader,
} = goalsAndStrategy.actions;
export default goalsAndStrategy.reducer;
import React, { useState } from "react";
import { createTheme, TextField } from "@mui/material";
import { colors } from "../../common/pallette/colors";
import { ThemeProvider } from "@emotion/react";

const TextAreaOrderedList = (props:any) => {
  const { placeholder, manageOnBlur, rows, handleChange } = props;
  const [value, setValue] = useState("");
  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&.Mui-focused": {
              backgroundColor: colors.grey.bright,
            },
            padding: 0,
          },
          input: {
            padding: "6px",
            fontSize: "12px",
          },
        },
      },
    },
  });

  const handleChangeInternal = (event: any) => {
    const newValue = event.target.value;
    setValue(newValue);
    handleChange(newValue);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default new line behavior

      const lines = value.split("\n");
      const nextNumber = lines.length + 1; // Determine next number
      const newValue = value + `\n${nextNumber}. `; // Append new line with number

      setValue(newValue);
      handleChange(newValue);
    }
  };
  const handleClick = () => {
    if (!value.trim()) {
      setValue("1. "); // Start numbering if empty
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <TextField
        rows={rows ? rows : 4}
        multiline
        fullWidth
        value={value}
        onClick={handleClick}
        onChange={handleChangeInternal}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        variant="outlined"
        onBlur={manageOnBlur}
      />
    </ThemeProvider>
  );
};

export default TextAreaOrderedList;

import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { makeLogin, requestPasswordReset } from '../../../api/auth';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAdmin, setIsClubChecker, setIsClubMaker, setToken, setUserDetails } from '../../../redux/reducers/common';
import { useNavigate } from "react-router-dom";
import { Spacer } from '../../../components/Spacer/Spacer';
import { ToastContainer, toast } from 'react-toastify';
import { getClub } from '../../../api/club';
import { setCurrentClub } from '../../../redux/reducers/clubDetails';
import { getUser } from '../../../api/user';

export const Card = styled.div`
  border-radius: 6px;
  background: #fff;
`;


const Div = styled.div`
  position: relative;
  width: 450px;
  height: 617px;
  margin: 0 50px;
  perspective: 1000px;

  #inner {
    transform-style: preserve-3d;
    width: 100%;
    height: 100%;
  }
`;

const StyledCard = styled(Card)<{ flipped?: boolean }>`
  min-width: 300px;
  padding: 59px;
  position: absolute;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  z-index: 10;
  box-shadow: 4px 3px 8px 0px rgba(0, 0, 0, 0.13);

  ${({ flipped }) =>
    flipped &&
    css`
      transform: rotateY(180deg);
    `};

  .input {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.94;
    letter-spacing: normal;
    background: #ebebeb;
  }

  #button {
    border-radius: 4px;
    height: 62px;
    width: 100%;
  }

  #title {
    font-size: 28px;
    font-weight: bold;
    text-align: left;
    color: $#181820;
  }

  #description {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.94;
    letter-spacing: normal;
    text-align: left;
    color: #181820;
  }

  #forgot-password {
    color: #4c65c0;
    float: right;
  }
`;

const LoginForm = () => {
  const { t } = useTranslation();
  const [requested, setRequested] = useState(false);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let token:any = localStorage.getItem('_jauth_');
  const parsedAuth = JSON.parse(token);
  
  // const [requestReset] = useMutation(requestPasswordReset, {
  //   onSuccess: () => {
  //     setRequested(true);
  //   },
  //   onError: () => {
  //     toast.error(t('INVALID_USERNAME_OR_EMAIL'));
  //   },
  // });

  // const [{ rotate, scale, z }, set] = useSpring(() => ({
  //   rotate: 0,
  //   z: 0,
  //   scale: 1,
  //   config: { mass: 5, tension: 300, friction: 80, duration: 600 },
  // }));

  const onForgotPasswordClick = (e:any) => {
    e.preventDefault();
    setUserName('');
    setPassword('');
    setIsFlipped(!isFlipped);
    // set({ to: [{ scale: 1.05 }, { rotate: 180 }, { scale: 1 }] });
  };

  // const onBackButtonClick = (e:any) => {
  //   e.preventDefault();
  //   set({ to: [{ scale: 1.05 }, { rotate: 0 }, { scale: 1 }] });
  // };

  useEffect(() => {
    const fetchClubDetails = async (clubId:any) => {
      const response = await getClub('GET CLUB DETAILS', {id: clubId});
      dispatch(setCurrentClub(response));
    }
    token && parsedAuth && Object.keys(parsedAuth).length && parsedAuth?.clubId !== '' && parsedAuth?.clubId !== null &&
    fetchClubDetails(parsedAuth.clubId);
  }, [token, parsedAuth])

  const manageLoginClick = () => {
    const doAction = async () => {
      try {
        const data = {
          userName: userName,
          password: password
        }
        const response = await makeLogin(data);
        return response;
      } catch (error) {
        
      }
    }
    doAction().then((res:any) => {
      const decoded:any = jwtDecode(res);
      const isAdminToken = decoded.roles.includes('JLeagueAdmin');
      const isClubMakerToken = decoded.roles.includes('ClubMaker');
      const isClubCheckerToken = decoded.roles.includes('ClubChecker');
      dispatch(setIsAdmin(isAdminToken));
      dispatch(setIsClubMaker(isClubMakerToken));
      dispatch(setIsClubChecker(isClubCheckerToken));
      dispatch(setToken(decoded));
      localStorage.setItem('token', res);
      localStorage.setItem('_jauth_', 
      JSON.stringify({
        token: res,
        roles: decoded.roles,
        exp: decoded.exp,
        clubId: decoded.clubId,
        username: decoded.sub,
      }));
      if(isAdminToken) {
        navigate('/adminhome');
      } else if (isClubMakerToken || isClubCheckerToken) {
        navigate('/selfassessment');
        getUser().then((res:any) => {
          dispatch(setUserDetails(res));
          localStorage.setItem('user', JSON.stringify(res));
          localStorage.setItem('lang', res.languageCode);
        });
      }
      setUserName('');
      setPassword('');
      setIsLoading(false);
    })
  }

  const resetPassword = () => {
    const doAction = async () => {
      try {
        const data = {
          email: userName
        }
        const response = await requestPasswordReset(data);
        return response;
      } catch (error) {
        toast.error(t('INVALID_USERNAME_OR_EMAIL'));
      }
    }
    doAction().then((res:any) => {
      switch(res.UserMessage) {
        case 'CAN_NOT_FIND_USER':
          toast.error(t('CAN_NOT_FIND_USER'));
          break;
        default:
          setRequested(true);
          break;
      }
    }).catch(err => toast.error(t('INVALID_USERNAME_OR_EMAIL')));
  }

  return (
    <Div>
      {/* <animated.div
        id="inner"
        style={{
          // transform: set.to([rotate, scale], (r:any , s:any): string => ` rotateY(${r}deg) scale(${s})`),
          display: 'block',
        }}
      > */}
        <StyledCard flipped={isFlipped}>
          <span id="title">{t('LOG_IN')}</span>
          <Spacer xs={20} />
          <span id="description">{t('LOG_IN_DESC')}</span>
          <Spacer xs={59} />
          <TextField
            className="input"
            name="userName"
            placeholder={t('EMAIL_OR_USERNAME')}
            value={userName}
            onChange={(e:any) => {setUserName(e.target.value)}}
          />
          <Spacer xs={34} />
          <TextField
            className="input"
            type="password"
            name="password"
            placeholder={t('PASSWORD')}
            value={password}
            onChange={(e:any) => {setPassword(e.target.value)}}
          />
          <Spacer xs={15} />
          <a id="forgot-password" href="#" onClick={onForgotPasswordClick}>
            {t('FORGOT_PASSWORD')}
          </a>
          <Spacer xs={69} />
          <LoadingButton id="button" 
          type="button" 
          onClick={() => {manageLoginClick()}}
          loading={isLoading}
          variant='contained'
          disableElevation>
            {t('LOG_IN')}
          </LoadingButton>
        </StyledCard>
        <StyledCard flipped={!isFlipped}>
          <span id="title">{t('RESET_PASSWORD')}</span>
          <Spacer xs={20} />
          <span id="description">{t('RESET_PASSWORD_DESC')}</span>
          <Spacer xs={59} />
          <div css="min-height: 40px">
            {!requested && (
              <TextField
                className="input"
                name="email"
                placeholder={t('EMAIL_OR_USERNAME')}
                onChange={(e:any) => {setUserName(e.target.value)}}
                value={userName}
              />
            )}
          </div>
          <Spacer xs={69} />
          {requested && (
            <>
              <Spacer xs={10} />
              <span id="description">{t('FURTHER_INSTRUCTION_SENT_TO_YOUR_EMAIL')}</span>
            </>
          )}
          {!requested && (
            <LoadingButton
              id="button"
              loading={false}
              variant='contained'
              onClick={() => {resetPassword()}}
              disableElevation
            >
              {t('RESET_PASSWORD')}
            </LoadingButton>
          )}

          <Spacer xs={30} />
          <LoadingButton
            id="button"
            type="button"
            css="background-color: transparent; color: #000"
            loading={false}
            onClick={onForgotPasswordClick}
          >
            {t('BACK_TO_LOG_IN')}
          </LoadingButton>
        </StyledCard>
      {/* </animated.div> */}
      <ToastContainer />
    </Div>
  );
};

export default LoginForm;

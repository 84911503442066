import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { rabgStatus } from "../../common/StaticExports";
import StatusSelectBoxDefault from "../SearchSelectBox/StatusSelectBoxDefault";

const StatusSelectBoxWithoutLabel = (props:any) => {
    const { value, manageSelect, manageOnBlur } = props;
    const [inputValue, setInputValue] = React.useState((value != undefined && value != null) ? (rabgStatus[value].statusName || '') : '');
    const [optionList, setOptionList] = useState(rabgStatus || []);
    const [t] = useTranslation();

    const manageHandleChange = (_event: any, newValue: any) => {
      let tempObj:any = Object;
      
      if (newValue == null || newValue == undefined) {
        tempObj = {status: null};
      } else {
        const index = rabgStatus.findIndex((item:any) => item.statusName == newValue.statusName);
        tempObj = {status: index};
      }
      manageSelect(tempObj);
    };
  
    const manageHandleInputChange = (_event: any, newInputValue: string) => {
      (newInputValue != undefined || newInputValue != 'undefined') &&
      setInputValue(newInputValue);
      // setOptionList(rabgStatus);
    };

    useEffect(() => {
      if(inputValue == null || inputValue == '') {
        setInputValue(rabgStatus[value]?.statusName);
      }
    }, [value]);

    return (
      <Box sx={{ width: '120px', ml: '5px' }} onBlur={manageOnBlur}>
        <StatusSelectBoxDefault
        options={optionList}
        keyLabel={'statusName'}
        handleChange={manageHandleChange}
        variant={'standard'}
        selectedValue={value}
        handleInputChange={manageHandleInputChange}
        inputValue={t(inputValue)}
        />
      </Box>
    )
}

export default StatusSelectBoxWithoutLabel;
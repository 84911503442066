import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import './styles.css'
//internal and external components
import Divider from '@mui/material/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faThumbtack } from '@fortawesome/free-solid-svg-icons'
import StatusIndicatorSmall from '../StatusIndicatorSmall';
import { stringify } from 'querystring';
import { colors } from '../../common/pallette/colors';
import { useNavigate } from 'react-router-dom';
import { setCurrentClub } from '../../redux/reducers/clubDetails';
import { useTranslation } from 'react-i18next';

const ClubCards = (props: any) => {
    const { data, manageIsPinnedClick } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [t] = useTranslation();

    const manageClubClick = () => {
        const d: any = {id: data.id, name: data.name, color: data.color, logo: data?.imageUrl, currentEvaluationPhase: data.currentEvaluationPhase};
        dispatch(setCurrentClub(d))
        navigate('/selfassessment')
    }
    // https://jamp.football/Content/clubLogos/c966b268-ef99-42fb-8b1e-cff414905570_ac%20nag%20parc.png
    return(
        <React.Fragment>
            <div className="myClubCard" onClick={manageClubClick}>
                <div className='myClubCardTopSection'>
                    <img src={`https://jamp.football${data?.imageUrl}`} alt="clubICon" />
                    <div className="cardDetail">
                        {/* <span className='clubCardClubName'>{data.clubName.length < 23 ? data.clubName : data.clubName.slice(0, 20) + '...'}</span> */}
                        <span className='clubCardClubName'>{data.name}</span>
                        <div className="targetBox">
                            <span>Target</span>
                            <div className='starContainer'>
                                {
                                    Array.from({length: data.targetRating}, () => <FontAwesomeIcon className='icon' icon={faStar} style={{color: colors.yellow.bright}} />)
                                }
                            </div>
                        </div>
                    </div>
            </div>
            <Divider sx={{ mb: '5px' }}/>
            <div className="selfAssessmenBox">
                <span>{t(data.currentEvaluationPhase)}</span>
                {t(data.currentEvaluationPhase) == t('SELF_ASSESSMENT') ? <span>{data.currentEvaluationProgress}% </span> :
                 <StatusIndicatorSmall reportType={'siteReview'} status={data.currentEvaluationStatus} minW={0} label={'generate'}/>}
            </div>
            {
                data.licence < 100 ?
                <div className="selfAssessmenBox">
                    <span>{t('LICENCE')}</span>
                    <span>{data.licence}% </span>
                </div>:
                <div className="selfAssessmenBox">
                    <span>{t('LICENCE_EVALUATION')}</span>
                    {
                        data.licenceEvaluation == 100 ?
                        <StatusIndicatorSmall reportType={'liecenceHome'} status={data.licenceRAGB} minW={0} label={'generate'}/>:
                        <span>{data.licenceEvaluation}% </span>
                    }
                </div>
            }
            <FontAwesomeIcon className='icon cursorPointer pinStyle' icon={faThumbtack} onClick={(e:any) => {e.stopPropagation(); manageIsPinnedClick(data);}} style={{color: data.isPinned ? colors.grey.dark : colors.grey.light,}} />
            </div>
        </React.Fragment>
    )
}

export default ClubCards;
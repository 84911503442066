import * as React from 'react';
import './styles.css';
//internal and external components/containers
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ThemeProvider, Typography, createTheme } from "@mui/material";
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import PhaseHeader from '../PhaseHeader';
import { setCurrentTab } from '../../redux/reducers/goalsAndStrategy';
import { AcademyGoals } from '../AcademyGoals';
import GoalDetails from '../GoalDetails';

const GoalsAndStrategyTabs = (props: any) => {
    const tabLabels:any = useSelector((state:any) => state.goalsAndStrategy.tabs);
    const selectedTab:any = useSelector((state:any) => state.goalsAndStrategy.currentTab);
    const selectedGoal = useSelector((state:any) => state.goalsAndStrategy.selectedGoal);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [t] = useTranslation();
    const location = useLocation();

    const handleChange = (event: React.SyntheticEvent, newValue: any) => {
      switch(newValue) {
        case t('ACADEMY_GOALS'):
          navigate('/goals');
          break;
        case t('DEPARTMENT_GOALS'):
          // navigate('/performanceplan');
          break;
        case t('MY_DEVELOPMENT'):
          // navigate('/performanceplan');
          break;
        case t('STRATEGY_ANALYSIS'):
          // navigate('/performanceplan');
          break;
        case t('CONFIG'):
          // navigate('/performanceplan');
          break;
      } 
      dispatch(setCurrentTab(newValue));
    };
    
    const theme = createTheme({
      components: {

      }
    });

    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ width: '100%' }}>
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange}
              sx={{
                '.MuiTabs-indicator': {
                  width: '0px !important'
                },
              }}>
                {
                    tabLabels.map((tabName: string, index: number) => {
                        return <Tab
                        sx={t(tabName) == selectedTab ?
                         { borderBottom: '2px solid #1976d2' }:
                         { borderBottom: '2px solid rgba(255, 255, 255, 0)' }
                        }
                        label={t(tabName)} value={t(tabName)} />;
                    })
                }
              </TabList>
            </Box>
            <PhaseHeader module={t('ACADEMY_GOALS')} />
            <TabPanel value={t('ACADEMY_GOALS')} sx={{ p: 0, height: '90vh' }}>
              {
                selectedGoal && selectedGoal != undefined && selectedGoal != null && location.pathname.includes('/goals/') ?
                <GoalDetails /> :
                <AcademyGoals />
              }
            </TabPanel>
            <TabPanel value={t('DEPARTMENT_GOALS')} sx={{ p: 0, height: '90vh' }}>
            </TabPanel>
            <TabPanel value={t('MY_DEVELOPMENT')} sx={{ p: 0, height: '90vh' }}>
            </TabPanel>
            <TabPanel value={t('STRATEGY_ANALYSIS')} sx={{ p: 0, height: '90vh' }}>
            </TabPanel>
            <TabPanel value={t('CONFIG')} sx={{ p: 0, height: '90vh' }}>
            </TabPanel>
          </TabContext>
        </Box>
      </ThemeProvider>
    );
}

export default GoalsAndStrategyTabs;
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./styles.css";
//internal and external component imports
import ClubCards from "../../components/ClubCards";
// import { clubDetails } from "../../assets/staticData";
import MyClubsTable from "../../components/MyClubsTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faTable,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  Alert,
  AlertTitle,
  Box,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { colors } from "../../common/pallette/colors";
import { getClubs, getLeagues, managePinnedClubs } from "../../api/common";
import ClubCardsSkeleton from "../../components/ClubCardsSkeleton";
import {
  setDisplayMyClubs,
  setPinnedClubs,
  setMyClubs,
  setLeagues,
} from "../../redux/reducers/common";
import { filterTheData } from "../../common/logics";
import MessageModalPopup from "../../components/MessageModalPopup/index.tsx";

const MyClubsContainer = () => {
  const [view, setView] = useState("tableView");
  // const [clubDetails, setClubDetails]: any = useState(useSelector((state: any) => state.evaluation));
  const searchString = useSelector(
    (state: any) => state.common.adminPageSearchString
  );
  const myClubs = useSelector((state: any) => state.common.myClubs);
  const displayMyClubs = useSelector(
    (state: any) => state.common.displayMyClubs
  );
  const pinnedClubs = useSelector((state: any) => state.common.pinnedClubs);
  const globalFilters = useSelector((state: any) => state.common.globalFilters);
  const globalFiltersCount = useSelector(
    (state: any) => state.common.globalFiltersCount
  );
  const clubNameFilter = useSelector(
    (state: any) => state.common.clubNameFilter
  );
  const leagueFilter = useSelector((state: any) => state.common.leagueFilter);
  const starsFilter = useSelector(
    (state: any) => state.common.starRatingFilter
  );
  const targetStarsFilter = useSelector(
    (state: any) => state.common.targetStarRatingFilter
  );
  const [isfetchingClubs, setIsFetchingClubs] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let filteredClubs = myClubs ? myClubs : [];

    if (searchString !== "") {
      filteredClubs = filteredClubs.filter((club: any) =>
        club.name.toLowerCase().includes(searchString.toLowerCase())
      );
    }

    // const count = globalFilters.some((d:any) => d.value != '' || (d.operator == 'is empty' || d.operator == 'is not empty'));
    if (clubNameFilter && clubNameFilter.length > 0) {
      // filteredClubs = filterTheData(filteredClubs, clubNameFilter);
      filteredClubs = filteredClubs.filter((d: any) =>
        clubNameFilter.some((obj: any) => d.id == obj.id)
      );
    }

    if (leagueFilter && leagueFilter.length > 0) {
      filteredClubs = filteredClubs.filter((d: any) =>
        leagueFilter.some((obj: any) => d.leagueId == obj.id)
      );
    }

    if (starsFilter && starsFilter.length > 0) {
      filteredClubs = filteredClubs.filter((d: any) =>
        starsFilter.some((obj: any) => d.currentRating == obj.id)
      );
    }

    if (targetStarsFilter && targetStarsFilter.length > 0) {
      filteredClubs = filteredClubs.filter((d: any) =>
        targetStarsFilter.some((obj: any) => d.targetRating == obj.id)
      );
    }

    dispatch(setDisplayMyClubs(filteredClubs));
    const tempArr = filteredClubs.filter((d: any) => d.isPinned);
    dispatch(setPinnedClubs(tempArr));
  }, [
    searchString,
    myClubs,
    clubNameFilter,
    leagueFilter,
    starsFilter,
    targetStarsFilter,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetchingClubs(true);
        const response = await getClubs();
        const leagues = await getLeagues();
        dispatch(setLeagues(leagues));
        // dispatch(getMyClubs(response));
        // setClubDetails(response);
        // if (response !== undefined && response.length) {
        //     favouriteClubs = response.filter((d: any) => d.isFavourite);
        // }
        return response;
      } catch {
        console.error("error in fetching club data");
      }
    };
    myClubs.length == 0 &&
      fetchData().then((res) => {
        dispatch(setMyClubs(res));
        dispatch(setDisplayMyClubs(res));
        const tempArr = res?.filter((d: any) => d.isPinned);
        dispatch(setPinnedClubs(tempArr));
        setIsFetchingClubs(false);
      });
  }, []);

  const pinClub = async (clubId: any) => {
    const response = await managePinnedClubs({ clubId: clubId });
    return response;
  };

  const manageIsPinnedClick = (data: any) => {
    if (pinnedClubs.length < 10 || data.isPinned) {
      pinClub(data.id)
        .then(() => {
          let tempData = { ...data, isPinned: !data.isPinned };
          let index = myClubs.findIndex((x: any) => x.id == data.id);
          let updatedClubDetails: any = [...myClubs];
          updatedClubDetails.splice(index, 1, tempData);
          dispatch(setMyClubs(updatedClubDetails));
        })
        .catch((err) => console.error(err));
    } else if (pinnedClubs.length == 10) {
      setOpenPopup(true);
    }
  };

  return (
    <React.Fragment>
      <Box className="myClubsDetails" sx={{ borderColor: colors.grey.bright }}>
        {openPopup && (
          <MessageModalPopup
            open={openPopup}
            message={"Reached the maximum limit of pinned clubs."}
            closeModal={() => {
              setOpenPopup(false);
            }}
          />
        )}
        <Typography className="collapseHeading">MY CLUBS</Typography>
        <Box className="myClubsContentWrapper">
          <Box className="myClubCardsContainer">
            {!isfetchingClubs && pinnedClubs && pinnedClubs.length ? (
              pinnedClubs.map((club: any) => {
                return (
                  <ClubCards
                    data={club}
                    manageIsPinnedClick={manageIsPinnedClick}
                  />
                );
              })
            ) : (pinnedClubs == undefined || pinnedClubs.length == 0) &&
              !isfetchingClubs ? (
              <Typography sx={{ textAlign: "left" }}>
                No Pinned Clubs
              </Typography>
            ) : (
              Array.from({ length: 10 }, () => {
                return <ClubCardsSkeleton />;
              })
            )}
          </Box>
          <Box className="clubViewIconsWrapper">
            <Tooltip title="Cards View" arrow placement="top">
              <FontAwesomeIcon
                className={`clubViewIcons fa-xl ${
                  view === "cardView"
                    ? "selectedIconColor cursorPointer"
                    : "icon cursorPointer"
                }`}
                icon={faAddressCard}
                onClick={() => setView("cardView")}
              />
            </Tooltip>
            <Tooltip title="Table View" arrow placement="top">
              <FontAwesomeIcon
                className={`clubViewIcons fa-xl ${
                  view === "tableView"
                    ? "selectedIconColor cursorPointer"
                    : "icon cursorPointer"
                }`}
                icon={faTable}
                onClick={() => setView("tableView")}
              />
            </Tooltip>
          </Box>
          {view === "tableView" ? (
            <MyClubsTable
              favourites={pinnedClubs ? pinnedClubs.length : 0}
              isLoading={isfetchingClubs}
              manageIsPinnedClick={manageIsPinnedClick}
            />
          ) : (
            <>
              <Divider />
              {!isfetchingClubs && displayMyClubs.length > 0 ? (
                <Box
                  className="myClubCardsContainer globalClubCardsWrapper"
                  sx={
                    !pinnedClubs || pinnedClubs.length < 10
                      ? { maxHeight: "65%" }
                      : {}
                  }
                >
                  {displayMyClubs.map((club: any) => {
                    return (
                      <ClubCards
                        data={club}
                        manageIsPinnedClick={manageIsPinnedClick}
                      />
                    );
                  })}
                </Box>
              ) : (
                <Typography>No Records Found</Typography>
              )}
            </>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default MyClubsContainer;

import React from "react";
import "./styles.css";
//external or internal compoents
import SearchBox from "../../components/SearchBox";
import FilterBox from "../../components/FilterBox";
import FilterContainer from "../../components/FilterContainer";

const SearchFilterContainer = (props: any) => {
  const { parent, othersOptions } = props;
  return (
    <React.Fragment>
      <div
        className={
          (parent == "Performance Plan' || parent == 'Goals & Strategy"
           ) ? "visiblityHidden"
            : "SearchFilterWrapper"
        }
      >
        <SearchBox
          searchBoxStyle={"searchBoxMain"}
          parent={parent ? parent : ""}
        />
        {parent == "adminHome" && <FilterBox />}
        {parent == "evaluationDashboard" && (
          <FilterContainer othersOptions={othersOptions} />
        )}
      </div>
    </React.Fragment>
  );
};

export default SearchFilterContainer;

import * as React from 'react';
import { useState } from 'react';
import { TextField, InputAdornment, ThemeProvider, createTheme, Popper, InputLabel, Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import styled from 'styled-components';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../../common/pallette/colors';
import { useTranslation } from 'react-i18next';
import { AvatarAndName } from '../../AvatarAndName';

const CustomPopper = styled(Popper)({
  '& .MuiAutocomplete-listbox': {
    fontSize: '14px',
  },
});

export default function AvatarSelectBoxDefault(props: any) {
  const {
    placeholder,
    selectedValue,
    options,
    variant,
    keyLabel,
    label,
    textColor,
    handleChange,
    handleInputChange,
    inputValue,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [t] = useTranslation();

  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            padding: '0px 14px !important',
          },
        },
      },
      MuiStack: {
        styleOverrides: {
          root: {
            width: '100% !important',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            fontSize: '12px',
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '&::after': {
              borderBottom: '0px',
            },
            '&::before': {
              borderBottom: '0px',
            },
            '&:hover:not(.Mui-disabled, .Mui-error)::before': {
              borderBottom: 'none',
            },
          },
          input: {
            color: textColor,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            padding: 0,
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Stack spacing={1} sx={{ '& > :not(style) ~ :not(style)': { marginTop: '0px' } }}>
          {label && <InputLabel className="fs12" sx={{ textAlign: 'left' }}>{label}</InputLabel>}
          <Autocomplete
            freeSolo
            fullWidth
            className="fs12"
            value={selectedValue}
            inputValue={inputValue ? inputValue : ''}
            onChange={handleChange}
            onInputChange={handleInputChange}
            options={options}
            getOptionLabel={(option) => {
              if (typeof option === 'object' && option !== null) {
                return keyLabel && option[keyLabel] ? t(option[keyLabel]) : '';
              } else {
                return inputValue;
              }
            }}sx={{
              border: 0,
              "&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot, &.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
                paddingRight: "0px", 
              },
            }}
            renderOption={(params, option) => {
              return(
                <li {...params}>
                  <Box className={'mtbAuto'}>
                    <AvatarAndName avatarName={option?.displayName} avatarImage={option?.avatarPath} avatarSize={18}/>
                  </Box>
                </li>
              )
            }}
            PopperComponent={CustomPopper}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ border: 0 }}
                placeholder={placeholder || 'Search'}
                className="fs12"
                variant={variant || 'outlined'}
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                  startAdornment: ( selectedValue ?
                    <AvatarAndName avatarImage={selectedValue?.avatarPath} avatarSize={18}/> : null
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {isOpen ? <FontAwesomeIcon color={colors.black.dark} width={'12px'} icon={faChevronUp} /> : <FontAwesomeIcon color={colors.black.dark} width={'12px'} icon={faChevronDown} />}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Stack>
      </Box>
    </ThemeProvider>
  );
}

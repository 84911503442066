import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { EnhancedTableHead } from "../../../components/EnhancedTableHead";
import TableSkeleton from "../../../components/TableSkeleton";
import { useSelector } from "react-redux";
import { filteredList } from "../Utils";
import { sortData } from "../../../common/SortSupport";
import { TableCell, TableRow } from "@mui/material";
import ClubDetails from "./ClubDetails";

export default function ClubsTable() {
  const [t] = useTranslation();
  const [order, setOrder] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");
  const [clubDetails, setClubDetails] = React.useState([]);
  const clubsAssociatedPeople: any = useSelector(
    (state: any) => state.evaluationDashboard.clubsAssociatedPeople
  );
  const searchString: any = useSelector(
    (state: any) => state.evaluationDashboard.searchString
  );
  const targetStarRatingFilter: any = useSelector(
    (state: any) => state.evaluationDashboard.targetStarRatingFilter
  );
  const leagueFilter: any = useSelector(
    (state: any) => state.evaluationDashboard.leagueFilter
  );
  const otherFilter: any = useSelector(
    (state: any) => state.evaluationDashboard.otherFilter
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const Head = [
    {
      key: "firstName",
      displayName: t("PERSON").toUpperCase(),
    },
    {
      key: "",
      displayName: t("ROLE").toUpperCase(),
    },
    {
      key: "",
      displayName: t("Clubs").toUpperCase(),
    },
  ];

  // Memoize the filtered club dates based on searchString and clubsAssociatedPeople
  const filteredClubAssPeople = useMemo(() => {
    console.log("Filtering club associated people based on searchString...");
    return clubsAssociatedPeople?.filter((row: any) => {
      const searchTerm = searchString.toLowerCase();
      return (
        // Check if firstName includes the search term
        row.firstName?.toLowerCase().includes(searchTerm) ||
        // Check if lastName includes the search term
        row.lastName?.toLowerCase().includes(searchTerm) ||
        // Check if any clubRole's name includes the search term
        row.clubRoles?.some((role: any) =>
          role.role?.name.toString().toLowerCase().includes(searchTerm)
        ) ||
        // Check if any clubDetail's name within each clubRole includes the search term
        row.clubRoles?.some((role: any) =>
          role.clubDetail?.some((club: any) =>
            club.name.toString().toLowerCase().includes(searchTerm)
          )
        )
      );
    });
  }, [clubsAssociatedPeople, searchString]); // Recompute only when clubsAssociatedPeople or searchString changes

  // Memoize the final filtered and sorted data based on other filters and sorting order
  const filteredAndSortedData = useMemo(() => {
    console.log("Applying additional filters...");
    if (filteredClubAssPeople) {
      let filteredData = filteredClubAssPeople;

      // Apply all filters
      if (
        otherFilter.length > 0 ||
        leagueFilter.length > 0 ||
        targetStarRatingFilter.length > 0
      ) {
        filteredData = filteredList(
          otherFilter,
          leagueFilter,
          targetStarRatingFilter,
          filteredData,
          "CLUBSPEOPLE"
        );
      }

      return filteredData;
    }

    return [];
  }, [
    filteredClubAssPeople,
    otherFilter,
    leagueFilter,
    targetStarRatingFilter,
  ]);

  // Memoizing visible rows (sorted data)
  const visibleRows = useMemo(() => {
    console.log("Sorting the filtered data...");
    return filteredAndSortedData.length > 0
      ? sortData(filteredAndSortedData, orderBy, order)
      : [];
  }, [filteredAndSortedData, order, orderBy]);

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", gap: 4 }}>
        <TableContainer sx={{ maxHeight: "100vh", overflowY: "auto", flexGrow: 1 }}>
          <Table
            stickyHeader
            sx={{ boxShadow: "none" }}
            size="small"
            aria-label="a dense table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              tableHeads={Head}
            />
            <TableBody>
              {visibleRows?.length > 0 ? (
                visibleRows.map((row: any, index: number) => {
                  // Check if the current row is the first occurrence of the person
                  const isFirstRowForPerson =
                    index === 0 ||
                    visibleRows[index - 1].firstName !== row.firstName ||
                    visibleRows[index - 1].lastName !== row.lastName;

                  return (
                    <React.Fragment key={index}>
                      {/* First Row with Person Name, Role, and Club */}
                      {isFirstRowForPerson && (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            "&:hover": {
                              backgroundColor: "#f5f5f5", // Light gray background on hover
                              cursor: "pointer", // Change cursor to pointer to indicate clickability
                            },
                          }}
                        >
                          <TableCell rowSpan={row.clubRoles?.length || 1}>
                            {/* Display person's name in the first row only */}
                            {row.firstName} {row.lastName}
                          </TableCell>

                          {/* Display Role and Club for the first row */}
                          {row.clubRoles?.map(
                            (role: any, roleIndex: number) => (
                              <React.Fragment key={roleIndex}>
                                {/* Role Name */}
                                <TableCell
                                  align="left"
                                  style={{
                                    fontSize: 12,
                                    fontFamily: "Montserrat",
                                  }}
                                >
                                  {role.role.name}
                                </TableCell>

                                {/* Club Name(s) */}
                                <TableCell
                                  align="left"
                                  style={{
                                    fontSize: 12,
                                    fontFamily: "Montserrat",
                                  }}
                                  onClick={() =>
                                    setClubDetails(role.clubDetail)
                                  }
                                >
                                  {t("Club") + " " + role.clubDetail.length}
                                </TableCell>
                              </React.Fragment>
                            )
                          )}
                        </TableRow>
                      )}

                      {/* Subsequent rows with the same Person's Name */}
                      {row.clubRoles?.map(
                        (role: any, roleIndex: number) =>
                          roleIndex > 0 && (
                            <TableRow key={roleIndex}>
                              {/* Empty Person's Name cell */}
                              <TableCell></TableCell>

                              {/* Role Name */}
                              <TableCell
                                align="left"
                                style={{
                                  fontSize: 12,
                                  fontFamily: "Montserrat",
                                }}
                              >
                                {role.role.name}
                              </TableCell>

                              {/* Club Name(s) */}
                              <TableCell
                                align="left"
                                style={{
                                  fontSize: 12,
                                  fontFamily: "Montserrat",
                                }}
                                onClick={() => setClubDetails(role.clubDetail)}
                              >
                                {t("Club") + " " + role.clubDetail.length}
                              </TableCell>
                            </TableRow>
                          )
                      )}
                    </React.Fragment>
                  );
                })
              ) : clubsAssociatedPeople.length > 0 ? (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      fontSize: 12,
                      fontFamily: "Montserrat",
                    }}
                    colSpan={8}
                  >
                    {t("NO_RECORDS_FOUND")}...
                  </TableCell>
                </TableRow>
              ) : (
                <TableSkeleton rowCount={10} cellCount={7} />
              )}
              <TableRow></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {clubDetails?.length > 0 && (
          <Box
            sx={{
              minWidth: "60vh", // Set a fixed width for the TimelineDetails section
              maxWidth: "80vh", // Set a fixed width for the TimelineDetails section
              flexShrink: 0, // Prevent the TimelineDetails from shrinking
              overflow: "auto", // If needed, add scrolling if the content overflows
            }}
          >
            <ClubDetails
              clubDetails={clubDetails}
              order={order}
              orderBy={orderBy}
              handleRequestSort={handleRequestSort}
            />
          </Box>
        )}
      </Box>
    </>
  );
}

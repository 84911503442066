import { Box, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../common/pallette/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";

export const NoGoalsCard = () => {
    const [t] = useTranslation();

    return (
        <Box
        sx={{  
            width: '100%',
            borderRadius: '10px',
            border: `2px solid ${colors.black.dark}`,
            display: "flex",
            padding: '10px',
        }}>
            <FontAwesomeIcon className="mtbAuto fs24 mr10" icon={faLightbulb} />
            <Typography className="mtbAuto fs12">{t('NO_GOALS_LABEL')}</Typography>
        </Box>
    )
}
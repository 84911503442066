import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import produce from 'immer';
//internal and external components import
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faComments, faFileInvoice, faPersonRunning, faHeart, faThumbtack } from '@fortawesome/free-solid-svg-icons';
import { ReactNode } from 'react';
import CommentsDocsActionsIconWrapper from '../CommentsDocsActionsIconWrapper';
import { stableSort, getComparator, sortData } from '../../common/SortSupport';
import { EnhancedTableHead } from '../EnhancedTableHead';
import { colors } from '../../common/pallette/colors';
import { useNavigate } from 'react-router-dom';
import { setCurrentClub } from '../../redux/reducers/clubDetails';
import TableSkeleton from '../TableSkeleton';
import { setMyClubs } from '../../redux/reducers/common';
import { managePinnedClubs } from '../../api/common';
import { useTranslation } from 'react-i18next';

export default function MyClubsTable(props: any) {
  const { favourites, isLoading, manageIsPinnedClick } = props;
  const myClubs = useSelector((state: any) => state.common.myClubs);
  const displayMyClubs = useSelector((state: any) => state.common.displayMyClubs);
  const leagues = useSelector((state: any) => state.common.leagues);
  // const [clubDetails, setClubDetails] = React.useState(stateClubDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  //supporting contents
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows: any = React.useMemo(
    () =>
      displayMyClubs && displayMyClubs?.length > 0 &&
      sortData(displayMyClubs, orderBy, order),
    [order, orderBy, displayMyClubs],
  );

  const head = [
    {
      key: 'name', displayName: 'club',
    },
    {
      key: 'academyDirector', displayName: t('DC_1_ACADEMY_DIRECTOR'),
    },
    {
      key: 'leagueId', displayName: t('LEAGUE'),
    },
    {
      key: 'currentRating', displayName: t('CURRENT_STAR'),
    },
    {
      key: 'targetRating', displayName: t('TARGET_STAR'),
    },
    // {
    //   key: '', displayName: '',
    // },
    {
      key: 'isFavourite', displayName: '',
    },
  ]

  const manageClubClick = (data: any) => {
    const d: any = { id: data.id, name: data.name, color: data.color, logo: data.imageUrl, currentEvaluationPhase: data.currentEvaluationPhase };
    dispatch(setCurrentClub(d));
    navigate('/selfassessment');
    // navigate('/clublicence');
  }

  const findLeague = (league: any) => {
    const result = leagues.find((x: any) => { return x.id == league });
    return result.name;
  }

  return (
    <TableContainer sx={{ height: favourites < 8 ? '70%' : '48%' }}>
      <Table stickyHeader sx={{ boxShadow: 'none' }} size="small" aria-label="a dense table">
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          tableHeads={head}
        />
        <TableBody>
          {
            !isLoading && visibleRows?.length > 0 ?
              visibleRows.map((row: any, index: any) => (
                <TableRow
                  key={`${row.name}_${index}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  className='hoverBG'
                >
                  <TableCell width={300} component="th" scope="row" className='cursorPointer' onClick={() => { manageClubClick(row) }}>
                    {row.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.academyDirector}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {findLeague(row.leagueId)}
                  </TableCell>
                  <TableCell align="left">
                    {
                      Array.from({ length: 4 }, (_, i: any) => <FontAwesomeIcon icon={faStar} style={i < row.currentRating ? { color: colors.yellow.bright, } : { color: colors.grey.light, }} />)
                    }
                  </TableCell>
                  <TableCell align="left">
                    {
                      Array.from({ length: 4 }, (_, i: any) => <FontAwesomeIcon icon={faStar} style={i < row.targetRating ? { color: colors.yellow.bright, } : { color: colors.grey.light, }} />)
                    }
                  </TableCell>
                  <TableCell align='left'>
                    <FontAwesomeIcon className='icon cursorPointer' icon={faThumbtack} onClick={() => manageIsPinnedClick(row)} style={{ color: row.isPinned ? colors.grey.dark : colors.grey.light, }} />
                  </TableCell>
                </TableRow>
              )) :
              (
                isLoading ?
                  <TableSkeleton rowCount={8} cellCount={6} /> :
                  <TableCell colSpan={6} align='center'>No Records Found</TableCell>
              )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
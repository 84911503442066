import React, { useEffect, useState } from "react";
import "./styles.css";
//external or internal components
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faPlus,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { colors } from "../../common/pallette/colors";
import SelectBoxPrimary from "../SelectBoxPrimary";
import {
  Box,
  Button,
  ClickAwayListener,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewGlobalFilters,
  setGlobalFilters,
  setGlobalFiltersCount,
  setInitialGlobalFilters,
  setIsAdmin,
} from "../../redux/reducers/common";
import CustomTextField from "../CustomTextField";
import SelectBox from "../SelectBox";
import FiltersWrapper from "./filters/filtersWrapper";

const FilterBox = () => {
  const [isOpen, setIsOpen] = useState(false);
  const globalFilters = useSelector((state: any) => state.common.globalFilters);
  const globalFiltersCount = useSelector(
    (state: any) => state.common.globalFiltersCount
  );
  const dispatch = useDispatch();
  const filterByOptions = [
    "Club name",
    "League",
    "Star rating",
    "Target star rating",
    "Project status",
  ];
  const operatorOptions = [
    "contains",
    "equals",
    "starts with",
    "ends with",
    "is empty",
    "is not empty",
  ];
  const selectedClubs = useSelector(
    (state: any) => state.common.clubNameFilter
  );
  const selectedLeagues = useSelector(
    (state: any) => state.common.leagueFilter
  );
  const selectedStars = useSelector(
    (state: any) => state.common.starRatingFilter
  );
  const selectedTargetStars = useSelector(
    (state: any) => state.common.targetStarRatingFilter
  );

  const manageDeleteFilter = (deleteIndex: number) => {
    const tempArr = globalFilters.filter(
      (d: any, i: number) => i !== deleteIndex
    );
    dispatch(setGlobalFilters(tempArr));
  };

  const handleFilterChange = (
    condition: any,
    filterBy: any,
    operator: any,
    value: any,
    index: any
  ) => {
    const tempArr = globalFilters.map((d: any, i: number) => {
      if (i === index) {
        return {
          condition: condition,
          filterBy: filterBy,
          operator: operator,
          value: value,
        };
      } else {
        return d;
      }
    });
    dispatch(setGlobalFilters(tempArr));
  };

  useEffect(() => {
    let count: any = 0;
    globalFilters.map((d: any) => {
      if (
        d.value != "" ||
        d.operator == "is empty" ||
        d.operator == "is not empty"
      ) {
        count++;
      }
    });
    // setFilterCount(count);
    dispatch(setGlobalFiltersCount(count));
  }, [globalFilters]);

  useEffect(() => {
    let count: any = 0;
    selectedClubs.length > 0 && count++;
    selectedLeagues.length > 0 && count++;
    selectedStars.length > 0 && count++;
    selectedTargetStars.length > 0 && count++;
    dispatch(setGlobalFiltersCount(count));
  }, [selectedClubs, selectedLeagues, selectedStars, selectedTargetStars]);

  return (
    <React.Fragment>
      <div className="filterBox" style={{ backgroundColor: colors.grey.light }}>
        <div
          className="filterButton pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <FontAwesomeIcon className="filterIcon icon" icon={faFilter} />
          <span style={{ color: colors.grey.dark }}>Filters</span>
        </div>
        {globalFiltersCount > 0 && (
          <Box
            className={"filterCount"}
            sx={{ backgroundColor: colors.blue.navy }}
          >
            {globalFiltersCount}
          </Box>
        )}
        {isOpen && (
          <ClickAwayListener
            onClickAway={() => {
              setIsOpen(false);
            }}
          >
            <Box className="filterWrapper">
              <FiltersWrapper />
            </Box>
          </ClickAwayListener>
        )}
        {/* complex filter box not in use */}
        {false && (
          <div className="filterWrapper">
            <Box className={"closeIconWrapper"}>
              <Tooltip title="Close" arrow placement="right">
                <FontAwesomeIcon
                  onClick={() => setIsOpen(false)}
                  className={"icon mr10"}
                  icon={faXmark}
                />
              </Tooltip>
            </Box>
            <Box className={"filtersContainer"}>
              {globalFilters.map((d: any, index: number) => {
                {
                  return (
                    <Box className="filterConditionWrapper">
                      {/* {
                                                    globalFilters?.length > 1 &&
                                                    <Box className={'filterConditionContainer'}>
                                                        {
                                                            index !== 0 ?
                                                            <SelectBox label={''} options={['and', 'or']} border='solid' selected={d?.condition} manageChange={(condition:any) => {handleFilterChange(condition, d.filterBy, d.operator, d.value, index)}} />
                                                            : <Box sx={{ width: '90px' }} />
                                                        }
                                                    </Box>
                                                } */}
                      <Box className={"filterSelector"}>
                        <SelectBox
                          label={"Filter By"}
                          options={filterByOptions}
                          border="solid"
                          width="160px"
                          selected={d?.filterBy}
                          manageChange={(filterBy: any) => {
                            handleFilterChange(
                              d.condition,
                              filterBy,
                              d.operator,
                              d.value,
                              index
                            );
                          }}
                        />
                      </Box>
                      <Box className={"filterSelector"}>
                        <SelectBox
                          label={"Operator"}
                          options={operatorOptions}
                          border="solid"
                          width="160px"
                          selected={d?.operator}
                          manageChange={(operator: any) => {
                            handleFilterChange(
                              d.condition,
                              d.filterBy,
                              operator,
                              d.value,
                              index
                            );
                          }}
                        />
                      </Box>
                      <Box className={"filterSelector"}>
                        <CustomTextField
                          label={"Value"}
                          value={d.value}
                          isDisabled={
                            d.operator == "is empty" ||
                            d.operator == "is not empty"
                          }
                          manageChange={(value: any) => {
                            handleFilterChange(
                              d.condition,
                              d.filterBy,
                              d.operator,
                              value,
                              index
                            );
                          }}
                        />
                      </Box>
                      {index !== 0 ? (
                        <Box className={"filterDeleteContainer"}>
                          <Tooltip
                            title="Delete Filter"
                            arrow
                            placement="right"
                          >
                            <FontAwesomeIcon
                              onClick={() => {
                                manageDeleteFilter(index);
                              }}
                              className={"icon mr10"}
                              icon={faTrash}
                            />
                          </Tooltip>
                        </Box>
                      ) : (
                        <Box sx={{ width: "28px" }} />
                      )}
                    </Box>
                  );
                }
              })}
            </Box>
            <Box className={"closeIconWrapper mt20"}>
              <Button
                variant="contained"
                className="mtbAuto"
                sx={{ ml: 1 }}
                disableElevation
                onClick={() => dispatch(addNewGlobalFilters())}
                startIcon={<FontAwesomeIcon width={"12px"} icon={faPlus} />}
              >
                Add Filter
              </Button>
              <Button
                variant="contained"
                className="mtbAuto"
                sx={{ ml: 1 }}
                disableElevation
                onClick={() => dispatch(setInitialGlobalFilters())}
                startIcon={<FontAwesomeIcon width={"12px"} icon={faTrash} />}
              >
                Remove All
              </Button>
            </Box>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default FilterBox;

import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DateSelectorDefault from "../DateSelectorDefault";
import YearRangeSelector from "../YearRangeSelector";

const YearFromTo = (props:any) => {
    const { fromDate, toDate, manageSelect, dataLabel, label, manageOnBlur } = props;
    const [t] = useTranslation();
    const handleChangeFromYear = (newValue:any) => {
      var tempObj:any = Object;
      if(newValue == null || newValue == undefined) {
        tempObj = dataLabel ? { [dataLabel]: null } : { dueDate: null };
      } else {
        tempObj = dataLabel ? { [dataLabel]: new Date(newValue).toISOString() } : { yearFrom: new Date(newValue).toISOString() };
      }
      manageSelect(tempObj);
    }
    const handleChangeToYear = (newValue:any) => {
      var tempObj:any = Object;
      if(newValue == null || newValue == undefined) {
        tempObj = dataLabel ? { [dataLabel]: null } : { dueDate: null };
      } else {
        tempObj = dataLabel ? { [dataLabel]: new Date(newValue).toISOString() } : { yearTo: new Date(newValue).toISOString() };
      }
      manageSelect(tempObj);
    }

    return (
        <Box sx={{ display: "flex", ml: "auto"}}>
          <Typography className="fs12 fw700 mtbAuto">{label ? label : t('DUE_DATE')}</Typography>
          <Box sx={{ width: '90px', ml: '5px' }} className={'mtbAuto'} onBlur={manageOnBlur}>
            <YearRangeSelector
            fromYearDate={fromDate}
            toYearDate={toDate}
            manageFromYear={handleChangeFromYear}
            manageToYear={handleChangeToYear}
            variant={'standard'}
            />
          </Box>
        </Box>
    );
};

export default YearFromTo;
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
//internal and external components import
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { Checkbox, TableRow, TextField, Tooltip } from '@mui/material/';
import { Box } from '@mui/material';
import ThreeDotMenu from '../ThreeDotMenu';
import { EnhancedTableHead } from '../EnhancedTableHead';
import TableSkeleton from '../TableSkeleton';
import { sortData } from '../../common/SortSupport';
import { faCirclePlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import CheckBox from '../CheckBox/CheckBox';
import ButtonDefault from '../ButtonDefault';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { TextFieldWithoutBorders } from '../TextFieldWithoutBorders';
import DateSelector from '../DateSelector';
import { calcTaskPercentage, formatDate } from '../../common/StaticExports';
import ProgressBar from '../ProgressBar';
import FrequencySelectBox from '../FrequencySelectBox';

const KPIsTable = (props:any) => {

  const { data, isLoading, handleChange, isDetailed, handleOnBlur, goalId } = props;
  const initialKpi = {
    "id": 0,
    "name": null,
    "frequency": null,
    "goalId": goalId,
  }
  const [isAddKpi, setIsAddKpi] = useState(false);

  const [t] = useTranslation();

  //supporting contents
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  let visibleRows = React.useMemo(
    () =>
      data && data.length > 0 &&
      sortData(data, orderBy, order),
    [order, orderBy, data],
  );

  const head = 
  isDetailed ? 
  [
    {
      key: 'name', displayName: 'kpi',
    },
    {
      key: 'frequence', displayName: 'updated',
    },
    {
      key: 'lastUpdated', displayName: 'last updated',
    },
    {
      key: 'value', displayName: 'kpi value',
    },
  ]:
  [
    {
      key: 'name', displayName: 'kpi',
    },
    {
      key: 'frequence', displayName: 'updated',
    },
  ]

  //table contents
    return(
        <React.Fragment>
            <Box sx={{ position: 'relative' }}>
              <TableContainer 
              sx={{ 
                "& .MuiTable-root": {
                  border: 0,
                },
                "& .MuiTableCell-root": {
                  borderBottom: "none", // Remove bottom borders from table cells
                },
                "& .MuiTableRow-root": {
                  border: "none", // Remove row borders
                }
              }}>
                <Table stickyHeader size="small" aria-label="a dense table" onBlur={handleOnBlur}>
                    <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    tableHeads={head}
                    />
                    {
                      !isLoading && visibleRows.length ?
                      <TableBody>
                        {
                          visibleRows.map((d: any, i: number) => (
                            <>
                              <TableRow
                              className='hoverBG'
                              sx={{ cursor: 'pointer' }}
                              key={`tasks-table-row-${i}`}
                              >
                                <TableCell className='fs12' width={'900px'}>
                                  {/* {d?.task ? d?.task : ''} */}
                                  <TextFieldWithoutBorders 
                                  value={d.name}
                                  handleChange={(e:any) => {handleChange('name', e.target.value, i)}}
                                  />
                                </TableCell>
                                <TableCell sx={{ width: '130px' }}>
                                  {/* {d?.updated ? d?.updated : ''} */}
                                    <FrequencySelectBox 
                                    value={d.frequency}
                                    manageSelect={(e:any) => {handleChange('frequency', e.frequency, i)}}
                                    />
                                </TableCell>
                                {
                                  isDetailed &&
                                  <TableCell className='fs12' width={'900px'}>
                                    {d?.lastUpdated ? d?.lastUpdated : ''}
                                  </TableCell>
                                }
                                {
                                  isDetailed &&
                                  <TableCell className='fs12' width={'900px'}>
                                    {d?.value ? d?.value : ''}
                                  </TableCell>
                                }
                              </TableRow>
                            </>
                          ))
                        }
                      </TableBody>
                      :
                      (
                        !isLoading && !visibleRows ?
                        <TableBody>
                          <TableRow>{'No Records Found'}</TableRow>
                        </TableBody> :
                        <TableBody>
                          <TableSkeleton rowCount={4} cellCount={2} />
                        </TableBody>
                      )
                    }
                </Table>
              </TableContainer>
              <Box sx={{ mt: 2}}>
                  <ButtonDefault
                  variant={'outlined'}
                  value={t('ADD_KPI')}
                  manageOnClick={() => {handleChange('addKpi', initialKpi, null)}}
                  icon={<FontAwesomeIcon className={'fs13'} icon={faCirclePlus} />}
                  />
              </Box>
            </Box>
        </React.Fragment>
    )
}

export default KPIsTable;
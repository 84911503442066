import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import PeoplePage from "./PeoplePage";
import MyEvaluationPage from "./MyEvaluationPage";
import MyLicencePage from "./MyLicencePage";
import DatesPage from "./DatesPage";
import ProgressPage from "./ProgressPage";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { setCurrentTab } from "../../redux/reducers/evaluationDashboard";
import { createTheme, ThemeProvider } from "@mui/material";

export default function EvaluationDashboardPage() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const tabs: any = useSelector((state: any) => state.evaluationDashboard.tabs);
  const currentTab: any = useSelector(
    (state: any) => state.evaluationDashboard.currentTab
  );

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    dispatch(setCurrentTab(newValue));
  };

  const theme = createTheme({
    components: {},
  });
  return (
    <ThemeProvider theme={theme}>
      <Box className={"contentSpacing"}>
        <TabContext value={currentTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              sx={{
                ".MuiTabs-indicator": {
                  width: "0px !important",
                },
              }}
            >
              {tabs &&
                tabs?.map((item: string, index: number) => (
                  <Tab
                    sx={
                      item === currentTab
                        ? { borderBottom: "2px solid #1976d2" }
                        : { borderBottom: "2px solid rgba(255, 255, 255, 0)" }
                    }
                    key={index}
                    label={t(item.toUpperCase())}
                    value={item}
                  />
                ))}
            </TabList>
          </Box>
          <TabPanel value={t("MY_EVALUATION")}>
            <MyEvaluationPage />
          </TabPanel>
          <TabPanel value={t("MY_LICENCE")}>
            <MyLicencePage />
          </TabPanel>
          <TabPanel value={t("PEOPLE")}>
            <PeoplePage />
          </TabPanel>
          <TabPanel value={t("DATES")}>
            <DatesPage />
          </TabPanel>
          <TabPanel value={"PROGRESS"}>
            <ProgressPage />
          </TabPanel>
        </TabContext>
      </Box>
    </ThemeProvider>
  );
}

import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import './styles.css';
import ButtonDefault from "../../components/ButtonDefault";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { NoGoalsCard } from "../../components/NoGoalsCard";
import { useDispatch, useSelector } from "react-redux";
import { GoalsWrapper } from "../GoalsWrapper";
import NewAcademyGoalPopper from "../NewAcademyGoalPopper";
import GoalDetails from "../GoalDetails";
import { createGoal, getGoals, updateGoal } from "../../api/goals";
import { setGoals } from "../../redux/reducers/goalsAndStrategy";

export const AcademyGoals = () => {
    const [t] = useTranslation();
    const goals = useSelector((state:any) => state.goalsAndStrategy.goals);
    const [isAddNewGoal, setIsAddNewGoal] = useState(false);
    const [isFetchingGoals, setIsFetchingGoals] = useState(false);
    const currentClub = useSelector((state:any) => state.clubDetails);
    const dispatch = useDispatch();

    const fetchGoals = async () => {
        setIsFetchingGoals(true);
        const response = await getGoals({ clubId: currentClub?.id });
        dispatch(setGoals(response));
        setIsFetchingGoals(false);
    }  

    useEffect(() => {
        !isFetchingGoals && goals && goals?.length === 0 &&
        fetchGoals(); 
    }, [goals, currentClub, updateGoal]);

    return(
        <Box className={'academyGoalsWrapper'}>
            <NewAcademyGoalPopper isOpen={isAddNewGoal} handleClickAway={() => {setIsAddNewGoal(false)}}/>
            <Typography className="fs18 fw800 uppercase">
                {`${t('ACADEMY')} ${t('GOALS')}`}
            </Typography>
            <Box sx={{ mt: '10px' }}>
                <ButtonDefault 
                value={t('ADD_GOAL')}
                icon={<FontAwesomeIcon width={'12px'} icon={faPlus}/>}
                manageOnClick={() => {setIsAddNewGoal(true)}}
                />
            </Box>
            <Box sx={{ mt: '10px', width: '98%', height: '100%' }}>
                {
                    !isFetchingGoals && goals && goals?.length > 0 ?
                    <GoalsWrapper data={goals} isLoading={isFetchingGoals} /> :
                    (
                        isFetchingGoals ?
                        <Typography>Loading...</Typography> :
                        <NoGoalsCard />
                    )
                }
            </Box>
        </Box>
    )
}
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SelectBoxDefault from "../SearchSelectBox/SelectBoxDefault";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { departmentList } from "../../assets/staticData";
import { getClubDepartments } from "../../api/club";

const DepartmentSelectBox = (props:any) => {
    const { value, manageSelect } = props;
    const selectedObj = departmentList.find((item:any) => item.id == value);
    const [inputValue, setInputValue] = React.useState(selectedObj ? (selectedObj?.name || '') : '');
    const [optionList, setOptionList] = useState(departmentList || []);
    const [t] = useTranslation();
    const clubDetails = useSelector((state:any) => state.clubDetails);

    const manageHandleChange = (_event: any, newValue: any) => {
      let tempObj:any = Object;
      
      if (newValue == null || newValue == undefined) {
        tempObj = {departmentName: null, departmentId: null};
      } else {
        tempObj = {departmentName: newValue.name, departmentId: newValue.id};
      }
      manageSelect(tempObj);
    };
  
    const manageHandleInputChange = (_event: any, newInputValue: string) => {
      if (newInputValue == '') {
        manageSelect({departmentName: null, departmentId: null});
      }
      (newInputValue != undefined || newInputValue != 'undefined') &&
      setInputValue(newInputValue);
      // setOptionList(departmentList);
    };

    const fetchDepartmentList = async () => {
      const response = await getClubDepartments({ clubId: clubDetails?.id });
      setOptionList(response);
      const selectedObj = response.find((item:any) => item.id == value);
      setInputValue(selectedObj ? (selectedObj?.name || '') : '');
    }
    useEffect(() => {
      clubDetails && clubDetails?.id &&
      fetchDepartmentList();
    }, [value]);

    return (
      <Box sx={{ display: "flex", ml: "auto"}}>
        <Typography className="fs12 fw700 mtbAuto">{t('DEPARTMENT')}</Typography>
        <Box sx={{ width: '120px', ml: '5px' }}className={'mtbAuto'}>
          <SelectBoxDefault
          options={optionList}
          keyLabel={'name'}
          handleChange={manageHandleChange}
          variant={'standard'}
          selectedValue={value}
          handleInputChange={manageHandleInputChange}
          inputValue={inputValue}
          />
        </Box>
      </Box>
    )
}

export default DepartmentSelectBox;
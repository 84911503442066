import { ThemeProvider } from "@emotion/react";
import { createTheme, TextField } from "@mui/material";
import React from "react";
import { colors } from "../../common/pallette/colors";

export const TextFieldWithoutBorders = (props:any) => {
    const { value, handleChange, placeholder, manageOnBlur, fontSize, fontWeight, fullWidth } = props;
    const theme = createTheme({
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.Mui-focused": {
                backgroundColor: colors.grey.bright,
              },
            },
            input: {
                padding: '6px',
                fontSize: fontSize ? fontSize : '12px',
                fontWeight: fontWeight ? fontWeight : 100,
            }
          },
        },
      },
    });
    
    return(
        <ThemeProvider theme={theme}>
            <TextField
            fullWidth={fullWidth}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            onBlur={manageOnBlur}
            />
        </ThemeProvider>
    )
}
import * as React from 'react';
import './styles.css';
//internal and external components/containers
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { setCurrentEvaluationTab, updateCurrentSection } from '../../redux/reducers/evaluation';
import ClubCriteria from '../ClubCriteria';
import { ThemeProvider, createTheme } from "@mui/material";
import { useNavigate } from 'react-router';
import Calendar from '../../components/Calendar';
import { useTranslation } from 'react-i18next';
import PhaseHeader from '../PhaseHeader';

const EvaluationTabs = (props: any) => {
    const tabLabels:any = useSelector((state:any) => state.evaluation.evaluationTabs);
    const selectedTab:any = useSelector((state:any) => state.evaluation.currentEvaluationTab);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [t] = useTranslation();

    const handleChange = (event: React.SyntheticEvent, newValue: any) => {
      switch(newValue) {
        case t('EVALUATION'):
          navigate('/selfassessment');
          break;
        case t('ON_SITE'):
          navigate('/onsite');
          break;
        case t('REPORTS'):
          navigate('/reports');
          break;
      } 
      dispatch(setCurrentEvaluationTab(newValue));
      dispatch(updateCurrentSection(newValue));
    };
    
    const theme = createTheme({
      components: {

      }
    })

    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ width: '100%' }}>
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange}
              sx={{
                '.MuiTabs-indicator': {
                  width: '0px !important'
                },
              }}>
                {
                    tabLabels.map((tabName: string, index: number) => {
                        return <Tab
                         sx={t(tabName)==selectedTab ?
                          { borderBottom: '2px solid #1976d2' }:
                          { borderBottom: '2px solid rgba(255, 255, 255, 0)' }
                         }
                         label={t(tabName)} 
                         value={t(tabName)} />;
                    })
                }
              </TabList>
            </Box>
            <PhaseHeader module={'EVALUATION'} />
            <TabPanel value={t('EVALUATION')} sx={{ p: 0 }}>
              <ClubCriteria />
            </TabPanel>
            <TabPanel value={t('ON_SITE')} sx={{ p: 0 }}>
              <ClubCriteria />
            </TabPanel>
            <TabPanel value={t('REPORTS')} sx={{ p: 0 }}>
              <ClubCriteria />
            </TabPanel>
          </TabContext>
        </Box>
      </ThemeProvider>
    );
}

export default EvaluationTabs;
import React, { useEffect, useState } from 'react';
//internal and external components import
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { TableRow, Checkbox, Chip, TextField, ThemeProvider, createTheme, Tooltip, Typography  } from '@mui/material';
import { stableSort, getComparator } from '../../common/SortSupport';
import { EnhancedTableHead } from '../EnhancedTableHead';
import { Box } from '@mui/material';
import logo from '../../assets/icons/JLogo.svg';
import SelectBoxPrimary from '../SelectBoxPrimary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck, faLinkSlash, faEyeSlash, faBicycle, faTrashCan, faTrash, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import TableSkeleton from '../TableSkeleton';
import { DATE_FORMAT, formatDate, genUID } from '../../common/StaticExports';
import { useDispatch, useSelector } from 'react-redux';
import NestedThreeDotMenu from '../NestedThreeDotMenu';
import PerformanceActionDetailPopper from '../../containers/PerformanceActionDetailPopper';
import PerformanceNewActionDetailPopper from '../../containers/PerformanceNewActionDetailPopper';
import ButtonDefault from '../ButtonDefault';
import { useTranslation } from 'react-i18next';
import { archiveAction, deleteAction, getAllActions, updateAction } from '../../api/action';
import { unassignAction } from '../../api/standards';
import ActionSelectBox from '../SearchSelectBox/ActionSelectBox';
import StaffSelectBox from '../SearchSelectBox/StaffSelectBox';
import DateSelector from '../DateSelector';
import { getStaff } from '../../api/user';
import { getClubDepartments } from '../../api/club';
import { editProject } from '../../api/projects';
import { setProjectDetails, setSelectedProject } from '../../redux/reducers/actionPlan';
import CommentsDocsActionsIconWrapper from '../CommentsDocsActionsIconWrapper';

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '6px 16px'
        }
      }
    },
    MuiStack: {
      styleOverrides: {
        root: {
          display: 'inline',
          width: '90px'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minWidth: '90px !important',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 12
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 18,
          height: 18
        }
      }
    }
  },
})

const PerformanceActionsTable = (props: any) => {
    const { data, project, isLoading, isAddLoading } = props;

    const head = [
      {
          key: '', displayName: 'action id',
      },
      {
          key: 'name', displayName: 'action',
      },
      {
          key: '', displayName: '',
      },
      {
          key: 'projectName', displayName: 'project',
      },
      {
          key: 'ragb', displayName: 'status',
      },
      {
          key: 'ownerName', displayName: 'owner',
      },
      {
          key: 'departmentName', displayName: 'department',
      },
      {
          key: 'dueDate', displayName: 'due date',
      },
      {
          key: '', displayName: '',
      },
    ]

  //supporting contents
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [openActionDetail, setOpenActionDetail] = React.useState(false);
  const isAdmin = useSelector((state:any) => state.common.isAdmin);
  const clubDetails = useSelector((state:any) => state.clubDetails);
  const isActions = useSelector((state:any) => state.actionPlan.isActions);
  const [dataList, setDataList] = useState(data);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const threeDotMenuOptions = 
  isActions ?
  [
    {label: 'Archive Action', icon: faEyeSlash, isDisabled: false},
    {label: 'Delete Action', icon: faTrash, isDisabled: false},
  ]:
  [
    {label: 'Unlink Action', icon: faLinkSlash, isDisabled: false},
    {label: 'Archive Action', icon: faEyeSlash, isDisabled: false},
    {label: 'Delete Action', icon: faTrash, isDisabled: false},
  ];
  const [actionData, setActionData] = useState([]);
  const [addNewAction, setAddNewAction] = useState(false);
  const [newActionData, setNewActionData]:any = useState({});
  const [actionList, setActionList] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const selectedProject = useSelector((state:any) => state.actionPlan.selectedProject);

  const handleThreedotMenuClick = async (selctedAction:any, action:any) => {
    switch(selctedAction) {
      case 'Unlink Action':
        unassignAction({ projectId: project.id, actionId: action?.id });
        break;
      case 'Delete Action':
        {
          await deleteAction({ actionId: action?.id });
          const tempArr = dataList.filter((x:any) => x.id != action.id);
          setDataList(tempArr);
        }
        break;
      case 'Archive Action':
        {
          await archiveAction({ actionId: action?.id });
          const tempArr = dataList.filter((x:any) => x.id != action.id);
          setDataList(tempArr);
        }
        break;
    }
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows:any = React.useMemo(
    () => 
      setDataList(
        data?.length > 0 ?
        stableSort(data, getComparator(order, orderBy)) : []
      ),
    [order, orderBy, data],
  );

  //update project api
  const updateproject = async () => {
      const response = await editProject({ projectId: selectedProject?.id, project: {...selectedProject, actions: [newActionData]} });
      const tempObj = { ...selectedProject, actions: response.action };
      dispatch(setSelectedProject(tempObj));
      dispatch(setProjectDetails(response));
  }

  const manageCreateClick = () => {
    selectedProject && selectedProject?.id &&
    Object.keys(newActionData).length > 0 &&
    Object.values(newActionData).some(value => value !== null && value !== '') &&
    updateproject();
    setNewActionData({});
    setAddNewAction(false);
  }

  const fetchActionList = async () => {
      const response = await getAllActions({ clubId: clubDetails.id, isSelfAssessment: true });
      setActionList(response);
  }
  const fetchStaffList = async () => {
    const response = await getStaff({ clubId: clubDetails?.id });
    setStaffList(response);
    // const selectedObj = response.find((item:any) => item.id == value);
    // setInputValue(selectedObj ? (selectedObj?.displayName || '') : '');
  }
  const fetchDepartmentList = async () => {
    const response = await getClubDepartments({ clubId: clubDetails?.id });
    setDepartmentList(response);
  }

  const manageStatus = async (status:any, action:any) => { 
    const tempAction = {...action, status: status}
    const response = await updateAction({ actionId: action.id, action: tempAction });
  }

  useEffect(() => {
      if (addNewAction && clubDetails && clubDetails?.id) {
        actionList && actionList.length == 0 &&
        fetchActionList();
        staffList && staffList.length == 0 &&
        fetchStaffList();
        departmentList && departmentList.length == 0 &&
        fetchDepartmentList();
      }
  }, [addNewAction]);
  
  //table contents
    return(
        <React.Fragment>
          <ThemeProvider theme={theme}>
          <Box sx={{ width: '100%', height: '100%' }}>
              <PerformanceActionDetailPopper isOpen={openActionDetail} handleClickAway={() => {setOpenActionDetail(false)}} actionData={actionData} projectData={data}/>
              <PerformanceNewActionDetailPopper isOpen={addNewAction && isActions} handleClickAway={() => {setAddNewAction(false)}} />
              <TableContainer sx={{ maxHeight: '100%', overflowY: 'auto', width: '100%' }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                    <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    tableHeads={!project ? head : head.filter(d => d.key !== 'projectName')}
                    bg='#fff'
                    />
                    <TableBody>
                    {
                      !isLoading && dataList?.length ?
                      dataList.map((d: any, i: number) =>
                        (
                          <TableRow
                          sx={{ cursor: 'pointer' }}
                          key={`actions-table-row-${i}`}
                          >
                              <TableCell className='fs12'>
                                {`A${project ? project?.projectIndex+'.'+(i+1) : i+1}`}
                              </TableCell>
                              <TableCell className='fs12'
                               sx={{ width: '500px' }}
                               onClick={() => {
                                setActionData({...d, actionIndex: project ? project?.projectIndex+'.'+(i+1) : i+1});
                                setOpenActionDetail(true);}}>
                                  {(d.name?.length > 40 && false) ? d.name.slice(0, 40) + '...' : d.name}
                              </TableCell>
                              <TableCell>
                                <Box sx={{ display: 'flex' }}>
                                  {
                                      d?.isCreatedByJL ?
                                      <img className='smallLogoForTables ml10 displayinlineBlock' src={logo} alt="JL Logo" />:
                                      <Box className={'smallLogoForTables ml10'} />
                                  }
                                  <CommentsDocsActionsIconWrapper comments={d?.comments ? d?.comments : 0} tasks={d?.tasks?.length > 0 ? d?.tasks?.length : 0} />
                                </Box>
                              </TableCell>
                              {
                                !project &&
                                <TableCell className='fs12'>
                                  {d.projectName}
                                </TableCell>
                              }
                              <TableCell className='fs12'>
                                <SelectBoxPrimary label={''} 
                                options={[0, 1, 2, 3, 4]} 
                                isStatus={true} 
                                selected={d?.ragb ? d.ragb : 4}
                                manageChange={(e:any) => {manageStatus(e, d);}}
                                report={'rabg'} />
                              </TableCell>
                              <TableCell className='fs12'>
                                {d?.ownerName ? d?.ownerName : '-'}
                              </TableCell>
                              <TableCell className='fs12'>
                                {d?.departmentName ? d?.departmentName : '-'}
                              </TableCell>
                              <TableCell className='fs12'>{d?.dueDate ? formatDate(d.dueDate) : '-'}</TableCell>
                              <TableCell sx={{ width: '1px' }}>
                                <NestedThreeDotMenu options={threeDotMenuOptions} manageClick={(e:any) => {handleThreedotMenuClick(e, d)}} />
                              </TableCell>
                          </TableRow>
                      )) :
                      (
                        !isLoading && (dataList?.length == 0 || dataList == undefined) ?
                        <TableRow>
                          <TableCell colSpan={10} className='fs12'>No Action Found</TableCell>
                        </TableRow>:
                        <TableSkeleton rowCount={6} cellCount={project ? 7 : 9} />
                      )
                  }
                  {/* add new action in table */}
                  {
                    !isActions && addNewAction ? 
                    <TableRow
                    sx={{ cursor: 'pointer' }}
                    key={`actions-table-row-create`}
                    >
                        <TableCell>
                        </TableCell>
                        <TableCell className='fs12'>
                          <ActionSelectBox optionList={actionList} variant={'standard'} selected={newActionData?.name} manageActionSelect={(data:any) => {
                            if (data && Object.keys(data).includes('id')) {
                              setNewActionData({...data, standardHistory: []});
                            } else {
                              setNewActionData({...newActionData, ...data, standardHistory: []});
                            }
                            }}
                            />
                        </TableCell>
                        <TableCell className='fs12'>
                            <SelectBoxPrimary label={''} 
                            options={[0, 1, 2, 3, 4]} 
                            isStatus={true} 
                            selected={newActionData?.status ? newActionData?.status : 0} 
                            manageChange={(stat:any) => {
                              setNewActionData({...newActionData, status: stat});
                            }}
                            report={'rabg'} />
                        </TableCell>
                        <TableCell className='fs12'>
                          <Box sx={{ width: '120px' }}>
                            <StaffSelectBox options={staffList} variant={'standard'} selectedValue={newActionData?.ownerUserId}
                            manageSelect={(data:any) => {
                              setNewActionData({...newActionData, ownerName: data.displayName, ownerUserId: data.id, standardHistory: []})
                            }}/>
                          </Box>
                        </TableCell>
                        <TableCell className='fs12'>
                          <StaffSelectBox options={departmentList} variant={'standard'} selectedValue={newActionData?.projectId}
                              manageSelect={(e:any) => {setActionData({...newActionData, departmentName: e.name, departmentId: e.id, standardHistory: []})}} />
                          </TableCell>
                        <TableCell className='fs12'>
                          <Box sx={{ width: '120px' }}>
                            <DateSelector variant='standard' dateValue={newActionData?.dueDate ? formatDate(newActionData?.dueDate) : null} 
                            manageChange={(e:any) => {setNewActionData({...newActionData, dueDate: e ? e : null, standardHistory: []})}}/>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex' }}>
                            <Tooltip title='Delete' arrow placement='top'><FontAwesomeIcon onClick={() => {setNewActionData({}); setAddNewAction(false); }} className={'icon mr10'} icon={faXmark}/></Tooltip>
                            <Tooltip title='Create' arrow placement='top'><FontAwesomeIcon onClick={() => {manageCreateClick()}} className={'icon mr10'} icon={faCheck} /></Tooltip>
                          </Box>
                        </TableCell>
                    </TableRow>:
                    (
                      isAddLoading &&
                      <TableSkeleton rowCount={1} cellCount={8} />
                    )
                  }
                    </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ float: 'left', mt: 2 }}>
                  <ButtonDefault
                  variant={'outlined'}
                  value={t('ADD_ACTION')}
                  icon={<FontAwesomeIcon className={'fs13'} icon={faCirclePlus} />}
                  manageOnClick={() => {setAddNewAction(true)}}
                  disabled={addNewAction}
                  />
              </Box>
            </Box>
          </ThemeProvider>
        </React.Fragment>
    )
}

export default PerformanceActionsTable;
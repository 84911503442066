import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import ClubCards from "../../components/EDClubCards";
import { colors } from "../../common/pallette/colors";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import MessageModalPopup from "../../components/MessageModalPopup/index.tsx";
import { getClubs, getLeagues, managePinnedClubs } from "../../api/common";
import { setLeagues, setMyClubs, setPinnedClubs } from "../../redux/reducers/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import "./styles.css";
import { Typography } from "@mui/material";
import ClubCardsSkeleton from "../../components/ClubCardsSkeleton";

export default function PinnedClubCards() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const pinnedClubs = useSelector((state: any) => state.common.pinnedClubs);
  const [openPopup, setOpenPopup] = useState(false);
  const [isfetchingClubs, setIsFetchingClubs] = useState(false);
  const myClubs = useSelector((state: any) => state.common.myClubs);
  const pinClub = async (clubId: any) => {
    const response = await managePinnedClubs({ clubId: clubId });
    return response;
  };

  useEffect(() => {
    let filteredClubs = myClubs ? myClubs : [];
    const tempArr = filteredClubs.filter((d: any) => d.isPinned);
    dispatch(setPinnedClubs(tempArr));
  }, [dispatch, myClubs]);

  const manageIsPinnedClick = (data: any) => {
    if (pinnedClubs?.length < 10 || data.isPinned) {
      pinClub(data.id)
        .then(() => {
          let tempData = { ...data, isPinned: !data.isPinned };
          let index = myClubs.findIndex((x: any) => x.id === data.id);
          let updatedClubDetails: any = [...myClubs];
          updatedClubDetails.splice(index, 1, tempData);
          dispatch(setMyClubs(updatedClubDetails));
        })
        .catch((err: any) => console.error(err));
    } else if (pinnedClubs?.length === 10) {
      setOpenPopup(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetchingClubs(true);
        const response = await getClubs();
        const leagues = await getLeagues();
        dispatch(setLeagues(leagues));
        return response;
      } catch {
        console.error("error in fetching club data");
      }
    };
    if (myClubs.length == 0) {
      fetchData().then((res) => {
        dispatch(setMyClubs(res));
        const tempArr = res?.filter((d: any) => d.isPinned);
        dispatch(setPinnedClubs(tempArr));
        setIsFetchingClubs(false);
      });
    }
  }, []);

  const addNewClubMethod = () => {};

  return (
    <div>
      <Box className="myClubsDetails" sx={{ borderColor: colors.grey.bright }}>
        {openPopup && (
          <MessageModalPopup
            open={openPopup}
            message={"Reached the maximum limit of pinned clubs."}
            closeModal={() => {
              setOpenPopup(false);
            }}
          />
        )}
        <Box className="myClubsContentWrapper">
          <Box className="myClubCardsContainer">
            {!isfetchingClubs && pinnedClubs
              ? pinnedClubs?.map((club: any) => {
                  return (
                    <ClubCards
                      data={club}
                      manageIsPinnedClick={manageIsPinnedClick}
                    />
                  );
                })
              : Array.from({ length: 10 }, () => {
                  return <ClubCardsSkeleton />;
                })}
            {!isfetchingClubs && pinnedClubs?.length < 10 && (
              <div className="myClubCard1">
                <div className="myClubCardTopSection1">
                  <div className="cardDetail1">
                    {/* <span className='clubCardClubName'>{data.clubName.length < 23 ? data.clubName : data.clubName.slice(0, 20) + '...'}</span> */}
                    <FontAwesomeIcon
                      className="fa-sharp fa-regular fa-circle-plus"
                      icon={faCirclePlus}
                      style={{
                        fontSize: "40px",
                        marginBottom: "10px",
                        marginTop: "10px",
                        color: "gray", // Set the color to gray
                      }}
                      onClick={addNewClubMethod}
                    />
                    <span className="clubCardClubName1">{t("ADD_CLUB")}</span>
                  </div>
                </div>
              </div>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

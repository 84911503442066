import axios from "axios";
import { baseUrl } from "./config";

export const getClubProgressDetails = () => {
  return axios({
    url: `${baseUrl}/api/clubs/GetProgress`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((response) => response.data.data);
};

export const getClubDatesDetails = () => {
  return axios({
    url: `${baseUrl}/api/clubs/EvaluationDates`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((response) => response.data.data);
};

export const getClubTimelineDetails = (month: number) => {
  return axios({
    url: `${baseUrl}/api/clubs/EvaluationTimeLine?months=${month}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((response) => response.data.data);
};

export const getClubPeopleDetails = () => {
  return axios({
    url: `${baseUrl}/api/clubs/GetClubPeople`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((response) => response.data.data);
};

export const getClubAssociatedPeopleDetails = () => {
  return axios({
    url: `${baseUrl}/api/clubs/GetClubAssociatedPeople`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((response) => response.data.data);
};

export const getActiveClubEvaluationDetails = (id: number) => {
  return axios({
    url: `${baseUrl}/api/clubs/${id}/ActiveClubDetail`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((response) => response.data.data);
};

import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import StatusIndicatorSmall from "../StatusIndicatorSmall";
import ProgressBar from "../ProgressBar";
import { useTranslation } from "react-i18next";
import { AvatarAndName } from "../AvatarAndName";
import { colors } from "../../common/pallette/colors";
import './styles.css';
import SubGoalsTable from "../SubGoalsTable";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedGoal } from "../../redux/reducers/goalsAndStrategy";
import { useNavigate } from "react-router";
import NewAcademySubGoalPopper from "../../containers/NewAcademySubGoalPopper";
import { formatToYear } from "../../common/StaticExports";
import { baseUrl } from "../../api/config";
import ButtonDefault from "../ButtonDefault";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { getGoalDetail } from "../../api/goals";

export const GoalCard = (props:any) => {
    const { data, isSubGoal } = props;
    const [t] = useTranslation();
    const goals = useSelector((state:any) => state.goalsAndStrategy.goals);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [addSubGoal, setAddSubGoal] = useState(false);

    const manageChange = (from:any, item:any) => {
        switch(from) {
            case 'addSubGoal':
                setAddSubGoal(true);
                break;
        }
    }

    return (
        <Box
        className={'cardContainer hoverBGlite'} 
        onClick={(e:any) => {
            e.stopPropagation();
            dispatch(setSelectedGoal(data));
            navigate(`/goals/${data.id}`);
         }}
        sx={{ 
            border: `2px solid ${colors.grey.light}`
         }}>
            <NewAcademySubGoalPopper isOpen={addSubGoal} handleClickAway={() => {setAddSubGoal(false)}} />
            <Box className={'goalCardHeader mb5'}>
                <Typography className="cardTitle">{data?.name ? data?.name : ''}</Typography>
                <Box className={'goalCardTopRight'}>
                    <Typography className="fs14 mr20">
                        {
                            `${formatToYear(data?.yearFrom)} - ${formatToYear(data?.yearTo)}`
                        }
                    </Typography>
                    <StatusIndicatorSmall reportType={'rabg'} status={data?.status ? data?.status : 0} />
                </Box>
            </Box>
            <Typography className="fs14 mb10">{data?.description ? data?.description : ''}</Typography>
            <Box sx={{ width: '100%', mb: '10px' }}>
                <ProgressBar percent={data?.progress ? data?.progress : 0} width="100%"/>
            </Box>
            <Typography className="cardTitle mb5">{t('MEMBERS')}</Typography>
            {
                data?.members?.length > 0 ?
                <Box sx={{ display: "flex", mb: '10px' }}>
                    {
                        data?.members.map((item:any) => {
                            return(
                                <Box className={'mr10'}>
                                    <AvatarAndName 
                                    avatarName={item?.userName ? item?.userName : ''}
                                    avatarImage={item?.avatarPath ? baseUrl+'/Content'+item?.avatarPath : ''} />
                                </Box>
                            )
                        })
                    }
                </Box> :
                <Typography>{t('NO_MEMBERS_LABEL')}</Typography>
            }
            {
                !isSubGoal &&
                <>
                    <Typography className="cardTitle mb10">{t('SUB_GOALS')}</Typography>
                    {
                        data?.subGoals?.length > 0 ?
                        (
        
                            <SubGoalsTable data={data?.subGoals} isLoading={false}
                            handleChange={(e:any, from:any, item:any) => {e.stopPropagation(); manageChange(from, item)}} />
                        ) :
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '10px' }}>
                            <Typography className="fs14">{t('NO_SUB_GOALS_LABEL')}</Typography>
                            <ButtonDefault
                            variant={'outlined'}
                            value={t('ADD_SUB_GOAL')}
                            // manageOnClick={(e:any) => {handleChange(e, 'addSubGoal', data)}}
                            icon={<FontAwesomeIcon className={'fs13'} icon={faCirclePlus} />}
                            />
                        </Box>
                    }
                </>
            }
        </Box>
    )
}
import React, { useEffect, useState } from "react";
import { getStaff } from "../../api/user";
import { useSelector } from "react-redux";
import AvatarMultiSelectCheckBox from "../AvatarMultiSelectCheckBox";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

const StaffMultiSelectCheckBox = (props: any) => {
  const { manageOnFocus, manageChange, selectedList } = props;
  const [staffList, setStaffList] = useState([]);
  const clubDetails = useSelector((state: any) => state.clubDetails);
  const [t] = useTranslation();

  const fetchStaffList = async () => {
    const response = await getStaff({ clubId: clubDetails?.id });
    setStaffList(response);
  };

  useEffect(() => {
    staffList?.length === 0 && fetchStaffList();
  }, []);

  const manageSelectedValues = (updatedValue: any) => {
    const selectedId = updatedValue?.id;

    var tempArr = [updatedValue];
    if(selectedList?.length > 0) {
      const filteredArr = selectedList?.filter((item:any) => selectedId !== item.id);
      tempArr = filteredArr?.length === selectedList?.length ? [updatedValue, ...filteredArr] : filteredArr;
    }

    manageChange({ members: tempArr });
  };

  return (
    <Box
      onMouseDown={(e: any) => {
        e.stopPropagation();
        manageOnFocus(e);
      }}
      onClick={(e: any) => {
        e.stopPropagation();
        manageOnFocus(e);
      }}
    >
      <AvatarMultiSelectCheckBox
        options={staffList}
        selectedValues={selectedList}
        label={t("MEMBERS")}
        setSelectedValues={manageSelectedValues}
        manageOnFocus={manageOnFocus}
      />
    </Box>
  );
};

export default StaffMultiSelectCheckBox;

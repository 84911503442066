import React from "react";
import { useSelector, useDispatch } from "react-redux";
import "./styles.css";
//internal and external components
import Divider from "@mui/material/Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faThumbtack } from "@fortawesome/free-solid-svg-icons";
import StatusIndicatorSmall from "../StatusIndicatorSmall";
import { stringify } from "querystring";
import { colors } from "../../common/pallette/colors";
import { useNavigate } from "react-router-dom";
import { setCurrentClub } from "../../redux/reducers/clubDetails";
import { useTranslation } from "react-i18next";

const ClubCards = (props: any) => {
  const { data, manageIsPinnedClick } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const currentClub = useSelector((state: any) => state.clubDetails);

  const manageClubClick = () => {
    const d: any = {
      id: data.id,
      name: data.name,
      color: data.color,
      logo: data?.imageUrl,
      currentEvaluationPhase: data.currentEvaluationPhase,
    };
    dispatch(setCurrentClub(d));
  };

  // https://jamp.football/Content/clubLogos/c966b268-ef99-42fb-8b1e-cff414905570_ac%20nag%20parc.png
  return (
    <React.Fragment>
      <div
        className="myClubCard1"
        onClick={manageClubClick}
        style={{
          backgroundColor:
            data.id === currentClub?.id ? "#e8e8e8" : "transparent",
        }}
      >
        <div className="myClubCardTopSection1">
          <img src={`https://jamp.football${data?.imageUrl}`} alt="clubICon" />
          <div className="cardDetail1">
            {/* <span className='clubCardClubName'>{data.clubName.length < 23 ? data.clubName : data.clubName.slice(0, 20) + '...'}</span> */}
            <span className="clubCardClubName1">{data.name}</span>
          </div>
        </div>
        <FontAwesomeIcon
          className="icon cursorPointer1 pinStyle"
          icon={faThumbtack}
          onClick={(e: any) => {
            e.stopPropagation();
            manageIsPinnedClick(data);
          }}
          style={{
            color: data.isPinned ? colors.grey.dark : colors.grey.light,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default ClubCards;

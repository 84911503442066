import React from 'react';
//internal and external componentscontainers
import { Typography, Avatar, Box} from '@mui/material';

export const AvatarAndName = (props: any) => {
    const { avatarName, avatarImage, avatarSize } = props;
    return(
        <React.Fragment>
            <Box sx={{ display: 'flex', minWidth: avatarName != undefined && avatarName != null ? 100 : '20px' }}>
                <Avatar className='mr10' alt={avatarName} src={avatarImage} sx={{ width: avatarSize ? avatarSize : 20, height: avatarSize ? avatarSize :20 }} />
                {
                    avatarName != undefined && avatarName != null &&
                    <Typography className='fs13' sx={{ mt: '0px' }}>{avatarName}</Typography>
                }
            </Box>
        </React.Fragment>
    )
}
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import ProjectsTable from "../../components/CriteriaTable/ProjectsTable";
import PerformanceActionsTable from "../../components/PerformanceActionsTable";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../common/pallette/colors";
import { editProject, getProjectDetails } from "../../api/projects";
import { setProjectDetails, setProjects } from "../../redux/reducers/actionPlan";
import { TextFieldWithoutBorders } from "../../components/TextFieldWithoutBorders";
import { TextAreaWithoutBorders } from "../../components/TextAreaWithoutBorders";
import { useTranslation } from "react-i18next";
import OwnerSelectBox from "../../components/OwnerSelectBox";
import PerfActionDateSelector from "../../components/PerfActionDateSelector";
import DepartmentSelectBox from "../../components/DepartmentSelectBox";

export const ProjectsWrapper = () => {
    const currentProject = useSelector((state:any) => state.actionPlan.selectedProject);
    const details = useSelector((state:any) => state.actionPlan.details);
    const [isLoadingProjectDetails, setIsLoadingProjectDetails] = React.useState(false);
    const [isStateUpdated, setIsStateUpdated] = useState(false);
    const dispatch = useDispatch();
    const [t] = useTranslation();
    var tempObj:any = {};
    
    const fetchProjectDetails = async () => {
        setIsLoadingProjectDetails(true);
        const response = await getProjectDetails({ projectId: currentProject?.id, pastStatusPeriod: 1 });
        dispatch(setProjectDetails(response));
        setIsLoadingProjectDetails(false);
    }
    React.useEffect(() => {
        Object.keys(currentProject).length &&
        fetchProjectDetails();
    }, [currentProject]);

    const handleChangeState = async (updatedState:any) => {
        tempObj = {...details, ...updatedState};
        dispatch(setProjectDetails(tempObj));
        setIsStateUpdated(true);
    }

    const manageUpdateProject = async () => {
        const response = await editProject({ projectId: details?.id, project: {...tempObj} });
        setIsStateUpdated(false);
    }

    const handleSelectBox = async (newData:any) => {
        await handleChangeState(newData);
        manageUpdateProject();
    }

    return(
        <Box sx={Object.keys(currentProject).length ? {display: "flex", mt: 1, height: 'calc(100% - 47px)'} : { mt: 1, height: 'calc(100% - 47px)' }}>
            <ProjectsTable />
            {(Object.keys(currentProject).length > 0) && 
            <Box sx={{ ml: '20px', width: '100%', height: '100%' }}>
                <Box className={'projectDetailsHeader'}>
                    <Box className='projectDetailsHeading'>
                        <Typography className='projectDetailsTitle'>{`P${currentProject.projectIndex} ${details.name}`}</Typography>
                        {/* <Typography className='fs14'>{details?.description ? details?.description : ''}</Typography> */}
                        <TextFieldWithoutBorders
                        value={details?.name ? details?.name : ''}
                        handleChange={(e:any) => {
                            const tempObj = {name: e.target.value};
                            handleChangeState(tempObj);
                        }}
                        placeholder={t('ENTER_ACTION_NAME')}
                        manageOnBlur={isStateUpdated && manageUpdateProject}
                        />
                        <TextAreaWithoutBorders
                        value={details?.description ? details?.description : ''}
                        handleChange={(e:any) => {
                            const tempObj = {description: e.target.value};
                            handleChangeState(tempObj);
                        }}
                        placeholder={t('ENTER_ACTION_DESCRIPTION')}
                        manageOnBlur={isStateUpdated && manageUpdateProject}
                        />
                    </Box>
                    <Box className={'PDselectBoxWrapper'}>
                        <OwnerSelectBox value={details?.assigneeUserId} manageSelect={handleSelectBox} dataLabel={'assigneeUserId'}/>
                        <DepartmentSelectBox value={details?.departmentId} manageSelect={handleSelectBox} />
                        <PerfActionDateSelector value={details?.startDate} manageSelect={handleSelectBox} dataLabel={'startDate'} label={t('START_DATE')}/>
                        <PerfActionDateSelector value={details?.dueDate} manageSelect={handleSelectBox} dataLabel={'dueDate'}/>
                    </Box>
                </Box>
              <Box className={'perfActionTableWrapper'}>
                <PerformanceActionsTable data={details?.actions} project={currentProject} isLoading={isLoadingProjectDetails}/>
              </Box>
            </Box>}
        </Box> 
    );
}
import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { useTranslation } from "react-i18next";
import { EnhancedTableHead } from "../../../components/EnhancedTableHead";
import TableSkeleton from "../../../components/TableSkeleton";
import PinnedClubCards from "../PinnedClubCards";
import { useDispatch, useSelector } from "react-redux";
import { getActiveClubEvaluationDetails } from "../../../api/evaluationDashboard";
import { setActiveClubDetail } from "../../../redux/reducers/evaluationDashboard";
import { TableCell, TableRow } from "@mui/material";
import { formatDate, getIconForValues, getStars } from "../Utils";

export default function MyEvaluationPage() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");
  const currentClub = useSelector((state: any) => state.clubDetails);
  const activeClubDetail: any = useSelector(
    (state: any) => state.evaluationDashboard.activeClubDetail
  );
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const peopleHead = [
    {
      key: "",
      displayName: t("ASM").toUpperCase(),
    },
    {
      key: "",
      displayName: t("EVALUATION_TEAM").toUpperCase(),
    },
    {
      key: "",
      displayName: t("TRANSLATOR").toUpperCase(),
    },
    {
      key: "",
      displayName: t("DC_1_ACADEMY_DIRECTOR").toUpperCase(),
    },
  ];

  const Head2 = [
    {
      key: "",
      displayName: "",
    },
    {
      key: "",
      displayName: t("STATUS").toUpperCase(),
    },
    {
      key: "",
      displayName: t("DUE_DATE").toUpperCase(),
    },
  ];

  const Head3 = [
    {
      key: "",
      displayName: t("CRITERIA").toUpperCase(),
    },
    {
      key: "",
      displayName: t("STATUS").toUpperCase(),
    },
    {
      key: "",
      displayName: t("PROGRESS").toUpperCase(),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      dispatch(
        setActiveClubDetail(
          await getActiveClubEvaluationDetails(currentClub.id)
        )
      );
    };
    if (currentClub.id !== 0) {
      fetchData();
    }
  }, [dispatch, currentClub]);

  return (
    <div>
      <PinnedClubCards />
      <>
        <TableContainer sx={{ height: "70%" }}>
          <Table
            stickyHeader
            sx={{ boxShadow: "none" }}
            size="small"
            aria-label="a dense table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              tableHeads={peopleHead}
            />
            <TableBody>
              {Object.entries(activeClubDetail).length > 0 ? (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "&:hover": {
                      backgroundColor: "#f5f5f5", // Light gray background on hover
                      cursor: "pointer", // Change cursor to pointer to indicate clickability
                    },
                  }}
                >
                  <TableCell
                    align="left"
                    style={{ fontSize: 12, fontFamily: "Montserrat" }}
                  >
                    {activeClubDetail.clubPeople?.asm || "-"}{" "}
                    {/* Display default if undefined */}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ fontSize: 12, fontFamily: "Montserrat" }}
                  >
                    {activeClubDetail.clubPeople?.evaluationTeam || "-"}{" "}
                    {/* Display default if undefined */}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ fontSize: 12, fontFamily: "Montserrat" }}
                  >
                    {activeClubDetail.clubPeople?.translator || "-"}{" "}
                    {/* Display default if undefined */}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ fontSize: 12, fontFamily: "Montserrat" }}
                  >
                    {activeClubDetail.clubPeople?.academyDirector || "-"}{" "}
                    {/* Display default if undefined */}
                  </TableCell>
                </TableRow>
              ) : (
                <TableSkeleton rowCount={1} cellCount={4} /> // Show skeleton loader if activeClubDetail is undefined
              )}
              <TableRow></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
      <br />
      <Box sx={{ display: "flex", gap: "16px" }}>
        <>
          <TableContainer sx={{ height: "70%" }}>
            <Table
              stickyHeader
              sx={{ boxShadow: "none" }}
              size="small"
              aria-label="a dense table"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                tableHeads={Head2}
              />
              <TableBody>
                {Object.entries(activeClubDetail).length > 0 ? (
                  <>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": {
                          backgroundColor: "#f5f5f5", // Light gray background on hover
                          cursor: "pointer", // Change cursor to pointer to indicate clickability
                        },
                      }}
                    >
                      <TableCell>{t("TARGET")}</TableCell>
                      <TableCell>
                        {getStars(activeClubDetail?.clubPeople?.targetRating) ||
                          "-"}
                      </TableCell>
                      <TableCell>
                        {formatDate(activeClubDetail?.targetDate) ||
                          "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": {
                          backgroundColor: "#f5f5f5", // Light gray background on hover
                          cursor: "pointer", // Change cursor to pointer to indicate clickability
                        },
                      }}
                    >
                      <TableCell>{t("SELF_ASSESSMENT")}</TableCell>
                      <TableCell>
                        {getIconForValues(activeClubDetail?.selfAssesment) ||
                          "-"}
                      </TableCell>
                      <TableCell>
                        {formatDate(activeClubDetail?.selfAssesment1) || "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": {
                          backgroundColor: "#f5f5f5", // Light gray background on hover
                          cursor: "pointer", // Change cursor to pointer to indicate clickability
                        },
                      }}
                    >
                      <TableCell>{t("OFF_SITE")}</TableCell>
                      <TableCell>
                        {getIconForValues(activeClubDetail?.offSiteProgress) ||
                          "-"}
                      </TableCell>
                      <TableCell>
                        {formatDate(activeClubDetail?.offSite) || "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": {
                          backgroundColor: "#f5f5f5", // Light gray background on hover
                          cursor: "pointer", // Change cursor to pointer to indicate clickability
                        },
                      }}
                    >
                      <TableCell>{t("ON_SITE")}</TableCell>
                      <TableCell>
                        {getIconForValues(activeClubDetail?.onSiteProgress) ||
                          "-"}
                      </TableCell>
                      <TableCell>
                        {formatDate(activeClubDetail?.onSite) || "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": {
                          backgroundColor: "#f5f5f5", // Light gray background on hover
                          cursor: "pointer", // Change cursor to pointer to indicate clickability
                        },
                      }}
                    >
                      <TableCell>{t("INTERIM_REPORT")}</TableCell>
                      <TableCell>
                        {getIconForValues(activeClubDetail?.interimReport) ||
                          "-"}
                      </TableCell>
                      <TableCell>
                        {formatDate(activeClubDetail?.interimReportDate) || "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": {
                          backgroundColor: "#f5f5f5", // Light gray background on hover
                          cursor: "pointer", // Change cursor to pointer to indicate clickability
                        },
                      }}
                    >
                      <TableCell>
                        {t("SELF_ASSESSMENT").toUpperCase() + " " + 2}
                      </TableCell>
                      <TableCell>
                        {getIconForValues(activeClubDetail?.selfAssesment) ||
                          "-"}
                      </TableCell>
                      <TableCell>
                        {formatDate(activeClubDetail?.selfAssesment2) || "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": {
                          backgroundColor: "#f5f5f5", // Light gray background on hover
                          cursor: "pointer", // Change cursor to pointer to indicate clickability
                        },
                      }}
                    >
                      <TableCell>{t("FINAL_REPORT")}</TableCell>
                      <TableCell>
                        {getIconForValues(activeClubDetail?.finalReport) || "-"}
                      </TableCell>
                      <TableCell>
                        {formatDate(activeClubDetail?.finalReportDate) || "-"}
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <TableSkeleton rowCount={5} cellCount={3} />
                )}
                <TableRow></TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
        <>
          <TableContainer sx={{ height: "70%" }}>
            <Table
              stickyHeader
              sx={{ boxShadow: "none" }}
              size="small"
              aria-label="a dense table"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                tableHeads={Head3}
              />
              <TableBody>
                <TableSkeleton rowCount={5} cellCount={3} />
              </TableBody>
            </Table>
          </TableContainer>
        </>
      </Box>
    </div>
  );
}

import { Box, Typography, Button, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TargetAcheivedStars from '../../components/TargetAcheivedStars';
import StatusButton from '../../components/StatusButton';
import ExecutiveSummaryPopper from '../ExecutiveSummaryPopper';
import StackedHorizontalBar from '../../components/Charts/StackedHorizontalBar';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getClubReviewsInfo, getInterviewers, getInterviewTeamUsers, getOnSiteRoles } from '../../api/evaluation';
import { getUsersByClub } from '../../api/club';
import { setClubUsers, setUserRoles } from '../../redux/reducers/common';
import { setInterviewers, setInterviewTeams, setInterviewView } from '../../redux/reducers/evaluation';
import DataPreview from '../../components/DataPreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faTableList } from '@fortawesome/free-solid-svg-icons';
import { SwitchButton } from '../../components/SwitchButton';
import { colors } from '../../common/pallette/colors';
import { setIsActions } from '../../redux/reducers/actionPlan';
import { TextAreaWithoutBorders } from '../../components/TextAreaWithoutBorders';
import TextAreaOrderedList from '../../components/TextAreaOrderedList';
import { resetGoalsHeader, setGoalsHeader } from '../../redux/reducers/goalsAndStrategy';
import { getGoalsHeader, updateGoalsHeader } from '../../api/goals';

const PhaseHeader = (props:any) => {
    const { module } = props;
    const [isOpen, setIsOpen] = React.useState(false);
    const clubDesirePlan = useSelector((state: any) => state.clubData.clubDesirePlan);
    const saCriteria = useSelector((state: any) => state.evaluation.saCriteria);
    const clubDetails = useSelector((state:any) => state.clubDetails);
    const licenceCriteria = useSelector((state: any) => state.licence.licenceCriteria);
    const [statusData, setStatusData] = useState([]);
    const [t] = useTranslation();
    var [statusLabel, setStatusLabel] = useState(clubDetails.currentEvaluationPhase);
    const dispatch = useDispatch();
    const currentSection = useSelector((state:any) => state.evaluation.currentSection);
    const interviewers = useSelector((state:any) => state.evaluation.interviewers);
    const interviewView = useSelector((state:any) => state.evaluation.interviewView);
    const projects = useSelector((state:any) => state.actionPlan.projects);
    const allActions = useSelector((state:any) => state.actionPlan.allActions);
    const isActions = useSelector((state:any) => state.actionPlan.isActions);
    const goalsHeader = useSelector((state:any) => state.goalsAndStrategy.goalsHeader)
    const [updatedGoalsHeader, setUpdatedGoalsHeader] = useState(false);
    const [projectsStatus, setProjectsStatus] = useState([]);
    const [actionsStatus, setActionsStatus] = useState([]);
    const siteReviewPalette = [
      colors.grey.dark,
      colors.statusColors.met,
      colors.statusColors.partial,
      colors.statusColors.notMet
    ];
    const projectsPalette = [
      colors.lightishRed,
      colors.tangerine,
      colors.vibrantGreen,
      colors.gold,
      colors.middleGrey,
    ];

      const jla = [
        {
          name: "Not Evaluated",
          data: [0]
        },{
          name: "Met",
          data: [4]
        },
        {
          name: "Partial",
          data: [0]
        },
        {
          name: "Not Met",
          data: [2]
        }
      ]

      useEffect(() => {
        var notEval = 0;
        var met = 0;
        var partial = 0;
        var notMet = 0;
        if((saCriteria?.length && clubDetails?.currentEvaluationPhase != '') ||
      (licenceCriteria?.length && module == 'LICENCE')) {
          var key = '';
          const caseString = module == 'EVALUATION' ? clubDetails.currentEvaluationPhase : module;
          switch(caseString) {
            case 'SELF_ASSESSMENT':
              key = 'offSiteStatus';
              setStatusLabel('OFF_SITE');
              break;
            case 'OFF_SITE':
              key = 'offSiteStatus';
              break;
            case 'ON_SITE':
              key = 'onSiteStatus';
              break;
            case 'INTERIM_REPORT':
              key = 'interimReportStatus';
              break;
            case 'FINAL_REPORT':
              key = 'finalReportStatus';
              break;
            case 'LICENCE':
              key = 'status';
              setStatusLabel('LICENCE_EVALUATION');
              break;
          }
          const criteriaArr = module == 'EVALUATION' ? saCriteria : licenceCriteria;
          criteriaArr.map((d:any) => {
            switch(d[key]){
              case 0:
                notEval++;
                break;
              case 1:
                notMet++;
                break;
              case 2:
                partial++;
                break;
              case 3:
                met++;
                break;
            }
          });
          const tempData:any = [
            {
              name: "NOT_EVALUATED",
              data: [notEval],
            },
            {
              name: "MET",
              data: [met],
            },
            {
              name: "PARTIAL",
              data: [partial]
            },
            {
              name: "NOT_MET",
              data: [notMet]
            }
          ];
          setStatusData(tempData);
        }
      }, [saCriteria, clubDetails, module]);

      useEffect(() => {
        if ((module == t('ACTION_PLAN') && !isActions && projects) || (module == t('ACTION_PLAN') && isActions && allActions)) {
          var missed = 0;
          var atRisk = 0;
          var onTrack = 0;
          var completed = 0;
          var notStarted = 0;
          const tempArr = isActions ? allActions : projects;
          tempArr.map((item:any) => {
            switch(item.ragb) {
              case 0:
                missed++;
                break;
              case 1:
                atRisk++;
                break;
              case 2:
                onTrack++;
                break;
              case 3:
                completed++;
                break;
              case 4:
                notStarted++;
                break;
            }
          });
          const tempData:any = [
            {
              name: "MISSED",
              data: [missed],
            },
            {
              name: "AT_RISK",
              data: [atRisk],
            },
            {
              name: "ON_TRACK",
              data: [onTrack]
            },
            {
              name: "COMPLETED",
              data: [completed]
            },
            {
              name: "NOT_STARTED",
              data: [notStarted]
            }
          ];
          if(isActions) {
            setActionsStatus(tempData);
          } else {
            setProjectsStatus(tempData);
          }
        }
      }, [module, projects, allActions])

      const fetchOnSiteSupport = async () => {
        const reviewData = await getClubReviewsInfo({clubId: clubDetails?.id});
        const response = await getInterviewTeamUsers({onSiteReviewId: reviewData?.onSiteReviewId});
        const interviewers = await getInterviewers({onSiteReviewId: reviewData?.onSiteReviewId});
        const sortedInterviewers = interviewers.map((item:any) => {
          if (item.displayName == null) {
            const tempObj = response.find((d:any) => d.id == item.userId);
            if (tempObj) {
              return {...item, displayName: tempObj.displayName, roleName: tempObj.roleName};
            } else {
              return {...item, displayName: 'No Name', roleName: 'No Roles'};
            }
          } return {...item, roleName: ''};
        })
        dispatch(setInterviewers(sortedInterviewers));
        dispatch(setInterviewTeams(response));
      }

      //fetching goals header
      const fetchGoalsHeader = async () => {
        const response = await getGoalsHeader({ clubId: clubDetails?.id });
        if(response == null) {
          dispatch(resetGoalsHeader());
        } else {
          dispatch(setGoalsHeader(response));
        }
      }

      useEffect(() => {
        (module == 'EVALUATION' && currentSection == t('ON_SITE')) &&
        fetchOnSiteSupport();

        module == t('ACADEMY_GOALS') &&
        fetchGoalsHeader();
      }, [clubDetails, currentSection]);

      //managing goals header changes
      const manageGoalsHeaderChange = (key:any, updatedData:any) => {
        const tempObj = { ...goalsHeader, [key]: updatedData };
        dispatch(setGoalsHeader(tempObj));
        setUpdatedGoalsHeader(true);
      }
      //updating goals header api
      const updateGoalsHeaderChanges = async () => {
        if(updatedGoalsHeader) {
          const response = await updateGoalsHeader({ clubId: clubDetails?.id, header: goalsHeader });
          if(response == null) {
            dispatch(resetGoalsHeader());
          } else {
            dispatch(setGoalsHeader(response));
          }
          setUpdatedGoalsHeader(false);
        }
      }

    return(
        <React.Fragment>
          {/* Evaluation Content */}
          {
            module == 'EVALUATION' && 
            <Box className={'finalReportWrapper'} sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
              <TargetAcheivedStars label='current' acheived={clubDesirePlan.achievedStarRating ? clubDesirePlan.achievedStarRating : 0}/>
              <TargetAcheivedStars label='target' acheived={clubDesirePlan.starRating ?  clubDesirePlan.starRating : 0}/>
              <StackedHorizontalBar data={statusData} label={t(statusLabel)} colorPalette={siteReviewPalette} />
              {
                (currentSection == t('ON_SITE')) ?
                <DataPreview data={interviewers} label={'Interview Team'}/>:
                <StackedHorizontalBar data={jla} label={'Open JL Actions'} colorPalette={siteReviewPalette}/>
              }
              {
                  (currentSection == t('ON_SITE')) ?
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '120px', mr: '20px' }}>
                    <Box sx={{ m: 'auto' }}>
                      <Tooltip title='List View' arrow placement='top'>
                        <FontAwesomeIcon className={interviewView == 'LIST' ? 'selectedIconColor fs24' : 'icon fs24'} icon={faTableList} onClick={() => {dispatch(setInterviewView('LIST'))}} />
                      </Tooltip>
                    </Box>
                    <Box sx={{ m: 'auto' }}>
                      <Tooltip title='Calendar View' arrow placement='top'>
                        <FontAwesomeIcon className={interviewView == 'CALENDAR' ? 'selectedIconColor fs24 mb2' : 'icon fs24 mb2'} icon={faCalendarDays} onClick={() => {dispatch(setInterviewView('CALENDAR'))}} />
                      </Tooltip>
                    </Box>
                  </Box> :
                  <Box sx={(module == 'EVALUATION') ? { display: 'flex' } : { visibility: 'hidden' }}>
                      <Box sx={{ display: 'flex' }}>
                          <Typography className="mr10 capitalize fs13 fw800 mtbAuto">Executive Summary</Typography>
                          <StatusButton isTodo={true} status='todo'/>
                      </Box>
                      <Button variant='contained' className='mtbAuto' sx={{ height: '28px', ml: 1 }} disableElevation onClick={() => setIsOpen(true)}>Edit</Button>
                  </Box>
                }
                <ExecutiveSummaryPopper isOpen={isOpen} handleIsOpen={() => setIsOpen(false)}/>
            </Box>
          }
          {/* Performance Plan Content */}
          {
            module == t('ACTION_PLAN') &&
            <Box className={'finalReportWrapper'} sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
              <StackedHorizontalBar data={isActions ? actionsStatus : projectsStatus} label={isActions ? 'Actions Status' : 'Project Status'} colorPalette={projectsPalette}/>
              <SwitchButton 
              label={'Projects'}
              label01={'Actions'}
              isOn={isActions}
              handleOnClick={() => {
                const changeBool:any = !isActions;
                dispatch(setIsActions(changeBool));
              }}
              />
            </Box>
          }
          {/* Goals & Strategy Content */}
          {
            module == t('ACADEMY_GOALS') &&
            <Box className={'finalReportWrapper'} sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
              <Box sx={{ display: 'flex', width: '50%' }}>
                <Typography className={'fs14 fw700 mr10 uppercase'} sx={{ width: '70px', textAlign: 'left' }}>{t('ACADEMY_VISION')}</Typography>
                <Box sx={{ width: '80%' }}>
                  <TextAreaWithoutBorders 
                  value={goalsHeader?.vision ? goalsHeader?.vision : ''}
                  rows={2}
                  handleChange={(e:any) => {manageGoalsHeaderChange('vision', e.target.value)}}
                  placeholder={t('ACADEMY_VISION_PLACEHOLDER')}
                  manageOnBlur={updateGoalsHeaderChanges}/>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', width: '50%' }}>
                <Typography className={'fs14 fw700 mr10 uppercase'} sx={{ width: '90px', textAlign: 'left' }}>{t('ACADEMY_NORTH_STAR')}</Typography>
                <Box sx={{ width: '80%' }}>
                  <TextAreaOrderedList 
                  value={goalsHeader?.northStar ? goalsHeader?.northStar : ''}
                  rows={2} 
                  handleChange={(e:any) => {manageGoalsHeaderChange('northStar', e)}}
                  placeholder={t('ACADEMY_NORTH_STAR_PLACEHOLDER')}
                  manageOnBlur={updateGoalsHeaderChanges} />
                </Box>
              </Box>
            </Box>
          }
        </React.Fragment>
    )
}

export default PhaseHeader;
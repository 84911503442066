import React, { useEffect, useState } from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { colors } from "../../common/pallette/colors";
import { Box, ClickAwayListener } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalFiltersCount } from "../../redux/reducers/common";
import FiltersWrapper from "./filters/filtersWrapper";

const FilterContainer = (props: any) => {
  const { othersOptions } = props;
  const [isOpen, setIsOpen] = useState(false);
  const globalFilters = useSelector((state: any) => state.common.globalFilters);
  const globalFiltersCount = useSelector(
    (state: any) => state.common.globalFiltersCount
  );
  const dispatch = useDispatch();

  const targetStarRatingFilter = useSelector(
    (state: any) => state.evaluationDashboard.targetStarRatingFilter
  );
  const leagueFilter = useSelector(
    (state: any) => state.evaluationDashboard.leagueFilter
  );
  const otherFilter = useSelector(
    (state: any) => state.evaluationDashboard.otherFilter
  );

  useEffect(() => {
    let count: any = 0;
    globalFilters.map((d: any) => {
      if (
        d.value != "" ||
        d.operator == "is empty" ||
        d.operator == "is not empty"
      ) {
        count++;
      }
    });
    // setFilterCount(count);
    dispatch(setGlobalFiltersCount(count));
  }, [globalFilters]);

  useEffect(() => {
    let count: any = 0;
    targetStarRatingFilter.length > 0 && count++;
    leagueFilter.length > 0 && count++;
    otherFilter.length > 0 && count++;
    dispatch(setGlobalFiltersCount(count));
  }, [targetStarRatingFilter, leagueFilter, otherFilter]);

  return (
    <React.Fragment>
      <div className="filterBox" style={{ backgroundColor: colors.grey.light }}>
        <div
          className="filterButton pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <FontAwesomeIcon className="filterIcon icon" icon={faFilter} />
          <span style={{ color: colors.grey.dark }}>Filters</span>
        </div>
        {globalFiltersCount > 0 && (
          <Box
            className={"filterCount"}
            sx={{ backgroundColor: colors.blue.navy }}
          >
            {globalFiltersCount}
          </Box>
        )}
        {isOpen && (
          <ClickAwayListener
            onClickAway={() => {
              setIsOpen(false);
            }}
          >
            <Box className="filterWrapper">
              <FiltersWrapper filterOptions={othersOptions} />
            </Box>
          </ClickAwayListener>
        )}
      </div>
    </React.Fragment>
  );
};

export default FilterContainer;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SearchFilterContainer from "../../../containers/SearchFilterContainer";
import { Box } from "@mui/material";
import ProgressTable from "./ProgressTable";
import { setClubsProgress } from "../../../redux/reducers/evaluationDashboard";
import { getClubProgressDetails } from "../../../api/evaluationDashboard";
import "./styles.css";

export default function ProgressPage() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const othersOptions = [
    { id: 1, name: "SET", value: "set" },
    { id: 2, name: "NOT_SET", value: "notset" },
    { id: 3, name: "UNDEFINED", value: "undefined" },
    { id: 4, name: "UNASSIGNED", value: "unassigned" },
    { id: 5, name: "FAVOURITES", value: "favourites" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setClubsProgress(await getClubProgressDetails()));
    };

    fetchData();
  }, []);

  return (
    <>
      <Box className="ProgressHeader">
        <SearchFilterContainer
          parent={"evaluationDashboard"}
          othersOptions={othersOptions}
        />
      </Box>
      <br />
      <ProgressTable />
    </>
  );
}

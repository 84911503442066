import React, { useEffect, useState } from "react";
import "./styles.css";
//external-components
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha, Paper, Typography, Box } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { colors } from "../../common/pallette/colors";
import { useDispatch, useSelector } from "react-redux";
import { setAdminPageSearchString } from "../../redux/reducers/common";
import { setEvaluationSearchString } from "../../redux/reducers/evaluation";
import { setEvaluationDashboardSearchString } from "../../redux/reducers/evaluationDashboard";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: colors.grey.light,
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginLeft: 0,
  marginRight: "10px",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  fontSize: "14px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    //   paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingLeft: `40px`,
    transition: theme.transitions.create("width"),
    width: "100%",
    height: "100%",
  },
}));

const SearchBox = (props: any) => {
  const { searchBoxStyle, placeholder, parent } = props;
  const dispatch = useDispatch();
  const adminPageSearchString = useSelector(
    (state: any) => state.common.adminPageSearchString
  );
  const evalPageSearchString = useSelector(
    (state: any) => state.evaluation.searchString
  );
  const evalDashPageSearchString = useSelector(
    (state: any) => state.evaluationDashboard.searchString
  );
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    switch (parent) {
      case "adminHome":
        setSearchString(adminPageSearchString);
        break;
      case "evaluation":
        setSearchString(evalPageSearchString);
        break;
      case "evaluationDashboard":
        setSearchString(evalDashPageSearchString);
        break;
    }
  }, [
    parent,
    adminPageSearchString,
    evalPageSearchString,
    evalDashPageSearchString,
  ]);

  const manageChange = (e: any, from: any) => {
    switch (from) {
      case "adminHome":
        dispatch(setAdminPageSearchString(e?.target?.value));
        break;
      case "evaluation":
        dispatch(setEvaluationSearchString(e?.target?.value));
        break;
      case "evaluationDashboard":
        dispatch(setEvaluationDashboardSearchString(e?.target?.value));
        break;
    }
  };

  return (
    <React.Fragment>
      <Box>
        <Search className={searchBoxStyle} sx={{ minWidth: 320 }}>
          <SearchIconWrapper>
            <SearchIcon style={{ width: 20, color: colors.grey.dark }} />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder={placeholder ? placeholder : "Search"}
            inputProps={{ "aria-label": "search" }}
            value={searchString}
            onChange={(e: any) => {
              manageChange(e, parent);
            }}
          />
        </Search>
        {/* <Paper sx={{ position: 'absolute', mt: 1, py: 1, minWidth: 306 }}>
                <Typography className='searchDropBox'>jhhb</Typography>
              </Paper> */}
      </Box>
    </React.Fragment>
  );
};

export default SearchBox;

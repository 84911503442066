import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TimelineTable from "./TimelineTable";
import DatesTable from "./DatesTable";
import SearchFilterContainer from "../../../containers/SearchFilterContainer";
import { Box } from "@mui/material";
import "./styles.css";
import { SwitchButton } from "../../../components/SwitchButton";
import { setIsActions } from "../../../redux/reducers/actionPlan";
import {
  getClubDatesDetails,
  getClubTimelineDetails,
} from "../../../api/evaluationDashboard";
import {
  setClubsDates,
  setClubsTimeline,
} from "../../../redux/reducers/evaluationDashboard";

export default function DatesPage() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const isActions = useSelector((state: any) => state.actionPlan.isActions);
  const timelineMonth = useSelector(
    (state: any) => state.evaluationDashboard.timelineMonth
  );
  const othersOptions = [
    { id: 1, name: "SET", value: "set" },
    { id: 2, name: "NOT_SET", value: "notset" },
    { id: 3, name: "UNDEFINED", value: "undefined" },
    { id: 4, name: "UNASSIGNED", value: "unassigned" },
    { id: 5, name: "FAVOURITES", value: "favourites" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setClubsDates(await getClubDatesDetails()));
      dispatch(setClubsTimeline(await getClubTimelineDetails(timelineMonth)));
    };
    console.log(timelineMonth);
    fetchData();
  }, [timelineMonth]);

  return (
    <>
      <Box className="DatesHeader">
        <SearchFilterContainer
          parent={"evaluationDashboard"}
          othersOptions={othersOptions}
        />
        <div className="SwitchButtonToggle">
          <SwitchButton
            label={t("DATES")}
            label01={t("TIMELINE")}
            isOn={isActions}
            handleOnClick={() => {
              const changeBool: any = !isActions;
              dispatch(setIsActions(changeBool));
            }}
          />
        </div>
      </Box>
      <br />
      {!isActions ? <DatesTable /> : <TimelineTable />}
    </>
  );
}

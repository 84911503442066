import { Box, ClickAwayListener, Fade, Paper, Popper, Typography } from "@mui/material";
import React, { useState } from "react";
import "./styles.css";
import OwnerSelectBox from "../../components/OwnerSelectBox";
import DepartmentSelectBox from "../../components/DepartmentSelectBox";
import PerfActionDateSelector from "../../components/PerfActionDateSelector";
import { t } from "i18next";
import TextFieldDefault from "../../components/TextFieldDefault";
import StatusSelectBoxPNAD from "../../components/StatusSelectBoxPNAD";
import { TextAreaPerformanceAction } from "../../components/TextAreaPerformanceAction";
import { useDispatch, useSelector } from "react-redux";
import { ActionTextBoxDefault } from "../../components/ActionTextBoxDefault";
import YearFromTo from "../../components/YearFromTo";
import PrivacySelector from "../../components/PrivacySelector";
import KPIsTable from "../../components/KPIsTable";
import { createGoal } from "../../api/goals";
import StaffMultiSelectCheckBox from "../../components/StaffMultiSelectCheckBox";
import { setGoals } from "../../redux/reducers/goalsAndStrategy";

const NewAcademyGoalPopper = (props:any) => {
    const { isOpen, handleClickAway } = props;
    const [newGoalData, setNewGoalData]:any = useState({
        "name": null,
        "description": null,
        "ownerId": null,
        "departmentId": null,
        "status": null,
        "objectives": [],
        "members": [],
        "parentGoalId": null,
        "yearFrom": null,
        "yearTo": null,
        "privacy": 0,
        "dueDate": null,
        "kpis": [],
    });
    const [isUpdatedData, setIsUpdatedData] = useState(false);
    const goals = useSelector((state:any) => state.goalsAndStrategy.goals);
    const dispatch = useDispatch();
    
    const handleClickInsidePopper = (event: MouseEvent) => {
        event.stopPropagation();
    }

    //handling kpis
    const handleChangeKpis = (label:any, updatedValue:any, index:any) => {
        var tempKpis = newGoalData?.kpis ? newGoalData?.kpis : [];
        switch(label) {
            case 'addKpi':
                tempKpis = [...tempKpis, updatedValue];
                break;
            default:
                tempKpis = tempKpis.map((item:any, i:any) => {
                    if (i == index) {
                        return { ...item, [label]: updatedValue };
                    } return item;
                });
                break;
        }
        const tempObj = {...newGoalData, kpis: tempKpis};
        setIsUpdatedData(true);
        setNewGoalData(tempObj);
    };
    //handling other changes
    const handleChangeState = (updatedData:any) => {
        var tempObj = { ...newGoalData, ...updatedData };
        setIsUpdatedData(true);
        setNewGoalData(tempObj);
    }

    const manageOnClickAway = async () => {
        if (isUpdatedData) {
            const members = newGoalData?.members;
            const memberIds = members?.length > 0 ? members?.map((d:any) => d?.id) : [];
            const response = await createGoal({goal: {...newGoalData, members: memberIds}});
            const tempActionArr:any = [response, ...goals, ];
            dispatch(setGoals(tempActionArr));
        }
        setNewGoalData({});
        handleClickAway();
    }

    return (
        <React.Fragment>
            <Popper className='popperStyle' open={isOpen} transition>
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={manageOnClickAway}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper sx={{ p: 2, width: '1200px', height: '90vh', overflowY: 'auto', margin: 'auto', mt: '4vh' }} onClick={(e:any) => {handleClickInsidePopper(e)}}>
                                <Box className={'NAGmainWrapper'}>
                                    <Typography className="fs14 fw700 maxWidth70p uppercase">{t('NEW_ACADEMY_GOAL')}</Typography>
                                    <Box className={'mt30'}>
                                        <Box sx={{ width: '200px', display: "inline-block" }}>
                                            <TextFieldDefault label={t('GOAL_ID')} isDisabled={true} />
                                        </Box>
                                        <Box sx={{ width: "970px", ml:"20px", display: "inline-block" }} onClick={(e:any) => {handleClickInsidePopper(e)}}>
                                            <ActionTextBoxDefault 
                                            label={t('GOAL_TITLE')}
                                            value={newGoalData?.name ? newGoalData?.name : ''} 
                                            handleChange={handleChangeState}/>
                                        </Box>
                                        <Box className={'mt30 NAGinnerWrapper'}>
                                            <TextAreaPerformanceAction
                                            value={newGoalData?.description}
                                            label={t('GOAL_DESCRIPTION')} 
                                            handleChange={handleChangeState}
                                            />
                                            <Box className={'NAGprivacyTimeWrapper'}>
                                                <PrivacySelector value={newGoalData?.privacy} manageSelect={handleChangeState} />
                                                <YearFromTo 
                                                fromDate={newGoalData.fromYear} 
                                                todate={newGoalData.toYear} 
                                                label={t('TIME_PERIOD')}
                                                manageSelect={handleChangeState} />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className={'NAGselectBoxWrapper'}>
                                        <StatusSelectBoxPNAD value={newGoalData?.status} manageSelect={handleChangeState} />
                                        <OwnerSelectBox value={newGoalData?.ownerUserId} manageSelect={handleChangeState} />
                                        <DepartmentSelectBox value={newGoalData?.departmentId} manageSelect={handleChangeState} />
                                        <PerfActionDateSelector value={newGoalData?.dueDate} manageSelect={handleChangeState} />
                                    </Box>
                                    <Box className={'mt30'}>
                                        <StaffMultiSelectCheckBox 
                                         selectedList={newGoalData?.members}
                                         manageChange={handleChangeState}
                                         manageOnFocus={(e:any) => {handleClickInsidePopper(e)}}
                                        />
                                    </Box>
                                    <Box className={'mt30'}>
                                        <Typography className="fs12 fw700">{t('KPIS')}</Typography>
                                        <KPIsTable 
                                        data={newGoalData?.kpis ? newGoalData?.kpis : []}
                                        isLoading={false} 
                                        handleChange={handleChangeKpis} />
                                    </Box>
                                </Box>
                            </Paper>
                        </Fade>
                    </ClickAwayListener>)}
            </Popper>
        </React.Fragment>
    )
}

export default NewAcademyGoalPopper;
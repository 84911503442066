import React, { startTransition, useEffect } from "react";
import "./styles.css";
//internal and external components/containers import
import { Box, Tabs, Tab, Typography, } from "@mui/material";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import SearchFilterContainer from "../../containers/SearchFilterContainer";
import { setCurrentTab } from "../../redux/reducers/actionPlan";
import PerformancePlanTabs from "../../containers/PerformancePlanTabs";

const PerformancePlan = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [t] = useTranslation();

    startTransition(() => {      
      var tab:any = "";
      switch(location.pathname) {
        case '/performanceplan':
          tab = t("ACTION_PLAN");
          dispatch(setCurrentTab(tab));
          break;
        case '/strategy':
          tab = t("STRATEGY");
          dispatch(setCurrentTab(tab));
          break;
      } 
    });

    return(
        <React.Fragment>
            <Box className={'contentSpacing'}>
                <Box className='headerWrapper'>
                  <SearchFilterContainer parent={'Performance Plan'} />
                </Box>
                <PerformancePlanTabs />
            </Box>
        </React.Fragment>
    )
}

export default PerformancePlan;
import React, { useState, useEffect, useRef } from "react";
import {
  Checkbox,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import { colors } from "../../common/pallette/colors";
import { AvatarAndName } from "../AvatarAndName";
import { baseUrl } from "../../api/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import './styles.css';

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          border: "none !important", 
          "&:before, &:after": {
            display: "none", 
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "12px",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "0px 9px",
        },
      },
    },
  },
});

export default function AvatarMultiSelectCheckBoxWithoutBorders(props: any) {
  const { label, options, selectedValues, setSelectedValues, manageOnFocus } =
    props;
  const selectRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.focus();
    }
  }, [selectRef]);

  const handleChange = (selectedObj: any) => {
    setSelectedValues(selectedObj);
  };

  return (
    <ThemeProvider theme={theme}>
      <FormControl fullWidth variant="standard">
        <InputLabel sx={{ color: colors.black.dark }} className="fs12">{
        selectedValues?.length == 0 && label
        }</InputLabel>
        <Select
          multiple
          fullWidth
          autoFocus
          variant="standard" 
          value={selectedValues || []}
          inputRef={selectRef}
          renderValue={(selected) =>
            <Box className={'membersWrapperSelect'}>
              { 
                selected.map((opt: any) => {
                  return(
                    <AvatarAndName avatarName={opt?.displayName} avatarImage={opt?.avatarPath}/>
                  )
                })
              }
            </Box>
          }
          MenuProps={{
            disablePortal: true,
            disableAutoFocusItem: true,
            PaperProps: {
              onMouseDown: (e: any) => e.stopPropagation(),
            },
          }}
          onMouseDown={(e) => {
            e.stopPropagation();
            manageOnFocus(e);
          }}
          onClick={(e) => {
            e.stopPropagation();
            manageOnFocus(e);
          }}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          IconComponent={() => {
            return(
              isOpen ?
              <FontAwesomeIcon color={colors.black.dark} width={'12px'} icon={faChevronUp} /> :
              <FontAwesomeIcon color={colors.black.dark} width={'12px'} icon={faChevronDown} />
            )
          }}
        >
          {options?.map((option: any) => (
            <MenuItem
              key={option.id}
              value={option}
              onClick={(e) => {
                handleChange(option);
                e.stopPropagation();
                manageOnFocus(e);
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
                manageOnFocus(e);
              }}
            >
              <Checkbox
                sx={{
                  transform: "scale(0.8)", 
                  padding: "0px",
                }}
                checked={selectedValues?.some(
                  (item: any) => item.id === option.id
                )}
              />
              <AvatarAndName
                avatarName={option.displayName || ""}
                avatarImage={`${baseUrl}/Content${option.avatarPath}`}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
}

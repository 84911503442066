import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SelectBoxDefault from "../SearchSelectBox/SelectBoxDefault";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { frequencyStatus } from "../../common/StaticExports";

const FrequencySelectBox = (props:any) => {
    const { value, manageSelect } = props;
    const [inputValue, setInputValue] = React.useState((value != undefined && value != null) ? (frequencyStatus[value-1]?.statusName || '') : '');
    const [optionList, setOptionList] = useState(frequencyStatus || []);
    const [t] = useTranslation();

    const manageHandleChange = (_event: any, newValue: any) => {
      let tempObj:any = Object;
      
      if (newValue == null || newValue == undefined) {
        tempObj = {frequency: null};
      } else {
        const fStatus = frequencyStatus.find((item:any) => item.statusName == newValue.statusName);
        tempObj = {frequency: fStatus?.dbIndex};
      }
      manageSelect(tempObj);
    };
  
    const manageHandleInputChange = (_event: any, newInputValue: string) => {
      (newInputValue != undefined || newInputValue != 'undefined') &&
      setInputValue(newInputValue);
      // setOptionList(rabgStatus);
    };

    useEffect(() => {
      if(inputValue == null || inputValue == '') {
        setInputValue(frequencyStatus[value]?.statusName);
      }
    }, [value]);

    return (
      <Box sx={{ width: '120px', ml: '5px' }}>
        <SelectBoxDefault
        options={optionList}
        keyLabel={'statusName'}
        handleChange={manageHandleChange}
        variant={'standard'}
        selectedValue={value}
        handleInputChange={manageHandleInputChange}
        inputValue={inputValue}
        />
      </Box>
    )
}

export default FrequencySelectBox;
import React, { useEffect, useState } from "react";
import {
  Box,
  InputLabel,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { colors } from "../../common/pallette/colors";

const YearRangeSelector = (props: any) => {
  const { label, manageFromYear, manageToYear, fromYearDate, toYearDate, textColor, variant } = props;
  const [fromYear, setFromYear] = useState(fromYearDate);
  const [toYear, setToYear] = useState(toYearDate);
  const [isFromOpen, setIsFromOpen] = useState(false);
  const [isToOpen, setIsToOpen] = useState(false);

  const theme = createTheme({
    components: {
      MuiInput: {
        styleOverrides: {
          input: {
            height: "auto",
            fontSize: "12px",
            color: textColor,
          },
          root: {
            "&::before": {
              borderBottom: "none",
            },
            "&::after": {
              borderBottom: "none",
            },
            "&:hover:not(.Mui-disabled, .Mui-error)::before": {
              borderBottom: "none",
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderBottom: "none",
            "&:hover:not(.Mui-disabled, .Mui-error)::before": {
              borderBottom: "none",
            },
          },
          input: {
            padding: 0,
          }
        },
      },
      MuiStack: {
        styleOverrides: {
          root: {
            paddingTop: "0px !important",
          },
        },
      },
    },
  });

  useEffect(() => {
    if (fromYear && toYear) {
      const formattedFromYear = fromYear;
      const formattedToYear = toYear; // Last two digits of the year
      const range = `${formattedFromYear} - ${formattedToYear}`;
    //   manageChange(range); // Pass the formatted range to the parent
    }
  }, [fromYear, toYear]);

  const handleFromYearChange = (value:any) => {
    setFromYear(value);
    // if (value && dayjs(value).isValid()) {
    //   setFromYear(dayjs(value));
    // } else {
    //   setFromYear(null);
    // }
  };

  const handleToYearChange = (value:any) => {
    setToYear(value);
    // if (value && dayjs(value).isValid()) {
    //   setToYear(dayjs(value));
    // } else {
    //   setToYear(null);
    // }
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ width: "100%" }}>
          {(label && label !== "") && (
            <InputLabel className="selectBoxLabel">{label}</InputLabel>
          )}
          <Box sx={{ display: "flex" }}>
            {/* From Year Picker */}
            <DatePicker
              open={isFromOpen}
              views={["year"]} // Restrict to year selection only
              onChange={(v:any) => {
                if (!v || v.isValid()) {
                  setFromYear(v ? v.format("YYYY") : null);
                  manageFromYear(new Date(v));
                }
              }}
              value={fromYear ? dayjs(fromYear) : null} // Ensure value is a valid dayjs object or null
              onOpen={() => setIsFromOpen(true)}
              onClose={() => setIsFromOpen(false)}
              slotProps={{
                textField: {
                  variant: variant ? variant : "outlined",
                  size: "small",
                  fullWidth: true,
                  onKeyDown: (e) => {
                    if (e.key === "Backspace") {
                      setFromYear(null);
                      e.preventDefault();
                    }
                  },
                  onClick: () => setIsFromOpen(!isFromOpen),
                  placeholder: "From",
                  InputProps: {
                    endAdornment: null,
                  },
                },
              }}
            />
            <Typography sx={{ position: "relative", left: '-8px', top: '-2px' }}>-</Typography>
            {/* To Year Picker */}
            <DatePicker
              open={isToOpen}
              views={["year"]} // Restrict to year selection only
              onChange={(v:any) => {
                if (!v || v.isValid()) {
                  setToYear(v ? v.format("YY") : null);
                  manageToYear(new Date(v));
                }
              }}
              value={toYear ? dayjs(toYear) : null} // Ensure value is a valid dayjs object or null
              onOpen={() => setIsToOpen(true)}
              onClose={() => setIsToOpen(false)}
              slotProps={{
                textField: {
                  variant: variant ? variant : "outlined",
                  size: "small",
                  fullWidth: true,
                  onKeyDown: (e) => {
                    if (e.key === "Backspace") {
                      setToYear(null);
                      e.preventDefault();
                    }
                  },
                  onClick: () => setIsToOpen(!isToOpen),
                  placeholder: "To",
                  InputProps: {
                    endAdornment: null,
                  },
                },
              }}
            />
          </Box>
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default YearRangeSelector;
import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DateSelectorDefault from "../DateSelectorDefault";

const PerfActionDateSelector = (props:any) => {
    const { value, manageSelect, dataLabel, label } = props;
    const [t] = useTranslation();
    const handleChange = (newValue:any) => {
      var tempObj:any = Object;
      if(newValue == null || newValue == undefined) {
        tempObj = dataLabel ? { [dataLabel]: null } : { dueDate: null };
      } else {
        tempObj = dataLabel ? { [dataLabel]: new Date(newValue).toISOString() } : { dueDate: new Date(newValue).toISOString() };
      }
      manageSelect(tempObj);
    }

    return (
        <Box sx={{ display: "flex", ml: "auto"}}>
          <Typography className="fs12 fw700 mtbAuto">{label ? label : t('DUE_DATE')}</Typography>
          <Box sx={{ width: '90px', ml: '5px' }} className={'mtbAuto'}>
            <DateSelectorDefault 
            dateValue={value}
            manageChange={handleChange}
            variant={'standard'}
            />
          </Box>
        </Box>
    );
};

export default PerfActionDateSelector;
import React, { useEffect, useState } from 'react';
import MultiSelectCheckBox from '../../MultiSelectCheckBox';
import { useDispatch, useSelector } from 'react-redux';
import { resetClubNameFilter, resetLeagueFilter, resetStarRatingFilter, resetTargetStarRatingFilter, setClubNameFilter, setLeagueFilter, setLeagues, setStarRatingFilter, setTargetStarRatingFilter } from '../../../redux/reducers/common';
import { getLeagues } from '../../../api/common';
import AutoComplete from '../../AutoComplete';
import { Box } from '@mui/material';

const styles: any = {
  root: {
    display: 'flex',
    gap: '10px',
    marginLeft: '5px',
    justifyContent: 'space-between',
    paddingTop: '5px',
    marginRight: '25px',
  },
};

const FiltersWrapper: React.FC = () => {
  
  const selectedClubs = useSelector((state:any) => state.common.clubNameFilter);
  const clubData = useSelector((state:any) => state.common.myClubs);
  
  const selectedLeagues = useSelector((state:any) => state.common.leagueFilter);
  const leagueData = useSelector((state:any) => state.common.leagues);

  const selectedStars = useSelector((state:any) => state.common.starRatingFilter);
  const selectedTargetStars = useSelector((state:any) => state.common.targetStarRatingFilter);

  const [clubNameOptions, setClubNameOptions] = useState(clubData);
  const [leagueOptions, setLeagueOptions] = useState(leagueData);
  const starsOptions = [
    { id: 1, name: '1 Star' },
    { id: 2, name: '2 Star' },
    { id: 3, name: '3 Star' },
    { id: 4, name: '4 Star' },
  ];
  const [starRatingOptions, setStarRatingOptions] = useState(starsOptions);
  const [targetRatingOptions, setTargetRatingOptions] = useState(starsOptions);

  const dispatch = useDispatch();

  useEffect(() => {
    if(true) {
      
      const leagueIds = selectedLeagues?.map((d:any) => d.id);
      const starsIds = selectedStars?.map((d:any) => d.id);
      const targetStarIds = selectedTargetStars?.map((d:any) => d.id);

      const tempClubs = clubData?.filter((d:any) => (leagueIds.includes(d.leagueId) || leagueIds.length == 0) && 
      (starsIds.includes(d.currentRating) || starsIds.length ==0) && 
      (targetStarIds.includes(d.targetRating) || targetStarIds.length == 0));
      setClubNameOptions(tempClubs);

      const clubLeagueIds = selectedClubs?.map((d:any) => d.leagueId);
      setLeagueOptions(leagueData?.filter((d:any) =>  (clubLeagueIds.includes(d.id) || clubLeagueIds.length == 0)));
      
      const clubStarIds = selectedClubs?.map((d:any) => d.currentRating);
      setStarRatingOptions(starsOptions?.filter((d:any) =>  (clubStarIds.includes(d.id) || clubStarIds.length == 0)));
      
      const clubTargetStarIds = selectedClubs?.map((d:any) => d.targetRating);
      setTargetRatingOptions(starsOptions?.filter((d:any) =>  (clubTargetStarIds.includes(d.id) || clubTargetStarIds.length == 0)));
      
    }
  }, [selectedClubs, selectedLeagues, selectedStars, selectedTargetStars]);

  useEffect(() => {
    const fetchLeagues = async () => {
      const respone = await getLeagues();
      return respone;
    }
    fetchLeagues()
    .then((res) => {
      dispatch(setLeagues(res));
      setLeagueOptions(res);
    });
  }, [])

  const manageClubNameSelect = (club:any) => {
    const index = selectedClubs.findIndex((obj:any) => obj.id == club.id);
    let tempArr:any = [...selectedClubs];
      if (index !== -1) {
        tempArr.splice(index, 1);
    } else {
      tempArr.push(club);
    }
    dispatch(setClubNameFilter(tempArr));
  }
  
  const manageLeagueSelect = (league:any) => {
    const index = selectedLeagues.findIndex((obj:any) => obj.id == league.id);
    let tempArr:any = [...selectedLeagues];
      if (index !== -1) {
        tempArr.splice(index, 1);
    } else {
      tempArr.push(league);
    }
    dispatch(setLeagueFilter(tempArr));
  }

  const manageStarRatingSelect = (star:any) => {
    const index = selectedStars.findIndex((obj:any) => obj.id == star.id);
    let tempArr:any = [...selectedStars];
      if (index !== -1) {
        tempArr.splice(index, 1);
    } else {
      tempArr.push(star);
    }
    dispatch(setStarRatingFilter(tempArr));
  }
  
  const manageTargetStarRatingSelect = (star:any) => {
    const index = selectedTargetStars.findIndex((obj:any) => obj.id == star.id);
    let tempArr:any = [...selectedTargetStars];
      if (index !== -1) {
        tempArr.splice(index, 1);
    } else {
      tempArr.push(star);
    }
    dispatch(setTargetStarRatingFilter(tempArr));
  }

  return (
    <div className="filtersWrapperRoot" style={styles.root}>
      {/* club name filter */}
      <Box sx={{ width: '200px' }}>
        <AutoComplete 
        options={clubNameOptions}
        selectedValues={selectedClubs}
        handleMenuClick={(d:any) => {manageClubNameSelect(d)}} 
        label={'Club Name'}
        clickReset={() => {dispatch(resetClubNameFilter())}}
        isMultiple={true}
        />
      </Box>
      {/* league filter */}
      <Box sx={{ width: '200px' }}>
        <AutoComplete 
        options={leagueOptions}
        selectedValues={selectedLeagues}
        handleMenuClick={(d:any) => {manageLeagueSelect(d)}} 
        label={'League'}
        clickReset={() => {dispatch(resetLeagueFilter())}}
        isMultiple={true}
        />
      </Box>
      {/* star rating filter */}
      <Box sx={{ width: '200px' }}>
        <AutoComplete 
        options={starRatingOptions}
        selectedValues={selectedStars}
        handleMenuClick={(d:any) => {manageStarRatingSelect(d)}} 
        label={'Star Rating'}
        clickReset={() => {dispatch(resetStarRatingFilter())}}
        isMultiple={true}
        />
      </Box>
      {/* target star rating filter */}
      <Box sx={{ width: '200px' }}>
        <AutoComplete 
        options={targetRatingOptions}
        selectedValues={selectedTargetStars}
        handleMenuClick={(d:any) => {manageTargetStarRatingSelect(d)}} 
        label={'Target Rating'}
        clickReset={() => {dispatch(resetTargetStarRatingFilter())}}
        isMultiple={true}
        />
      </Box>
    </div>
  );
};

export default FiltersWrapper;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SelectBoxDefault from "../SearchSelectBox/SelectBoxDefault";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getStaff } from "../../api/user";

const OwnerSelectBox = (props:any) => {
    const { value, manageSelect, dataLabel } = props;
    const [inputValue, setInputValue] = React.useState('');
    const [optionList, setOptionList] = useState([]);
    const [t] = useTranslation();
    const clubDetails = useSelector((state:any) => state.clubDetails);

    const manageHandleChange = (_event: any, newValue: any) => {
      let tempObj:any = Object;
      
      if (newValue == null || newValue == undefined) {
        tempObj = dataLabel ? {ownerName: null, [dataLabel]: null} : {ownerName: null, ownerUserId: null};
      } else {
        tempObj = dataLabel ? {ownerName: newValue.displayName, [dataLabel]: newValue.id, departmentId: newValue.departmentId} : {ownerName: newValue.displayName, ownerUserId: newValue.id, departmentId: newValue.departmentId};
      }
      manageSelect(tempObj);
    };
  
    const manageHandleInputChange = (_event: any, newInputValue: string) => {
      (newInputValue != undefined || newInputValue != 'undefined') &&
      setInputValue(newInputValue);
      // setOptionList(optionList);
    };

    const fetchStaffList = async () => {
      const response = await getStaff({ clubId: clubDetails?.id });
      setOptionList(response);
      const selectedObj = response?.find((item:any) => item.id == value);
      setInputValue(selectedObj ? (selectedObj?.displayName || '') : '');
    }
    useEffect(() => {
      clubDetails && clubDetails?.id &&
      fetchStaffList();
    }, [value]);

    return (
      <Box sx={{ display: "flex", ml: "auto"}}>
        <Typography className="fs12 fw700 mtbAuto">{t('OWNER')}</Typography>
        <Box sx={{ width: '120px', ml: '5px' }}className={'mtbAuto'}>
          <SelectBoxDefault
          options={optionList}
          keyLabel={'displayName'}
          handleChange={manageHandleChange}
          variant={'standard'}
          selectedValue={value}
          handleInputChange={manageHandleInputChange}
          inputValue={inputValue}
          />
        </Box>
      </Box>
    )
}

export default OwnerSelectBox;
import React from "react";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { visuallyHidden } from "@mui/utils";
import { createTheme, ThemeProvider } from "@mui/material";

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: any;
  orderBy: string;
  tableHeads: any;
  bg?: string;
}

export function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, tableHeads, bg } = props;
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            zIndex: 0,
          },
        },
      },
    },
  });

  return (
    <TableHead>
      <ThemeProvider theme={theme}>
        <TableRow>
          {tableHeads.map((head: { key: string; displayName: String }) => {
            return (
              <TableCell
                className="uppercase"
                sx={
                  bg
                    ? {
                        fontSize: 12,
                        backgroundColor: bg,
                        zIndex: 1,
                      }
                    : {
                        fontSize: 12,
                        fontWeight: "bold",
                        fontFamily: "Montserrat",
                      }
                }
                sortDirection={orderBy == head.key ? order : false}
              >
                {head.key !== "" ? (
                  <TableSortLabel
                    // sx={{ fontSize: 12 }}
                    active={orderBy == head.key}
                    direction={orderBy == head.key ? order : "asc"}
                    onClick={createSortHandler(head.key)}
                    IconComponent={() => (
                      <FontAwesomeIcon
                        className="icon ml5"
                        icon={
                          order == "" || orderBy !== head.key
                            ? faSort
                            : order === "asc"
                            ? faSortUp
                            : faSortDown
                        }
                      />
                    )}
                  >
                    {head.displayName}
                    {orderBy === head.key ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  head.displayName
                )}
              </TableCell>
            );
          })}
        </TableRow>
      </ThemeProvider>
    </TableHead>
  );
}

import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import StarRateIcon from "@mui/icons-material/StarRate";
import { EnhancedTableHead } from "../../../components/EnhancedTableHead";
import TableSkeleton from "../../../components/TableSkeleton";
import { TableRow } from "@mui/material";
import { useSelector } from "react-redux";
import { filteredList } from "../Utils";
import { sortData } from "../../../common/SortSupport";

export default function PeopleTable() {
  const [t] = useTranslation();
  const [order, setOrder] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");
  const currentTab: any = useSelector(
      (state: any) => state.evaluationDashboard.currentTab
    );
  const clubsPeople: any = useSelector(
    (state: any) => state.evaluationDashboard.clubsPeople
  );
  const searchString: any = useSelector(
    (state: any) => state.evaluationDashboard.searchString
  );
  const targetStarRatingFilter: any = useSelector(
    (state: any) => state.evaluationDashboard.targetStarRatingFilter
  );
  const leagueFilter: any = useSelector(
    (state: any) => state.evaluationDashboard.leagueFilter
  );
  const otherFilter: any = useSelector(
    (state: any) => state.evaluationDashboard.otherFilter
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const Head = [
    {
      key: "name",
      displayName: t("Club").toUpperCase(),
    },    
    {
      key: "",
      displayName: t("CYCLE").toUpperCase(),
    },
    {
      key: "",
      displayName: t("LEAGUE").toUpperCase(),
    },
    {
      key: "",
      displayName: t("TARGET").toUpperCase(),
    },
    {
      key: "",
      displayName: t("ASM").toUpperCase(),
    },
    {
      key: "",
      displayName: t("EVALUATION_TEAM").toUpperCase(),
    },
    {
      key: "",
      displayName: t("TRANSLATOR").toUpperCase(),
    },
    {
      key: "",
      displayName: t('DC_1_ACADEMY_DIRECTOR').toUpperCase(),
    },
  ];

  const createStars = (count: number) => {
    let stars: JSX.Element[] = [];
    if (!count) {
      return undefined;
    }
    for (let i = 0; i < count; i++) {
      stars.push(
        <StarRateIcon
          fontSize="small"
          style={{ color: "gold", fontSize: "14px" }}
        />
      );
    }
    return stars;
  };

  // Memoize the filtered club dates based on searchString and clubsPeople
  const filteredClubPeople = useMemo(() => {
    console.log("Filtering club people based on searchString...");
    return clubsPeople?.filter((row: any) => {
      const searchTerm = searchString.toLowerCase();
      return (
        row.name?.toLowerCase().includes(searchTerm) ||
        row.league?.name?.toString().toLowerCase().includes(searchTerm) ||
        row.asm?.toString().toLowerCase().includes(searchTerm) ||
        row.evaluationTeam?.toString().toLowerCase().includes(searchTerm) ||
        row.translator?.toString().toLowerCase().includes(searchTerm) ||
        row.academyDirector?.toString().toLowerCase().includes(searchTerm)
      );
    });
  }, [clubsPeople, searchString]); // Recompute only when clubsDates or searchString changes

  // Memoize the final filtered and sorted data based on other filters and sorting order
  const filteredAndSortedData = useMemo(() => {
    console.log("Applying additional filters...");
    if (filteredClubPeople) {
      let filteredData = filteredClubPeople;

      // Apply all filters
      if (
        otherFilter.length > 0 ||
        leagueFilter.length > 0 ||
        targetStarRatingFilter.length > 0
      ) {
        filteredData = filteredList(
          otherFilter,
          leagueFilter,
          targetStarRatingFilter,
          filteredData,
          currentTab
        );
      }

      return filteredData;
    }

    return [];
  }, [filteredClubPeople, otherFilter, leagueFilter, targetStarRatingFilter, currentTab]);

  // Memoizing visible rows (sorted data)
  const visibleRows = useMemo(() => {
    console.log("Sorting the filtered data...");
    return filteredAndSortedData.length > 0
      ? sortData(filteredAndSortedData, orderBy, order)
      : [];
  }, [filteredAndSortedData, order, orderBy]);

  return (
    <>
      <Box sx={{ widt: "100%" }}>
        <TableContainer sx={{ maxHeight: "100vh", overflowY: "auto" }}>
          <Table
            stickyHeader
            sx={{ boxShadow: "none" }}
            size="small"
            aria-label="a dense table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              tableHeads={Head}
            />
            <TableBody>
              {visibleRows?.length > 0 ? (
                visibleRows.map((row: any, index: number) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:hover": {
                        backgroundColor: "#f5f5f5", // Light gray background on hover
                        cursor: "pointer", // Change cursor to pointer to indicate clickability
                      },
                    }}
                  >
                    <TableCell>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <img
                          src={`https://jamp.football/Content${row.imagePath}`}
                          alt={row.name}
                          width="30"
                          height="30"
                        />
                        <span>{row.name}</span>
                      </Box>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ fontSize: 12, fontFamily: "Montserrat" }}
                    >
                      {row?.cycle}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ fontSize: 12, fontFamily: "Montserrat" }}
                    >
                      {row?.league?.name}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ fontSize: 12, fontFamily: "Montserrat" }}
                    >
                      {createStars(row?.targetRating)|| "-"}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ fontSize: 12, fontFamily: "Montserrat" }}
                    >
                      {row?.asm|| "-"}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ fontSize: 12, fontFamily: "Montserrat" }}
                    >
                      {row?.evaluationTeam|| "-"}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ fontSize: 12, fontFamily: "Montserrat" }}
                    >
                      {row?.translator|| "-"}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ fontSize: 12, fontFamily: "Montserrat" }}
                    >
                      {row?.academyDirector || "-" }
                    </TableCell>
                  </TableRow>
                ))
              ) : clubsPeople.length > 0 ? (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      fontSize: 12,
                      fontFamily: "Montserrat",
                    }}
                    colSpan={8}
                  >
                    {t("NO_RECORDS_FOUND")}...
                  </TableCell>
                </TableRow>
              ) : (
                <TableSkeleton rowCount={10} cellCount={8} />
              )}
              <TableRow></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

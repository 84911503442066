import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLeagues } from "../../../redux/reducers/common";
import { getLeagues } from "../../../api/common";
import AutoComplete from "../../AutoComplete";
import { Box } from "@mui/material";
import {
  resetLeagueFilter,
  resetOtherFilter,
  resetTargetStarRatingFilter,
  setLeagueFilter,
  setOtherFilter,
  setTargetStarRatingFilter,
} from "../../../redux/reducers/evaluationDashboard";

const styles: any = {
  root: {
    display: "flex",
    gap: "10px",
    marginLeft: "5px",
    justifyContent: "space-between",
    paddingTop: "5px",
    marginRight: "25px",
  },
};

const FiltersWrapper = (props: any) => {
  const { filterOptions } = props;
  const selectedLeagues = useSelector(
    (state: any) => state.evaluationDashboard.leagueFilter
  );
  const selectedTargetStars = useSelector(
    (state: any) => state.evaluationDashboard.targetStarRatingFilter
  );
  const selectedOther = useSelector(
    (state: any) => state.evaluationDashboard.otherFilter
  );

  const leagueData = useSelector((state: any) => state.common.leagues);
  const [leagueOptions, setLeagueOptions] = useState(leagueData);
  const starsOptions = [
    { id: 1, name: "1 Star", value: 1 },
    { id: 2, name: "2 Star", value: 2 },
    { id: 3, name: "3 Star", value: 3 },
    { id: 4, name: "4 Star", value: 4 },
  ];
  const [targetRatingOptions, setTargetRatingOptions] = useState(starsOptions);
  const [otherOptions, setOtherOptions] = useState(filterOptions);

  const dispatch = useDispatch();

  useEffect(() => {
    if (leagueData && leagueData.length == 0) {
      const fetchLeagues = async () => {
        const respone = await getLeagues();
        return respone;
      };
      fetchLeagues().then((res) => {
        dispatch(setLeagues(res));
        setLeagueOptions(res);
      });
    }
  }, []);

  const manageLeagueSelect = (league: any) => {
    const index = selectedLeagues.findIndex((obj: any) => obj.id == league.id);
    let tempArr: any = [...selectedLeagues];
    if (index !== -1) {
      tempArr.splice(index, 1);
    } else {
      tempArr.push(league);
    }
    dispatch(setLeagueFilter(tempArr));
  };

  const manageTargetStarRatingSelect = (star: any) => {
    const index = selectedTargetStars.findIndex(
      (obj: any) => obj.id == star.id
    );
    let tempArr: any = [...selectedTargetStars];
    if (index !== -1) {
      tempArr.splice(index, 1);
    } else {
      tempArr.push(star);
    }
    dispatch(setTargetStarRatingFilter(tempArr));
  };

  const manageOtherSelect = (star: any) => {
    const index = selectedOther.findIndex((obj: any) => obj.id == star.id);
    let tempArr: any = [...selectedOther];
    if (index !== -1) {
      tempArr.splice(index, 1);
    } else {
      tempArr.push(star);
    }
    dispatch(setOtherFilter(tempArr));
  };

  return (
    <div className="filtersWrapperRoot" style={styles.root}>
      {/* league filter */}
      <Box sx={{ width: "200px" }}>
        <AutoComplete
          options={leagueOptions}
          selectedValues={selectedLeagues}
          handleMenuClick={(d: any) => {
            manageLeagueSelect(d);
          }}
          label={"League"}
          clickReset={() => {
            dispatch(resetLeagueFilter());
          }}
          isMultiple={true}
        />
      </Box>
      {/* target star rating filter */}
      <Box sx={{ width: "200px" }}>
        <AutoComplete
          options={targetRatingOptions}
          selectedValues={selectedTargetStars}
          handleMenuClick={(d: any) => {
            manageTargetStarRatingSelect(d);
          }}
          label={"Target Rating"}
          clickReset={() => {
            dispatch(resetTargetStarRatingFilter());
          }}
          isMultiple={true}
        />
      </Box>
      {/* other filter */}
      <Box sx={{ width: "200px" }}>
        <AutoComplete
          options={otherOptions}
          selectedValues={selectedOther}
          handleMenuClick={(d: any) => {
            manageOtherSelect(d);
          }}
          label={"Other"}
          clickReset={() => {
            dispatch(resetOtherFilter());
          }}
          isMultiple={true}
        />
      </Box>
    </div>
  );
};

export default FiltersWrapper;

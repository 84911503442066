import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { EnhancedTableHead } from "../../../components/EnhancedTableHead";
import TableSkeleton from "../../../components/TableSkeleton";
import { useSelector } from "react-redux";
import { TableRow, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../../../common/pallette/colors";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import ProgressBar from "../../../components/ProgressBar";
import { filteredList, formatDate, getStars } from "../Utils";
import { sortDataByClub } from "../../../common/SortSupport";

interface OverAllProgress {
  targetRating?: number;
  selfAssesment?: number;
  offsite?: number;
  onsite?: number;
  interimReport?: number;
  finalReport?: number;
  selfAssesment2?: number;
  // Add more fields as needed
}

export default function ProgressTable() {
  const [t] = useTranslation();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const currentTab: any = useSelector(
    (state: any) => state.evaluationDashboard.currentTab
  );
  const clubsProgress: any = useSelector(
    (state: any) => state.evaluationDashboard.clubsProgress
  ); // Store data
  const searchString: any = useSelector(
    (state: any) => state.evaluationDashboard.searchString
  );
  const targetStarRatingFilter: any = useSelector(
    (state: any) => state.evaluationDashboard.targetStarRatingFilter
  );
  const leagueFilter: any = useSelector(
    (state: any) => state.evaluationDashboard.leagueFilter
  );
  const otherFilter: any = useSelector(
    (state: any) => state.evaluationDashboard.otherFilter
  );

  // Get all keys from the OverAllProgress interface
  const overAllProgressKeys: (keyof OverAllProgress)[] = [
    "targetRating",
    "selfAssesment",
    "offsite",
    "onsite",
    "interimReport",
    "finalReport",
    "selfAssesment2",
  ];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const head = [
    {
      key: "name",
      displayName: t("Club").toUpperCase(),
    },
    {
      key: "",
      displayName: t("TARGET").toUpperCase(),
    },
    {
      key: "",
      displayName: t("SELF_ASSESSMENT").toUpperCase(),
    },
    {
      key: "",
      displayName: t("OFF-SITE").toUpperCase(),
    },
    {
      key: "",
      displayName: t("ON-SITE").toUpperCase(),
    },
    {
      key: "",
      displayName: t("INTERIM_REPORT").toUpperCase(),
    },
    {
      key: "",
      displayName: t("SELF_ASSESSMENT").toUpperCase() + " " + 2,
    },
    {
      key: "",
      displayName: t("FINAL_REPORT").toUpperCase(),
    },
    {
      key: "",
      displayName: "",
    },
  ];

  /**
   * Calculate and render a self-assessment based on the count, id, and sfDate.
   *
   * @param {number} count - The assessment count.
   * @param {string} id - The assessment ID.
   * @param {string} sfDate - The assessment date.
   * @returns {JSX.Element} - A JSX element representing the self-assessment.
   */
  const calculateSelfAssesment = (count: number, sfDate: any) => {
    // Check if the count is 0
    if (count === 0) {
      // Return a red dot icon
      return (
        <FiberManualRecordIcon
          fontSize="small"
          style={{ width: 10, color: "red" }}
        />
      );
    }
    // Check if the count is 100
    else if (count === 100) {
      // Return a tick icon
      return (
        <FontAwesomeIcon icon={faCircleCheck} color={colors.green.bright} />
      );
    }
    // If none of the above conditions match, render a ProgressLine with a tooltip
    else {
      return (
        <Tooltip title={formatDate(sfDate)}>
          <ProgressBar percent={count} />
        </Tooltip>
      );
    }
  };

  /**
   * Display an icon or progress bar based on the result and date.
   *
   * @param {number} result - The result value.
   * @param {string} date - The date string.
   * @returns {JSX.Element} - A JSX element representing the icon or progress bar.
   */
  const displayIconForValues = (result: number, date: string) => {
    if (result === 0) {
      if (date) {
        // Parse the selfAssessment1 date string into a Date object
        const inputDate = new Date(date);
        // Get the current date
        const currentDate = new Date();
        // Check if selfAssessment1 is overdue
        const isOverdue = currentDate > inputDate;

        if (isOverdue) {
          return (
            <FiberManualRecordIcon
              fontSize="small"
              style={{ width: 10, color: "red" }}
            />
          );
        } else {
          return "-";
        }
      } else {
        return (
          <FiberManualRecordIcon
            fontSize="small"
            style={{ width: 10, color: "red" }}
          />
        );
      }
    } else if (result === 100) {
      return (
        <FontAwesomeIcon icon={faCircleCheck} color={colors.green.bright} />
      );
    } else {
      return (
        <Tooltip title={formatDate(date)}>
          <ProgressBar percent={result} />
        </Tooltip>
      );
    }
  };

  // Memoizing the filtered clubs progress based on searchString
  const filteredClubsProgress = useMemo(() => {
    return clubsProgress?.filter((row: any) => {
      const searchTerm = searchString.toLowerCase();
      return (
        row.club.name.toLowerCase().includes(searchTerm) || // club name
        row.targetRating?.toString().toLowerCase().includes(searchTerm) || // targetRating
        row.selfAssesment?.toString().toLowerCase().includes(searchTerm) || // selfAssesment
        row.offsite?.toString().toLowerCase().includes(searchTerm) || // offsite
        row.onsite?.toString().toLowerCase().includes(searchTerm) || // onsite
        row.interimReport?.toString().toLowerCase().includes(searchTerm) || // interimReport
        row.finalReport?.toString().toLowerCase().includes(searchTerm) || // finalReport
        row.selfAssesment2?.toString().toLowerCase().includes(searchTerm) // selfAssesment2
      );
    });
  }, [clubsProgress, searchString]); // Recompute when clubsProgress or searchString changes

  // Memoizing the filtered data based on other filters and star rating filters
  const filteredAndSortedData = useMemo(() => {
    let filteredData = filteredClubsProgress;

    // Apply other filters if any
    if (
      otherFilter.length > 0 ||
      leagueFilter.length > 0 ||
      targetStarRatingFilter.length > 0
    ) {
      filteredData = filteredList(
        otherFilter,
        leagueFilter,
        targetStarRatingFilter,
        filteredClubsProgress,
        currentTab
      );
    }

    // If no additional filters are applied, return the filtered data as is
    return filteredData;
  }, [
    filteredClubsProgress,
    otherFilter,
    leagueFilter,
    targetStarRatingFilter,
    currentTab,
  ]); // Recompute when any of these dependencies change

  /**
   * Calculate the overall progress based on the response data.
   *
   * @param {array} response - The response data.
   */
  // Memoize the overall progress calculation
  const overallProgress = useMemo(() => {
    const data: { [key: string]: number } = {};

    if (filteredAndSortedData?.length === 0) {
      return {}; // Early return if no data exists
    }

    // Calculate target rating progress
    const targetList = filteredAndSortedData.filter(
      (item: any) =>
        item.targetRating === undefined ||
        item.targetRating === 0 ||
        item.targetRating === null
    );
    const targetCompletion =
      ((filteredAndSortedData.length - targetList.length) /
        filteredAndSortedData.length) *
      100;
    data["targetRating"] = Math.ceil(targetCompletion);

    // Calculate self-assessment progress
    const selfAssesment = filteredAndSortedData.filter(
      (item: any) => item.selfAssesment === 100
    );
    const selfAssesmentCompletion =
      (selfAssesment.length / filteredAndSortedData.length) * 100;
    data["selfAssesment"] = Math.ceil(selfAssesmentCompletion);

    // Calculate offsite progress
    const offSite = filteredAndSortedData.filter(
      (item: any) => item.offsite === 100
    );
    const offSiteCompletion =
      (offSite.length / filteredAndSortedData.length) * 100;
    data["offsite"] = Math.ceil(offSiteCompletion);

    // Calculate onsite progress
    const onSite = filteredAndSortedData.filter(
      (item: any) => item.onsite === 100
    );
    const onSiteCompletion =
      (onSite.length / filteredAndSortedData.length) * 100;
    data["onsite"] = Math.ceil(onSiteCompletion);

    // Calculate interim report progress
    const interimReport = filteredAndSortedData.filter(
      (item: any) => item.interimReport === 100
    );
    const interimReportCompletion =
      (interimReport.length / filteredAndSortedData.length) * 100;
    data["interimReport"] = Math.ceil(interimReportCompletion);

    // Calculate final report progress
    const finalReport = filteredAndSortedData.filter(
      (item: any) => item.finalReport === 100
    );
    const finalReportCompletion =
      (finalReport.length / filteredAndSortedData.length) * 100;
    data["finalReport"] = Math.ceil(finalReportCompletion);

    // Calculate self-assessment 2 progress
    const selfAssesment2 = filteredAndSortedData.filter(
      (item: any) => item.selfAssesment2 === 100
    );
    const selfAssesment2Completion =
      (selfAssesment2.length / filteredAndSortedData.length) * 100;
    data["selfAssesment2"] = Math.ceil(selfAssesment2Completion);

    return data;
  }, [filteredAndSortedData]); // Recompute when filteredAndSortedData changes

  // Memoize the final sorted data for display (sorting after calculating progress)
  const visibleRows = useMemo(() => {
    return filteredAndSortedData && filteredAndSortedData.length > 0
      ? sortDataByClub(filteredAndSortedData, orderBy, order)
      : [];
  }, [filteredAndSortedData, orderBy, order]);

  return (
    <>
      <Box sx={{ widt: "100%" }}>
        <TableContainer sx={{ maxHeight: "100vh", overflowY: "auto" }}>
          <Table
            stickyHeader
            sx={{ boxShadow: "none" }}
            size="small"
            aria-label="a dense table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              tableHeads={head}
            />
            <TableBody>
              {Object.entries(overallProgress).length > 0 && (
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      fontSize: 12,
                      fontFamily: "Montserrat",
                    }}
                    align="right"
                  >
                    {t("COMPLETE").toUpperCase()}
                  </TableCell>
                  {[...Array(7)].map((item, index: any) => (
                    <TableCell component="th" scope="row" align="left">
                      {overallProgress[overAllProgressKeys[index]] + "%"}
                    </TableCell>
                  ))}
                  <TableCell></TableCell>
                </TableRow>
              )}
              {visibleRows?.length > 0 ? (
                visibleRows?.map((clubProgress: any, index: number) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:hover": {
                        backgroundColor: "#f5f5f5", // Light gray background on hover
                        cursor: "pointer", // Change cursor to pointer to indicate clickability
                      },
                    }}
                  >
                    <TableCell>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <img
                          src={`https://jamp.football/Content${clubProgress.club.imagePath}`}
                          alt={clubProgress.club.name}
                          width="30"
                          height="30"
                        />
                        <span>{clubProgress.club.name}</span>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      {getStars(clubProgress.targetRating)}
                    </TableCell>
                    <TableCell align="left">
                      {calculateSelfAssesment(
                        clubProgress.selfAssesment,
                        clubProgress.plan?.selfAssesment1
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {displayIconForValues(
                        clubProgress.offsite,
                        clubProgress.plan?.offSite
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {displayIconForValues(
                        clubProgress.onsite,
                        clubProgress.plan?.onsite
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {displayIconForValues(
                        clubProgress.interimReport,
                        clubProgress.plan?.interimReport
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {displayIconForValues(
                        clubProgress.selfAssesment2,
                        clubProgress.plan?.selfAssesment2
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {displayIconForValues(
                        clubProgress.finalReport,
                        clubProgress.plan?.finalReport
                      )}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))
              ) : clubsProgress.length > 0 ? (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: "bold",
                      fontSize: 12,
                      fontFamily: "Montserrat",
                    }}
                    colSpan={8}
                  >
                    {t("NO_RECORDS_FOUND")}...
                  </TableCell>
                </TableRow>
              ) : (
                <TableSkeleton rowCount={10} cellCount={9} />
              )}
              <TableRow></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

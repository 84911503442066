import React from 'react';
import './styles.css';
//internal and external components import
import { Select, InputLabel, MenuItem, Box, createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
    components: {
        MuiSelect: {
            styleOverrides: {
                select: {
                    paddingTop: 0,
                    paddingBottom: 0,
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    border: 0,
                }
            }
        },
    }
})

const SortBox = (props: { label: any; options: any; handleClick?: any; }) => {
    const { label, options, handleClick } = props;
    const [value, setValue] = React.useState(options[0].name);
    const handleChange = (event: { target: { value: string } }) => {
        setValue(event.target.value);
        handleClick(event.target.value);
    };
    return(
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Box className={'sortBoxWrapper'}>
                    <InputLabel className='sortBoxLabel'>{label}</InputLabel>
                    <Select
                    className='sortBoxSelect'
                    value={value}
                    onChange={handleChange}
                    >
                        {
                            options.map((option: any) => {
                                return <MenuItem className='sortBoxmenuItem' value={option?.name}>{option?.name}</MenuItem>
                            })
                        }
                    </Select>
                </Box>
            </ThemeProvider>
        </React.Fragment>
    )
}

export default SortBox;
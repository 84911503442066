import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { EnhancedTableHead } from "../../../components/EnhancedTableHead";
import { formatDate, getStars } from "../Utils";
import { useTranslation } from "react-i18next";

interface ClubDetail {
  targetDate: string;
  name: string;
  targetRating: number;
  imagePath: string;
}

interface ClubDetailsProps {
  clubDetails: ClubDetail[];
  order: string;
  orderBy: string;
  handleRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
}

const ClubDetails: React.FC<ClubDetailsProps> = ({
  clubDetails,
  order,
  orderBy,
  handleRequestSort,
}) => {
  const [t] = useTranslation();

  const Head = [
    {
      key: "name",
      displayName: t("Club").toUpperCase(),
    },
    {
      key: "",
      displayName: t("TARGET").toUpperCase(),
    },
    {
      key: "",
      displayName: t("DUE_DATE").toUpperCase(),
    },
  ];
  return (
    <TableContainer sx={{ maxHeight: "100vh", overflowY: "auto" }}>
      <Table stickyHeader aria-label="sticky table" size="small">
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          tableHeads={Head}
        />
        <TableBody>
          {clubDetails.map((club, index) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": {
                  backgroundColor: "#f5f5f5", // Light gray background on hover
                  cursor: "pointer", // Change cursor to pointer to indicate clickability
                },
              }}
            >
              <TableCell
                align="left"
                style={{ fontSize: 12, fontFamily: "Montserrat" }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <img
                    src={`https://jamp.football/Content${club.imagePath}`}
                    alt={club.name}
                    width="30"
                    height="30"
                  />
                  <span>{club.name}</span>
                </Box>
              </TableCell>
              <TableCell
                align="left"
                style={{ fontSize: 12, fontFamily: "Montserrat" }}
              >
                {getStars(club?.targetRating) || "-"}
              </TableCell>
              <TableCell style={{ fontSize: 12, fontFamily: "Montserrat" }}>
                {formatDate(club.targetDate)}
              </TableCell>
            </TableRow>
          ))}
          <TableRow />
          <TableRow></TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClubDetails;

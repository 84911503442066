import * as React from 'react';
import './styles.css';
//internal and external components/containers
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ThemeProvider, Typography, createTheme } from "@mui/material";
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { setAllActions, setCurrentTab, setProjectDetails, setProjects } from '../../redux/reducers/actionPlan';
import ProjectsTable from '../../components/CriteriaTable/ProjectsTable';
import PerformanceActionsTable from '../../components/PerformanceActionsTable';
import PhaseHeader from '../PhaseHeader';
import { getProjectDetails, getProjectsByClubId } from '../../api/projects';
import { getAllActions } from '../../api/action';
import { getProjectsByClub } from '../../api/club';
import { colors } from '../../common/pallette/colors';
import { ProjectsWrapper } from '../ProjectsWrapper';

const PerformancePlanTabs = (props: any) => {
    const tabLabels:any = useSelector((state:any) => state.actionPlan.tabs);
    const selectedTab:any = useSelector((state:any) => state.actionPlan.currentTab);
    const currentProject = useSelector((state:any) => state.actionPlan.selectedProject);
    const isActions = useSelector((state:any) => state.actionPlan.isActions);
    const details = useSelector((state:any) => state.actionPlan.details);
    const allActions = useSelector((state:any) => state.actionPlan.allActions);
    const clubDetails = useSelector((state:any) => state.clubDetails);
    const [isLoadingProjectDetails, setIsLoadingProjectDetails] = React.useState(false);
    const [isLoadingActions, setIsLoadingActions] = React.useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [t] = useTranslation();

    const handleChange = (event: React.SyntheticEvent, newValue: any) => {
      switch(newValue) {
        case t('ACTION_PLAN'):
          navigate('/performanceplan');
          break;
      } 
      dispatch(setCurrentTab(newValue));
    };
    
    const theme = createTheme({
      components: {

      }
    });

    const fetchActions = async () => {
      setIsLoadingActions(true);
      const projects = await getProjectsByClub({ clubId: clubDetails?.id });
      const response = await getAllActions({ clubId: clubDetails?.id });
      const sortedData = response.map((item:any) => {
        const tempObj = projects.find((d:any) => d.id == item.projectId);
        if(tempObj) {
          return { ...item, projectName: tempObj.displayName }
        } return item;
      });
      dispatch(setAllActions(sortedData));
      setIsLoadingActions(false);
    }
    React.useEffect(() => {
      clubDetails && clubDetails?.id && isActions &&
      fetchActions();
    }, [isActions, clubDetails]);

    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ width: '100%' }}>
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange}
              sx={{
                '.MuiTabs-indicator': {
                  backgroundColor: 'primary.main', // Set desired underline color
                  width: '180px !important',
                  '::after': {
                    content: '""',
                    position: 'absolute',
                    left: '-10px',
                    right: '-10px',
                    height: '100%',
                    backgroundColor: 'inherit', // Matches the original color
                  },
                },
              }}>
                {
                    tabLabels.map((tabName: string, index: number) => {
                        return <Tab label={t(tabName)} value={t(tabName)} />;
                    })
                }
              </TabList>
            </Box>
            <PhaseHeader module={t('ACTION_PLAN')} />
            <TabPanel value={t('ACTION_PLAN')} sx={{ p: 0, height: '90vh' }}>
              {
                isActions ? 
                <Box sx={{ mt: 1, height: 'calc(100% - 47px)' }}>
                  <PerformanceActionsTable data={allActions} isLoading={isLoadingActions}/>
                </Box> :
                <ProjectsWrapper />
              }
            </TabPanel>
          </TabContext>
        </Box>
      </ThemeProvider>
    );
}

export default PerformancePlanTabs;
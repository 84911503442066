import { Box, ClickAwayListener, Fade, Paper, Popper, Typography } from "@mui/material";
import React, { useState } from "react";
import "./styles.css";
import StatusIndicator from "../../components/StatusIndicator";
import StatusSelectBoxDefault from "../../components/StatusSelectBoxDefault";
import OwnerSelectBox from "../../components/OwnerSelectBox";
import DepartmentSelectBox from "../../components/DepartmentSelectBox";
import PerfActionDateSelector from "../../components/PerfActionDateSelector";
import { t } from "i18next";
import TasksTable from "../../components/TasksTable";
import PerfActionStandardsTable from "../../components/PerfActionStandardsTable";
import { CommentsOverviewBox } from "../CommentsOverviewBox";
import { ReportComment } from "../ReportComment";
import TextFieldDefault from "../../components/TextFieldDefault";
import StatusSelectBoxPNAD from "../../components/StatusSelectBoxPNAD";
import TextAreaDefault from "../../components/TextAreaDefault";
import PerfActionLicenceStandardsTable from "../../components/PerfActionLicenceStandardsTable";
import { TextAreaPerformanceAction } from "../../components/TextAreaPerformanceAction";
import SingleSelectDefault from "../../components/SingleSelectDefault";
import { ActionSelcetBoxDefault } from "../../components/ActionSelectBoxDefault";
import { createAction, getAction } from "../../api/action";
import { editProject } from "../../api/projects";
import { useDispatch, useSelector } from "react-redux";
import { setAllActions, setIsActions, setSelectedProject } from "../../redux/reducers/actionPlan";
import { ActionTextBoxDefault } from "../../components/ActionTextBoxDefault";
import YearFromTo from "../../components/YearFromTo";
import PrivacySelector from "../../components/PrivacySelector";
import KPIsTable from "../../components/KPIsTable";
import StaffMultiSelectCheckBox from "../../components/StaffMultiSelectCheckBox";

const NewAcademySubGoalPopper = (props:any) => {
    const { isOpen, handleClickAway, goalId } = props;
    const [newSubGoalData, setNewSubGoalData]:any = useState({
        "id": 0,
        "name": null,
        "description": null,
        "ownerId": null,
        "departmentId": null,
        "status": null,
        "objectives": [],
        "members": [],
        "parentGoalId": goalId,
        "yearFrom": null,
        "yearTo": null,
        "privacy": 0,
        "dueDate": null,
        "kpis": []
    });
    const [isUpdatedData, setIsUpdatedData] = useState(false);
    
    const handleClickInsidePopper = (event: MouseEvent) => {
        event.stopPropagation();
    }

    //handling kpis
    const handleChangeKpis = (label:any, updatedValue:any, index:any) => {
        var tempKpis = newSubGoalData?.kpis ? newSubGoalData?.kpis : [];
        switch(label) {
            case 'addKpi':
                tempKpis = [...tempKpis, updatedValue];
                break;
            default:
                tempKpis = tempKpis.map((item:any, i:any) => {
                    if (i == index) {
                        return { ...item, [label]: updatedValue };
                    } return item;
                });
                break;
        }
        const tempObj = {...newSubGoalData, kpis: tempKpis};
        setIsUpdatedData(true);
        setNewSubGoalData(tempObj);
    };
    //handling other changes
    const handleChangeState = (updatedData:any) => {
        var tempObj = { ...newSubGoalData, ...updatedData };
        setIsUpdatedData(true);
        setNewSubGoalData(tempObj);
    }

    const manageOnClickAway = async () => {
        handleClickAway(newSubGoalData, isUpdatedData);
    }
    
    return (
        <React.Fragment>
            <Popper className='popperStyle' open={isOpen} transition>
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={manageOnClickAway}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper sx={{ p: 2, width: '1200px', height: '90vh', overflowY: 'auto', margin: 'auto', mt: '4vh' }} onClick={() => handleClickInsidePopper}>
                                <Box className={'NAGmainWrapper'}>
                                    <Typography className="fs14 fw700 maxWidth70p uppercase">{t('NEW_ACADEMY_SUB_GOAL')}</Typography>
                                    <Box className={'mt30'}>
                                        <Box sx={{ width: '200px', display: "inline-block" }}>
                                            <TextFieldDefault label={t('GOAL_ID')} isDisabled={true} />
                                        </Box>
                                        <Box sx={{ width: "970px", ml:"20px", display: "inline-block" }} onClick={() => handleClickInsidePopper}>
                                            <ActionTextBoxDefault 
                                            label={t('GOAL_TITLE')}
                                            value={newSubGoalData?.name ? newSubGoalData?.name : ''} 
                                            handleChange={handleChangeState}/>
                                        </Box>
                                        <Box className={'mt30 NAGinnerWrapper'}>
                                            <TextAreaPerformanceAction
                                            value={newSubGoalData?.description}
                                            label={t('GOAL_DESCRIPTION')} 
                                            handleChange={handleChangeState}
                                            />
                                            <Box className={'NAGprivacyTimeWrapper'}>
                                            <PrivacySelector value={newSubGoalData?.privacy} manageSelect={handleChangeState} />
                                                <YearFromTo 
                                                fromDate={newSubGoalData.fromYear} 
                                                todate={newSubGoalData.toYear} 
                                                label={t('TIME_PERIOD')}
                                                manageSelect={handleChangeState} />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className={'NAGselectBoxWrapper'}>
                                        <StatusSelectBoxPNAD value={newSubGoalData?.status} manageSelect={handleChangeState} />
                                        <OwnerSelectBox value={newSubGoalData?.ownerUserId} manageSelect={handleChangeState} />
                                        <DepartmentSelectBox value={newSubGoalData?.departmentId} manageSelect={handleChangeState} />
                                        <PerfActionDateSelector value={newSubGoalData?.dueDate} manageSelect={handleChangeState} />
                                    </Box>
                                    <Box className={'mt30'}>
                                        <StaffMultiSelectCheckBox 
                                         selectedList={newSubGoalData?.members}
                                         manageChange={handleChangeState}
                                         manageOnFocus={(e:any) => {handleClickInsidePopper(e)}}
                                        />
                                    </Box>
                                    <Box className={'mt30'}>
                                        <Typography className="fs12 fw700">{t('KPIS')}</Typography>
                                        <KPIsTable 
                                        data={newSubGoalData?.kpis ? newSubGoalData?.kpis : []}
                                        isLoading={false} 
                                        handleChange={handleChangeKpis} />
                                    </Box>
                                </Box>
                            </Paper>
                        </Fade>
                    </ClickAwayListener>)}
            </Popper>
        </React.Fragment>
    )
}

export default NewAcademySubGoalPopper;
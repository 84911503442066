import { createSlice } from "@reduxjs/toolkit";

let initialData: any = {
  tabs: ["MY_EVALUATION", "MY_LICENCE", "PEOPLE", "DATES", "PROGRESS"],
  searchString: "",
  targetStarRatingFilter: [],
  leagueFilter: [],
  otherFilter: [],
  currentTab: "MY_EVALUATION",
  clubsProgress: [],
  clubsDates: [],
  clubsTimeline: [],
  timelineMonth: 6,
  clubsPeople: [],
  clubsAssociatedPeople: [],
  activeClubDetail: {},
};

export const evaluationDashboard = createSlice({
  name: "evaluationDashboard",
  initialState: initialData,
  reducers: {
    setCurrentTab: (state: any, action: any) => {
      state.currentTab = action.payload;
      return state;
    },
    setClubsProgress: (state: any, action: any) => {
      state.clubsProgress = action.payload;
      return state;
    },
    setClubsDates: (state: any, action: any) => {
      state.clubsDates = action.payload;
      return state;
    },
    setClubsTimeline: (state: any, action: any) => {
      state.clubsTimeline = action.payload;
      return state;
    },
    setEvaluationDashboardSearchString: (state: any, action: any) => {
      state.searchString = action.payload;
      return state;
    },
    setLeagueFilter: (state: any, action: any) => {
      state.leagueFilter = action.payload;
      return state;
    },
    resetLeagueFilter: (state: any) => {
      state.leagueFilter = initialData.leagueFilter;
      return state;
    },
    setTargetStarRatingFilter: (state: any, action: any) => {
      state.targetStarRatingFilter = action.payload;
      return state;
    },
    resetTargetStarRatingFilter: (state: any) => {
      state.targetStarRatingFilter = initialData.targetStarRatingFilter;
      return state;
    },
    setOtherFilter: (state: any, action: any) => {
      state.otherFilter = action.payload;
      return state;
    },
    resetOtherFilter: (state: any) => {
      state.otherFilter = initialData.otherFilter;
      return state;
    },
    setClubsPeople: (state: any, action: any) => {
      state.clubsPeople = action.payload;
      return state;
    },
    setClubsAssociatedPeople: (state: any, action: any) => {
      state.clubsAssociatedPeople = action.payload;
      return state;
    },
    setActiveClubDetail: (state: any, action: any) => {
      state.activeClubDetail = action.payload;
      return state;
    },
    setTimelineMonth: (state: any, action: any) => {
      state.timelineMonth = action.payload;
      return state;
    },
  },
});

export const {
  setCurrentTab,
  setClubsProgress,
  setClubsDates,
  setEvaluationDashboardSearchString,
  setClubsTimeline,
  setLeagueFilter,
  setTargetStarRatingFilter,
  setOtherFilter,
  setClubsPeople,
  setClubsAssociatedPeople,
  setActiveClubDetail,
  resetLeagueFilter,
  resetTargetStarRatingFilter,
  resetOtherFilter,
  setTimelineMonth,
} = evaluationDashboard.actions;

export default evaluationDashboard.reducer;

import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { TextField, InputAdornment, ThemeProvider, createTheme, Popper, Checkbox, Box, IconButton, InputLabel } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { colors } from '../../common/pallette/colors';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { genUID } from '../../common/StaticExports';

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: '0px 14px !important'
        }
      }
    },
    MuiStack: {
      styleOverrides: {
        root: {
          width: '100% !important',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '12px'
        }
      }
    },
    MuiChip: {
        styleOverrides: {
            root: {
                display: 'none'
            }
        }
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          paddingRight: '0px !important'
        }
      }
    }
  }
});

const CustomPopper = styled(Popper)({
  '& .MuiAutocomplete-listbox': {
    fontSize: '14px',
  },
});

export default function AutoComplete(props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { placeholder, selectedValues, options, variant, key, handleMenuClick, label, clickReset, isMultiple } = props;
  const [optionList, setOptionList] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const keyLabel = key ? key : 'name';
//   const [selectedOptions, setSelectedOptions] = React.useState([]);

  React.useEffect(() => {
    if (options !== undefined) {
      setOptionList(options);
    }
  }, [options]);

//   const handleChange = (_event: any, newValue: any) => {
//     setSelectedOptions(newValue);
//     // handleMenuClick(newValue);
//   };

  const handleInputChange = (_event: any, newInputValue: any) => {
    setInputValue(newInputValue);
  };

  const handleClearAll = () => {
    clickReset();
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack spacing={1} sx={{ '& > :not(style) ~ :not(style)': { marginTop: '0px' } }}>
        { label && label != '' && <InputLabel className='fs12' sx={{ textAlign: 'left' }}>{label}</InputLabel>}
        <Autocomplete
          multiple={isMultiple}
          disableCloseOnSelect
          className='fs12'
          value={selectedValues}
          inputValue={inputValue}
        //   onChange={handleChange}
          onInputChange={handleInputChange}
          options={optionList}
          getOptionLabel={(option: any) => option.displayName || ''}
          sx={{ bgcolor: colors.grey.light, border: 0 }}
          PopperComponent={CustomPopper}
          filterOptions={(options, params) => {
            const filtered = options.filter((option: any) =>
              option[keyLabel].toLowerCase().includes(params.inputValue.toLowerCase())
            );
            return filtered;
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}
             onClick={() => {handleMenuClick(option)}}>
              <Checkbox
                size='small'
                style={{ marginRight: 8 }}
                checked={selectedValues?.some((obj:any) => obj.id == option.id)}
              />
              {Object.keys(option).includes('uid') ? option[keyLabel] + ' (' + genUID(option['uid']) + ')' : option[keyLabel]}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ border: 0 }}
              fullWidth
              placeholder={placeholder ? placeholder : "Search"}
              className='fs12'
              variant={variant ? variant : 'outlined'}
              InputProps={{
                ...params.InputProps,
                type: 'search',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearAll}>
                      <ClearIcon sx={{ fontSize: '14px' }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap' }}>
          {/* {selectedValues.map((option:any, index:any) => (
            <Box key={index} sx={{ mr: 1, mb: 1 }}>
              {option.name}
              {index < selectedValues.length - 1 && ','}
            </Box>
          ))} */}
          {selectedValues.length ? selectedValues.length + ' Selected' : ''}
        </Box>
      </Stack>
    </ThemeProvider>
  );
}
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { faCircleCheck, faStar } from "@fortawesome/free-solid-svg-icons";
import { colors } from "../../../common/pallette/colors";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import StatusIndicatorSmall from "../../../components/StatusIndicatorSmall";

export const formatDate = (dateString: string) => {
  if (!dateString) {
    return "-";
  }
  const date = new Date(dateString);
  return format(date, "yy.MM.dd"); // 'yy' for last two digits of the year, 'MM' for month, 'dd' for day
};

export const getStars = (count: number) => {
  // Check if the count is 0
  if (count === 0) {
    // Return a single red dot icon
    return (
      <FiberManualRecordIcon
        fontSize="small"
        style={{ width: 10, color: "red" }}
      />
    );
  }
  return (
    <div style={{ display: "flex", gap: "4px" }}>
      {Array.from({ length: count }, (_, index) => (
        <FontAwesomeIcon
          key={index}
          icon={faStar}
          style={{ color: colors.yellow.bright }}
        />
      ))}
    </div>
  );
};

export const filteredList = (
  otherFilter: any,
  leagueFilter: any,
  targetStarRatingFilter: any,
  data: any,
  currentTab: any
) => {
  // Helper function to check for "unassigned" fields
  const isUnassigned = (row: any, currentTab: string) => {
    if (currentTab === "PEOPLE") {
      return (
        row.asm === "" ||
        row.evaluationTeam === "" ||
        row.academyDirector === "" ||
        row.translator === ""
      );
    }

    if (currentTab === "CLUBSPEOPLE") {
      return row.clubRoles?.some((role: any) => {
        return !role?.role?.name || role?.role?.name.trim() === "";
      });
    }

    return false;
  };

  // Helper function to check for "set" fields (i.e., not null)
  const isSet = (row: any) => {
    return (
      row.targetDate != null &&
      row.selfAssesment1 != null &&
      row.selfAssesment2 != null &&
      row.offSite != null &&
      row.onSite != null &&
      row.interimReport != null &&
      row.finalReport != null
    );
  };

  // Helper function to check for "notset" fields (i.e., null)
  const isNotSet = (row: any) => {
    return (
      row.targetDate == null ||
      row.selfAssesment1 == null ||
      row.selfAssesment2 == null ||
      row.offSite == null ||
      row.onSite == null ||
      row.interimReport == null ||
      row.finalReport == null
    );
  };

  if (otherFilter.length > 0) {
    data = data?.filter((row: any) => {
      return otherFilter.every((item: any) => {
        // Handle "unassigned" case
        if (item.value === "unassigned") {
          return isUnassigned(row, currentTab);
        }

        // Handle "set" case (all required fields are not null)
        if (item.value === "set") {
          return isSet(row);
        }

        // Handle "notset" case (any required field is null)
        if (item.value === "notset") {
          return isNotSet(row);
        }

        // Default case (no filtering for other filter values)
        return true;
      });
    });
  }

  // Helper function to check if the targetStarRating matches
  const isTargetRatingMatch = (row: any, targetStarRatingFilter: any) => {
    // For "CLUBSPEOPLE" tab, check through clubRoles -> clubDetail -> currentRating
    if (currentTab === "CLUBSPEOPLE") {
      return row.clubRoles?.some((role: any) => {
        return role.clubDetail?.some((club: any) => {
          return targetStarRatingFilter.some(
            (item: any) =>
              club.currentRating?.toString() === item.value.toString()
          );
        });
      });
    }

    // For other cases, check directly on row.targetRating
    return targetStarRatingFilter.some(
      (item: any) => row.targetRating?.toString() === item.value.toString()
    );
  };

  if (targetStarRatingFilter.length > 0) {
    data = data?.filter((row: any) => {
      return isTargetRatingMatch(row, targetStarRatingFilter);
    });
  }

  // Helper function to check if the league matches
  const isLeagueMatch = (club: any, leagueFilter: any) => {
    return leagueFilter.some((item: any) => {
      return club.league?.name?.toLowerCase().includes(item.name.toLowerCase());
    });
  };

  if (leagueFilter.length > 0) {
    data = data?.filter((row: any) => {
      // Apply filtering for "CLUBSPEOPLE" tab
      if (currentTab === "CLUBSPEOPLE") {
        return row.clubRoles?.some((role: any) => {
          return role.clubDetail?.some((club: any) =>
            isLeagueMatch(club, leagueFilter)
          );
        });
      }

      // Apply filtering for other tabs
      return (
        row.clubRoles?.some((role: any) => {
          return role.clubDetail?.some((club: any) =>
            isLeagueMatch(club, leagueFilter)
          );
        }) ||
        row.league?.name
          ?.toLowerCase()
          .includes(leagueFilter[0].name.toLowerCase()) ||
        row.club?.league?.name
          ?.toLowerCase()
          .includes(leagueFilter[0].name.toLowerCase())
      );
    });
  }

  // Return the final filtered data
  return data;
};

export const getIconForValues = (result: number) => {
  if (result === 0) {
    return <StatusIndicatorSmall reportType={"Status"} status={1} />;
  } else if (result === 100) {
    return <StatusIndicatorSmall reportType={"Status"} status={3} />;
  } else {
    return "Inprogress";
  }
};

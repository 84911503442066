import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import StatusIndicatorSmall from "../../components/StatusIndicatorSmall";
import { useTranslation } from "react-i18next";
import { TextFieldWithoutBorders } from "../../components/TextFieldWithoutBorders";
import { TextAreaWithoutBorders } from "../../components/TextAreaWithoutBorders";
import ProgressBar from "../../components/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { GoalsWrapper } from "../GoalsWrapper";
import { NoGoalsCard } from "../../components/NoGoalsCard";
import KPIsTable from "../../components/KPIsTable";
import GoalProjectsTable from "../../components/GoalProjectsTable";
import './styles.css';
import YearFromTo from "../../components/YearFromTo";
import PrivacySelector from "../../components/PrivacySelector";
import GoalsBreadCrumb from "../GoalsBreadCrumb";
import { AvatarAndName } from "../../components/AvatarAndName";
import ButtonDefault from "../../components/ButtonDefault";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import NewAcademySubGoalPopper from "../NewAcademySubGoalPopper";
import { baseUrl } from "../../api/config";
import { setGoals, setSelectedGoal } from "../../redux/reducers/goalsAndStrategy";
import { getGoalDetail, updateGoal } from "../../api/goals";
import StatusSelectBoxPNAD from "../../components/StatusSelectBoxPNAD";
import StatusSelectBoxWithoutLabel from "../../components/StatusSelectBoxWithoutLabel";
import OwnerSelectBox from "../../components/OwnerSelectBox";
import AvatarOwnerSelectBox from "../../components/AvatarOwnerSelectBox";
import StaffMultiSelectCheckBoxWithoutBorders from "../../components/StaffMultiSelectCheckBoxWithoutBorders";

const GoalDetails = (props:any) => {
    const goal = useSelector((state:any) => state.goalsAndStrategy.selectedGoal);
    const [data, setData] = useState(goal);
    const [t] = useTranslation();
    const goals = useSelector((state:any) => state.goalsAndStrategy.goals);
    const [isUpdatedData, setIsUpdatedData] = useState(false);
    // const subGoals = goals.filter((item:any) => {
    //     const sg = data?.subGoals ? data?.subGoals : [];
    //     if(sg?.includes(item?.id)) {
    //         return item;
    //     }
    // });
    const [addSubGoal, setAddSubGoal] = useState(false);
    const dispatch = useDispatch();

    //managing redux state updates
    const handleChangeState = (updatedData:any) => {
        var tempObj = { ...data, ...updatedData };
        // dispatch(setSelectedGoal(tempObj));
        setData(tempObj);
        setIsUpdatedData(true);
    }
    //handling kpis
    const handleChangeKpis = (label:any, updatedValue:any, index:any) => {
        var tempKpis = data?.kpis ? data?.kpis : [];
        switch(label) {
            case 'addKpi':
                tempKpis = [...tempKpis, updatedValue];
                break;
            default:
                tempKpis = tempKpis.map((item:any, i:any) => {
                    if (i == index) {
                        return { ...item, [label]: updatedValue, goalId: data?.id };
                    } return item;
                });
                break;
        }
        const tempObj = {kpis: tempKpis};
        handleChangeState(tempObj);
    };
    //managing sub goals
    const manageHandleClickAway = (newSubGoalData:any, isUpdatedData: boolean) => {
        if (newSubGoalData != null && isUpdatedData) {
            let subGoals = data?.subGoals ? data?.subGoals : [];
            const tempSubGoals = [...subGoals, newSubGoalData];
            handleChangeState({subGoals: tempSubGoals});
        }
        setAddSubGoal(false);
    }

    const updateChanges = async () => {
        if(isUpdatedData) {
            const tempMembers = data?.members?.map((d:any) => d.id);
            const tempObj = { ...data, members: tempMembers };
            const response = await updateGoal({goalId: data?.id, goal: tempObj});
            dispatch(setSelectedGoal(response));
            setIsUpdatedData(false);
            const tempArr = goals.map((item:any) => item?.id === response?.id ? response : item);
            dispatch(setGoals(tempArr));
        }
    }

    useEffect(() => {
        isUpdatedData && updateChanges();
        !goal && Object.keys(goal)?.length == 0 && fetchGoaldetails();
    }, [ isUpdatedData, goal ]);

    
    const fetchGoaldetails = async () => {
        const response = await getGoalDetail({ goalId: data?.id });
        dispatch(setSelectedGoal(response));
    }

    return (
        <Box className={'goalDetailWrapper'}>
            <NewAcademySubGoalPopper isOpen={addSubGoal} handleClickAway={manageHandleClickAway} goalId={data?.id} />
            <Box className={'gdLeftContent'}>
                <GoalsBreadCrumb />
                <Box className={'gdHeader mb20'}>
                    <Box sx={{ width: '70%' }}>
                        <TextFieldWithoutBorders
                        value={data?.name ? data?.name : ''}
                        handleChange={(e:any) => {handleChangeState({name: e.target.value})}}
                        placeholder={t('ENTER_GOAL_NAME')}
                        fontSize={'16px'}
                        fontWeight={700}
                        fullWidth={true}
                        manageOnBlur={updateChanges}
                        />
                    </Box>
                    <StatusSelectBoxWithoutLabel 
                    value={data?.status}
                    manageSelect={handleChangeState}
                    manageOnBlur={updateChanges} />
                </Box>
                <Box className={'gdContents'}>
                    <Box className={'mb20'}>
                        <Typography className="headingsGD">{t('DESCRIPTION')}</Typography>
                        <TextAreaWithoutBorders
                        value={data?.description ? data?.description : ''}
                        handleChange={(e:any) => {handleChangeState({description: e.target.value})}}
                        placeholder={t('ENTER_GOAL_DESCRIPTION')}
                        manageOnBlur={updateChanges}
                        />
                    </Box>
                    <Box sx={{ width: '100%', mb: '20px' }}>
                        <Typography className="headingsGD">{t('PROGRESS')}</Typography>
                        <ProgressBar percent={data?.progress ? data?.progress : 0} width="100%"/>
                    </Box>
                    <Box className={'mb20'}>
                        <Box className={'subGoalsHeader'}>
                            <Typography className="headingsGD">{t('SUB_GOALS')}</Typography>
                            <ButtonDefault
                            variant={'outlined'}
                            value={t('ADD_SUB_GOAL')}
                            manageOnClick={() => {setAddSubGoal(true)}}
                            icon={<FontAwesomeIcon className={'fs13'} icon={faCirclePlus} />}
                            />
                        </Box>
                        {
                            data?.subGoals && data?.subGoals?.length > 0 ?
                            <GoalsWrapper 
                            data={data?.subGoals} 
                            isLoading={false} 
                            isSubGoal={true} /> :
                            <NoGoalsCard />
                        }
                    </Box>
                    <Box className={'mb20'}>
                        <Typography className="headingsGD">{t('KPIS')}</Typography>
                        <KPIsTable 
                        data={data?.kpis ? data?.kpis : []}
                        handleChange={handleChangeKpis}
                        handleOnBlur={updateChanges}
                        goalId={data?.id}
                        isDetailed={true} />
                    </Box>
                    {/* <Box className={'mb20'}>
                        <Typography className="headingsGD">{t('PROJECTS')}</Typography>
                        <GoalProjectsTable 
                        data={data?.projects ? data?.projects : []}/>
                    </Box> */}
                </Box>
            </Box>
            <Box className={'dividerLine'} />
            <Box className={'gdRightContent'}>
                <Typography className="headingsGD mb20">{t('DETAILS')}</Typography>
                <Box className={'mb10'}>
                    <Typography className="headingsGDright">{t('OWNER')}</Typography>
                    <AvatarOwnerSelectBox 
                    value={data?.ownerId} 
                    manageSelect={handleChangeState} 
                    hideLabel={true}
                    manageOnBlur={updateChanges} />
                </Box>
                <Box className={'mb10'}>
                    <Typography className="headingsGDright">{t('MEMBERS')}</Typography>
                    <StaffMultiSelectCheckBoxWithoutBorders
                    selectedList={data?.members}
                    manageChange={handleChangeState}
                    manageOnFocus={(e:any) => e.stopPropagation()}
                    manageOnBlur={updateChanges}
                    />
                </Box>
                <Box className={'mb10'}>
                    <YearFromTo 
                    fromDate={data?.yearFrom} 
                    toDate={data?.yearTo} 
                    label={t('TIME_PERIOD')}
                    manageSelect={handleChangeState}
                    manageOnBlur={updateChanges} />
                </Box>
                <Box className={'mb10'}>
                    <PrivacySelector value={data?.privacy} manageSelect={handleChangeState} manageOnBlur={updateChanges} />
                </Box>
            </Box>
        </Box>
    )
}

export default GoalDetails;